import React, { useState, useEffect, useMemo } from 'react'
import { searchScreens } from '../../services/extension'
import { isBrowser } from '../../services/auth'
import feather from 'feather-icons'
import { MapContainer, TileLayer, Marker } from 'react-leaflet'
import { DivIcon } from 'leaflet'
import Fullscreen from 'react-leaflet-fullscreen-plugin'

// import * as Icon from 'react-feather'

const Sales = ({ props }) => {
  const [map, setMap] = useState(null)
  const [highlight, setHighlight] = useState(false)
  const [screens, setScreens] = useState(false)

  useEffect(() => {
    searchScreens({ from: 1634072400, to: 1635541200, needs: 604800 }, (res = false) => {
      if (res) setScreens(res)
      if (!res) window.alert('Opasteiden lataamisessa on ongelma')
    })
  }, [])

  useEffect(() => {
    console.log('map', map)
  }, [map])

  const displayMap = useMemo(
    () => {
      const fullscreenOptions = {
        position: 'bottomright', // change the position of the button can be topleft, topright, bottomright or bottomleft, default topleft
        title: 'Full Screen', // change the title of the button, default Full Screen
        titleCancel: 'Exit Full Screen', // change the title of the button when fullscreen is on, default Exit Full Screen
        content: null, // change the content of the button, can be HTML, default null
        forceSeparateButton: true, // force separate button to detach from zoom buttons, default false
        forcePseudoFullscreen: false, // force use of pseudo full screen even if full screen API is available, default false
        fullscreenElement: false // Dom element to render in full screen, false by default, fallback to map._container
      }

      if (isBrowser()) {
        return (
          <div className='-mx-4' id='map'>
            <MapContainer
              center={{
                lat: 64.959997,
                lng: 27.590001
              }}
              zoom={8}
              scrollWheelZoom
              preferCanvas
              zoomControl={false}
              className='h-96 w-full mb-4'
              attributionControl={false}
              whenCreated={setMap}
            >
              <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
              />
              {screens && screens.map(screen => {
                if (screen.lat && screen.lng) {
                  const eventHandlers = {
                    click: (event) => {
                      setHighlight(screen.id)
                      document.getElementById(`screen-${screen.id}`) && document.getElementById(`screen-${screen.id}`).scrollIntoView({
                        behavior: 'smooth'
                      })
                    }
                  }
                  return (
                    <Marker
                      key={screen.id}
                      eventHandlers={eventHandlers}
                      position={{
                        lat: screen.lat,
                        lng: screen.lng
                      }}
                      icon={new DivIcon({
                        html: feather.icons.monitor.toSvg({ class: 'w-full h-auto', color: 'black' }),
                        className: `p-1.5 shadow rounded-full bg-white ${screen.id === highlight ? ' ring z-top bg-opacity-100' : ''}`,
                        iconSize: [32, 32],
                        iconAnchor: [16, 16]
                      })}
                    />
                  )
                }
                return null
              })}
              <Fullscreen
                {...fullscreenOptions}
              />
            </MapContainer>
          </div>
        )
      }
      return null
    },
    [screens, highlight]
  )

  const output = useMemo(() => {
    if (!screens) {
      return (
        <div className='flex items-center justify-between gap-0 mb-6'>
          <div>
            <h3 className='text-sm font-semibold text-gray-500 uppercase my-0'>Myynti</h3>
            <h2 className='my-0'>Ladataan</h2>
          </div>
        </div>
      )
    }

    return (
      <>
        <div className='flex items-center justify-between gap-0 mb-4'>
          <div>
            <h3 className='text-sm font-semibold text-gray-500 uppercase my-0'>Opasteet</h3>
            <h2 className='my-0'>{screens.length < 1 ? 'Ei tuloksia' : screens.length === 1 ? 'Yksin vain' : `${screens.length} tulosta`}</h2>
          </div>
        </div>
        {displayMap}
        <div className='flex flex-col'>
          hei
          {console.log(screens)}
        </div>
      </>
    )
  }, [screens, highlight])

  return output
}

export default Sales
