import React, { useState, useEffect, useMemo } from 'react'
import { getScreens } from '../../services/extension'
import { isBrowser } from '../../services/auth'
import feather from 'feather-icons'
import { MapContainer, TileLayer, Marker } from 'react-leaflet'
import { DivIcon } from 'leaflet'
import Fullscreen from 'react-leaflet-fullscreen-plugin'

import Screen from './screen'
import EditScreen from './edit-screen'
import { Link } from 'gatsby'

// import * as Icon from 'react-feather'

const Screens = ({ urlScreenID, ...props }) => {
  const [screen, setScreen] = useState(false)
  const [highlight, setHighlight] = useState(false)
  const [screens, setScreens] = useState(false)
  const [list, setList] = useState('all')

  useEffect(() => {
    if (urlScreenID) {
      if (urlScreenID === 'new') {
        setScreen({ id: urlScreenID, edit: true })
      } else {
        setScreen({ id: urlScreenID, edit: false })
      }
    }
  }, [urlScreenID])

  useEffect(() => {
    getScreens((res = false) => {
      if (res) setScreens(res)
      if (!res) window.alert('Opasteiden lataamisessa on ongelma')
    }, list)
  }, [list])

  const back = (reload = false, screen = 'none') => {
    screen === 'none' ? setScreen(false) : setScreen(screen)
    if (reload) {
      getScreens((res = false) => {
        if (res) {
          setScreens(res)
          if (screen !== 'none') setScreen(screen)
        }
        if (!res) window.alert('Opasteiden lataamisessa on ongelma')
      })
    }
  }

  const displayMap = useMemo(
    () => {
      const fullscreenOptions = {
        position: 'bottomright', // change the position of the button can be topleft, topright, bottomright or bottomleft, default topleft
        title: 'Full Screen', // change the title of the button, default Full Screen
        titleCancel: 'Exit Full Screen', // change the title of the button when fullscreen is on, default Exit Full Screen
        content: null, // change the content of the button, can be HTML, default null
        forceSeparateButton: true, // force separate button to detach from zoom buttons, default false
        forcePseudoFullscreen: false, // force use of pseudo full screen even if full screen API is available, default false
        fullscreenElement: false // Dom element to render in full screen, false by default, fallback to map._container
      }

      if (isBrowser()) {
        return (
          <div className='-mx-4' id='map'>
            <MapContainer
              center={{
                lat: 64.959997,
                lng: 27.590001
              }}
              zoom={8}
              scrollWheelZoom
              preferCanvas
              zoomControl={false}
              className='h-96 w-full mb-4'
              attributionControl={false}
            >
              <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
              />
              {screens && screens.map(screen => {
                if (screen.lat && screen.lng) {
                  const eventHandlers = {
                    click: (event) => {
                      setHighlight(screen.id)
                      document.getElementById(`screen-${screen.id}`).scrollIntoView({
                        behavior: 'smooth'
                      })
                    }
                  }
                  return (
                    <Marker
                      key={screen.id}
                      eventHandlers={eventHandlers}
                      position={{
                        lat: screen.lat,
                        lng: screen.lng
                      }}
                      icon={new DivIcon({
                        html: screen.status === 2 ? feather.icons['alert-circle'].toSvg({ class: `w-full h-auto ${screen.status === 0 ? ' opacity-75' : ''}`, color: 'white' }) : feather.icons.monitor.toSvg({ class: 'w-full h-auto', color: 'black' }),
                        className: `p-1.5 shadow rounded-full ${screen.status === 0 ? ' bg-gray-200 bg-opacity-75' : screen.status === 1 ? ' bg-white' : ' bg-yellow-400'} ${screen.id === highlight ? ' ring z-top bg-opacity-100' : ''} ${screen.status > 0 && !screen.available ? ' bg-white offline-dot' : ''}`,
                        iconSize: [32, 32],
                        iconAnchor: [16, 16]
                      })}
                    />
                  )
                }
                return null
              })}
              <Fullscreen
                {...fullscreenOptions}
              />
            </MapContainer>
          </div>
        )
      }
      return null
    },
    [screens, highlight]
  )

  const output = useMemo(() => {
    if (!screens) {
      return (
        <div className='flex items-center justify-between gap-0 mb-6'>
          <div>
            <h3 className='text-sm font-semibold text-gray-500 uppercase my-0'>Opasteet</h3>
            <h2 className='my-0'>Ladataan</h2>
          </div>
        </div>
      )
    }

    return (
      <>
        <div className='flex items-center justify-between gap-0 mb-4'>
          <div>
            <h3 className='text-sm font-semibold text-gray-500 uppercase my-0'>Opasteet</h3>
            <h2 className='my-0'>{screens.length < 1 ? 'Ei tuloksia' : screens.length === 1 ? 'Yksin vain' : `${screens.length} tulosta`}</h2>
          </div>
          {/*<button className='btn -green' onClick={() => setScreen({ id: 'new', edit: true })}>Uusi opaste</button>*/}
          <Link to='/app/profile/screens/new'>Uusi opaste</Link>
        </div>
        {displayMap}
        <div className='flex flex-col'>
          <div className='-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
            <div className='py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8'>
              <div className='shadow-sm overflow-hidden border-b border-gray-200 sm:rounded-lg'>
                <table className='min-w-full divide-y divide-gray-200'>
                  <thead className='bg-gray-50'>
                    <tr>
                      <th scope='col' className='px-6 py-3 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider'>
                        Nimi
                      </th>
                      <th scope='col' className='px-6 py-3 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider flex items-center gap-2'>
                        Tilanne
                        <select value={list} onChange={e => setList(e.target.value)} autoComplete='off' className='block py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'>
                          <option value='all'>Kaikki</option>
                          <option value='not_connected'>Kytkemättömät</option>
                          <option value='maintenance'>Vaatii huoltoa</option>
                          <option value='unavailable'>Ei yhteyttä</option>
                          <option value='available'>Päällä</option>
                        </select>
                      </th>
                      <th scope='col' className='relative px-6 py-3'>
                        <span className='sr-only'>Toiminnot</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className='bg-white divide-y divide-gray-200'>
                    {screens.map(screen =>
                      <tr key={screen.id} id={`screen-${screen.id}`} className={highlight === screen.id ? 'bg-blue-100' : null}>
                        <td className='px-6 py-4 whitespace-nowrap text-sm font-semibold text-gray-900'>
                          <button
                            onClick={() => {
                              setHighlight(screen.id)
                              document.getElementById('map') && document.getElementById('map').scrollIntoView({
                                behavior: 'smooth'
                              })
                            }}
                          >
                            {screen.name.fi ? screen.name.fi : <span className='font-normal gray-500'>Nimetön</span>}
                          </button>
                        </td>
                        <td className='px-6 py-4 whitespace-nowrap'>
                          {
                            {
                              0: <span className='px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800'>Ei kytketty</span>,
                              1: <span className='px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800'>Kytketty</span>,
                              2: <span className='px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800'>Vaatii huoltoa</span>
                            }[screen.status]
                          }
                          {screen.status > 0 && !screen.available && <span className='ml-1 px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800'>Ei yhteyttä</span>}
                        </td>
                        <td className='px-6 py-4 whitespace-nowrap text-right text-sm font-semibold flex gap-3 justify-center content-center'>
                          <Link className='a' to={`/app/profile/screens/${screen.id}`}>Avaa</Link>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }, [screens, list, highlight, displayMap])

  if (screen && screen.id) {
    if (screen.edit) {
      return <EditScreen screenId={screen.id} back={back} />
    }
    return <Screen screenId={screen.id} back={back} />
  }

  return output
}

export default Screens
