import React from "react";
import { Helmet } from "react-helmet";
import NavBar from "./nav-bar";
import Footer from "./footer";
import favicon from "../images/favicon.png";

const Layout = ({ children }) => (
  <>
    {/* Default favicon and title for pages */}
    <Helmet>
      <title>Opasti</title>
      <link rel="icon" type="image/png" sizes="32x32" href={favicon} />
      <meta name="description" content="Taloyhtiöden infonäytöt ovat helppo ja nopea tapa jakaa tietoa. Opastamassa SkyLED Finland Oy." />
    </Helmet>

    <NavBar />
    <div className="text-gray-900 font-medium flex flex-col min-h-screen">
      <main id="fade-in-element" className="flex-grow flex flex-col bg-white">
        {children}
      </main>
    </div>
    <Footer />
  </>
);

export default Layout;
