import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { getAccess } from '../../services/access'

import * as Icon from 'react-feather'

const Rights = ({ current, ...props }) => {
  const [rights, setRights] = useState({})
  const [newUserEmail, setNewUserEmail] = useState('')

  const loadUsers = useCallback(
    house => {
      if (!current) {
        return
      }
      const requestOptions = {
        headers: { Authorization: `Bearer ${getAccess()}` }
      }
      window.fetch(`${process.env.REACT_APP_EXTENSION_API_ENDPOINT}/v1/houses/${current}/rights`, requestOptions)
        .then(res => res.json())
        .then(
          (result) => {
            console.log(result)
            setRights(result)
          },
          (error) => {
            console.log(error)
          }
        )
    },
    [current]
  )

  useEffect(() => {
    if (!current) {
      return
    }
    loadUsers(current)
  }, [current, loadUsers])

  const handleDelete = (target, username) => {
    if (window.confirm(`Haluatko varmasti poistaa käyttöoikeuden käyttäjältä ${target} ${username}?`)) {
      const requestOptions = {
        method: 'DELETE',
        headers: { Authorization: `Bearer ${getAccess()}` }
      }

      window.fetch(`${process.env.REACT_APP_EXTENSION_API_ENDPOINT}/v1/houses/${current}/rights/${target}`, requestOptions)
        .then(res => res.json())
        .then(
          (result) => {
            console.log(result)
            window.alert('Käyttöoikeus poistettu')
            loadUsers(current)
          },
          (error) => {
            window.alert('Virhe poistossa, löytyy lokista')
            console.log(error)
          }
        )
    }
  }

  const handlePut = (target, level) => {
    const requestOptions = {
      method: 'PUT',
      headers: { Authorization: `Bearer ${getAccess()}` }
    }

    window.fetch(`${process.env.REACT_APP_EXTENSION_API_ENDPOINT}/v1/houses/${current}/rights/${target}/${level}`, requestOptions)
      .then(res => res.json())
      .then(
        (result) => {
          console.log(result)
          window.alert('Käyttöoikeus muokattu')
          loadUsers(current)
        },
        (error) => {
          window.alert('Virhe muokkauksessa, löytyy lokista')
          console.log(error)
        }
      )
  }

  const term = level => {
    return {
      50: 'Hallinnoija',
      100: 'Käyttöoikeus',
      1: 'Palveluntarjoaja',
      default: level
    }[level]
  }

  const isAdmin = useMemo(() => {
    if (rights.user_level) return rights.user_level <= 50
    return false
  }, [rights])

  const handleUserAdd = event => {
    event.preventDefault()
    const requestOptions = {
      method: 'POST',
      headers: { Authorization: `Bearer ${getAccess()}` }
    }

    window.fetch(`${process.env.REACT_APP_EXTENSION_API_ENDPOINT}/v1/houses/${current}/rights/${newUserEmail}`, requestOptions)
      .then(res => res.json())
      .then(
        (result) => {
          console.log(result)
          window.alert('Käyttäjä lisätty')
          loadUsers(current)
          setNewUserEmail('')
        },
        (error) => {
          window.alert('Virhe käyttäjän lisäämisessä, löytyy lokista')
          console.log(error)
        }
      )
  }

  const addUserForm = (
    <div className='p-3 pt-6'>
      <p>Voit antaa käyttäjille käyttöoikeuden syöttämällä hänen sähköpostinsa.</p>
      <form
        method='post'
        className='p-2'
        onSubmit={event => {
          handleUserAdd(event)
        }}
      >
        <div className='field'>
          <label htmlFor='email'>Sähköpostiosoite</label>
          <input
            type='email'
            name='email'
            value={newUserEmail}
            className='inline-block w-full'
            onChange={e => setNewUserEmail(e.target.value)}
          />
        </div>
        <input type='submit' className='btn -primary' value='Anna käyttöoikeus' />
      </form>
    </div>
  )

  if (!rights.users || rights.users.length <= 0) {
    return 'Käyttöoikeuksia ei löydetty'
  }

  return (
    <>
      <table className='w-full styled'>
        <thead>
          <tr>
            <th>Käyttäjänimi</th>
            {isAdmin && <th>Oikeus</th>}
            {isAdmin && <th>Toiminnot</th>}
          </tr>
        </thead>
        <tbody>
          {rights.users.map(right =>
            <tr className='dark:bg-dark-lighter' key={`${right.users_id}-${right.username}`}>
              {right.username ? <td className={right.you && 'font-semibold'}>{right.username}</td> : <td className='italic'>Nimetön</td>}
              {isAdmin && right.level && <td>{term(right.level)}</td>}
              {isAdmin &&
                <td>
                  <button
                    onClick={() => handleDelete(right.users_id, right.username ? right.username : 'nimetön')}
                  >
                    <Icon.UserX />
                  </button>
                  {rights.user_level < right.level &&
                    <button
                      onClick={() => handlePut(right.users_id, 50)}
                    >
                      <Icon.UserPlus />
                    </button>}
                  {right.level < 100 &&
                    <button
                      onClick={() => handlePut(right.users_id, 100)}
                    >
                      <Icon.UserMinus />
                    </button>}
                </td>}
            </tr>
          )}
        </tbody>
      </table>
      {isAdmin && addUserForm}
    </>
  )
}

export default Rights
