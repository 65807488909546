import React, { useState, useEffect, useRef } from "react";
import { getAccess } from "../../services/access";
import "quill/dist/quill.snow.css"; // Import Quill styles
import NoticePreview from "./NoticePreview";
import { ChevronDown, ChevronUp } from "react-feather";

// When adding/removing fonts make sure to also edit CSS
let fontList = ["Arial", "Open Sans", "Roboto", "Montserrat"];

// Render Quill on client side only (it needs access to document object)
let ReactQuill = false;
if (typeof window != "undefined") {
  ReactQuill = require("react-quill");
  const { Quill } = ReactQuill;

  // Fonts (uncomment when handled in signage)
  /*
  let fonts = Quill.import("attributors/style/font");
  fonts.whitelist = fontList;
  Quill.register(fonts, true);
  */
}

// Quill toolbar settings
const modules = {
  toolbar: [
    ["bold", "italic", "underline", "strike"],
    [{ header: 1 }, { header: 2 }],
    [{ align: [] }, { indent: "-1" }, { indent: "+1" }],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }],
    // [{ 'font': fontList }],
    ["clean"],
    // ["image"]  // REMOVE IMAGE FOR PROD!!! no backend for those yet
  ],
};

// Quill format settings
var formats = [
  "background",
  "bold",
  "color",
  "font",
  "code",
  "italic",
  "link",
  "size",
  "strike",
  "script",
  "underline",
  "blockquote",
  "header",
  "indent",
  "list",
  "align",
  "direction",
  "code-block",
  "formula",
  // 'image'
  // 'video'
];

// Date conversion
const unixToInput = (unix) => {
  const date = new Date(unix * 1000);
  return date.toISOString().substring(0, 10);
};

// Quill Delta should be parsed to json to be able to use the formatting. Legacy notices can be kept as strings.
const TryJsonParse = (str) => {
  try {
    let parsed = JSON.parse(str);
    // console.log("String was succesfully parsed");
    return parsed;
  } catch (e) {
    // console.log("Couldn't parse, returning string");
    return str;
  }
};

const EditNotice = ({ ...props }) => {
  const [showPreview, setShowPreview] = useState(false);
  const [title, setTitle] = useState(props.title || "");
  const [notice, setNotice] = useState(props.notice ? TryJsonParse(props.notice) : "");
  const [noticePlainTxt, setNoticePlainTxt] = useState("");
  const [starting, setStarting] = useState(props.starting_date ? unixToInput(props.starting_date) : "");
  const [ending, setEnding] = useState(props.ending_date ? unixToInput(props.ending_date) : "");

  const titlearea = useRef(null);
  const editorRef = useRef(null);

  // Handle notice field edits
  const HandleEdits = () => {
    try {
      if (editorRef != null && editorRef.current.editor != undefined) {
        let content = editorRef.current.editor.getContents();
        setNotice(content);
      }
    } catch (err) {
      console.log(err);
    }
  };

  // Handle updating plain text to match notice
  useEffect(() => {
    if (editorRef.current) {
      setNoticePlainTxt(editorRef.current.editor.getText());
    }
  }, [notice]);

  // Incase title changes
  useEffect(() => {
    titlearea.current.parentNode.dataset.replicatedValue = title;
  }, [title]);

  const handleTitleParse = (value) => {
    value = value.replace(/[\n\r]/gm, "");
    if (value.length <= 125) setTitle(value);
  };

  // Handle submit and send data to server
  const handleSubmit = (event) => {
    event.preventDefault();

    // Define data
    const data = new window.FormData();
    starting && data.append("starting_date", new Date(starting).getTime() / 1000);
    ending && data.append("ending_date", new Date(ending).getTime() / 1000);
    title && data.append("title", title);

    // Convert notice to string and append
    let noticeString = JSON.stringify(notice);
    noticeString = noticeString.replace(/[\n\r]/gm, "");

    if (noticeString.length > 2200) {
      window.alert("Ilmoitus on liian suuri. Lyhennä ilmoitusta tai poista osa muotoiluista ja yritä uudelleen.");
    } else {
      notice && data.append("notice", noticeString);

      const requestOptions = {
        method: props.isNew ? "POST" : "PUT",
        headers: { Authorization: `Bearer ${getAccess()}` },
        body: data,
      };

      window
        .fetch(
          `${process.env.REACT_APP_EXTENSION_API_ENDPOINT}/v1/houses/${props.houses_id}/notices` + (!props.isNew ? `/${props.id}` : ""),
          requestOptions
        )
        .then((res) => res.json())
        .then(
          (result) => {
            console.log(result);
            window.alert("Tallennettu");
            console.log(props);
            props.close && props.close();
            props.reload && props.reload(props.houses_id);
          },
          (error) => {
            window.alert("Virhe, löytyy lokista");
            console.log(error);
          }
        );
    }
  };

  // Handle deletion
  const handleDelete = () => {
    const requestOptions = {
      method: "DELETE",
      headers: { Authorization: `Bearer ${getAccess()}` },
    };

    window
      .fetch(`${process.env.REACT_APP_EXTENSION_API_ENDPOINT}/v1/houses/${props.houses_id}/notices/${props.id}`, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          console.log(result);
          window.alert("Poistettu");
          props.close && props.close();
          props.reload && props.reload(props.houses_id);
        },
        (error) => {
          window.alert("Virhe poistossa, löytyy lokista");
          console.log(error);
        }
      );
  };

  return (
    <div
      key={props._key && props._key}
      className="bg-white p-3 w-full rounded shadow-sm my-3 whitespace-pre-line text-left outline-none ring border-blue-300 dark:bg-dark"
    >
      <form
        id="noticeform"
        className="flex flex-col gap-1"
        onSubmit={(event) => {
          event.preventDefault();
          handleSubmit(event);
        }}
      >
        {/* Title */}
        <div className="border border-grey-darker h-14 w-full pb-0 mb-2 dark:border-dark-lighter">
          <textarea
            className="w-full h-full p-3.5 resize-none text-lg, font-semibold dark:bg-dark-lighter dark:text-white"
            value={title}
            placeholder="Otsikko tulee tähän"
            autoComplete="off"
            onChange={(e) => handleTitleParse(e.target.value)}
            ref={titlearea}
          />
        </div>
        <p className="text-right text-sm m-0 pr-1 pb-3">{title.length} / 125 merkkiä</p>

        {/* Notice content */}
        {ReactQuill ? (
          <ReactQuill
            className="dark:bg-dark-lighter dark:text-white"
            theme="snow"
            value={notice}
            onChange={() => {
              HandleEdits();
            }}
            modules={modules}
            formats={formats}
            ref={editorRef}
          />
        ) : (
          <p className="dark:text-white">Tekstieditoria ei saatu ladattua...</p>
        )}

        {/* Character count */}
        <p className="text-right text-sm mt-2 pr-1 dark:text-white">{noticePlainTxt.length - 1} / 2200 merkkiä</p>

        {/* Preview */}
        <button
          className="link flex items-center gap-2"
          type="button"
          onClick={() => {
            setShowPreview(!showPreview);
          }}
        >
          {showPreview ? <ChevronUp /> : <ChevronDown />} <span>{showPreview ? "Sulje esikatselu" : "Näytä esikatselu"}</span>
        </button>
        {showPreview && <NoticePreview className="my-6" title={title} noticeOps={notice.ops != null ? notice.ops : [{ insert: notice }]} />}

        {/* Settings */}
        <div className="flex justify-between gap-2 my-3">
          <div className="field w-full md:w-72">
            <label className="dark:text-white" htmlFor="starting">
              Julkaistaan
            </label>
            <input
              type="date"
              value={starting}
              autoComplete="off"
              onChange={(e) => setStarting(e.target.value)}
              name="starting"
              className="w-full dark:bg-dark-lighter dark:border-dark-lighter dark:text-white"
            />
          </div>
          <div className="field w-full md:w-72 m-0">
            <label className="dark:text-white" htmlFor="ending">
              Piilotetaan
            </label>
            <input
              type="date"
              name="ending"
              autoComplete="off"
              value={ending}
              onChange={(e) => setEnding(e.target.value)}
              className="w-full dark:bg-dark-lighter dark:border-dark-lighter dark:text-white"
            />
          </div>
        </div>

        {/* Buttons */}
        <div className="flex flex-col md:flex-row justify-between gap-2">
          <div>
            <input type="submit" className="w-full md:w-auto btn -primary" value={props.isNew ? "Julkaise" : "Tallenna"} />
          </div>
          <div className="flex flex-col md:flex-row gap-2">
            <button className="btn -secondary w-full md:w-auto" onClick={() => props.close()}>
              Sulje
            </button>
            {!props.isNew && (
              <button className="btn -red w-full md:w-auto" onClick={() => handleDelete()}>
                Poista ilmoitus
              </button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditNotice;
