import React, { useState, useEffect } from 'react';
import { Home, Monitor, Info, Calendar, MessageSquare } from "react-feather";
import ThemeSwitcher from '../../ThemeSwitcher';

const HouseNav = ({ view, setView, ...props }) => {

  const [navbarVisible, setNavbarVisible] = useState(true);

  const changeView = (view) => {
    setView(view);
    setNavbarVisible(false);
  };

  const [newsNotificationCount, setNewsNotificationCount] = useState(1);


  const toggleNavbar = () => {  
    setNavbarVisible(!navbarVisible);
  };                                  
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       
  // Add event listener on component mount                   
  useEffect(() => {                                                                           
    const handleResize = () => {
      setNavbarVisible(false);
    };
    
    /*function toggleTheme(){
      document.documentElement.classList.add('dark');
    }*/

    // Initial check and set up event listener
    handleResize();
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
    <ul id='resources-navbar' className={`flex flex-col md:flex-row lg:flex-col w-full lg:h-full gap-2 p-0 m-0 ${!navbarVisible ? 'hidden md:flex' : 'md:flex'}`}>
      <li className='list-none'><button onClick={() => changeView('notices')} disabled={view === 'notices'} className='btn -hoverblue flex flex-row gap-2 items-center dark:text-white'><Calendar />Ilmoitukset</button></li>
      <li className='list-none'><button onClick={() => changeView('views')} disabled={view === 'views'} className='btn -hoverblue flex flex-row gap-2 items-center dark:text-white'><Monitor />Näkymät</button></li>
      <li className='list-none'><button onClick={() => changeView('houses')} disabled={view === 'houses'} className='btn -hoverblue flex flex-row gap-2 items-center dark:text-white'><Home />Taloyhtiöt</button></li>
      <li className='list-none'>
        <button onClick={() => changeView('news')} disabled={view === 'news'} className='btn -hoverblue flex flex-row gap-2 items-center dark:text-white relative'>
          <MessageSquare />
          Uutiset
          {newsNotificationCount > 0 && (
            <span className="notification-badge bg-gradient-to-r from-red-400 to-red-500 text-white rounded-full px-2 py-1 text-xs absolute right-0  mr-1">
             {newsNotificationCount}
            </span>
          )}
        </button>
      </li>
      <li className='list-none'><button onClick={() => changeView('instructions')} disabled={view === 'instructions'} className='btn -hoverblue flex flex-row gap-2 items-center dark:text-white'><Info />Ohjeet</button></li>
      <div className="">
        <ThemeSwitcher />
      </div>

      {/* <li className='list-none'><button onClick={() => changeView('settings')} disabled={view === 'settings'} className='btn -hoverblue'>Asetukset</button></li> */}
    </ul>
    <div className='flex flex-row justify-end md:hidden'>
      <button
      onClick={toggleNavbar}
      data-collapse-toggle='resources-navbar'
      type='button'
      className='inline-flex w-10 h-10 rounded-lg'
      aria-expanded={navbarVisible}
      >
        <svg width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'
        >
          {navbarVisible ? (
            <>
            <line x1='10%' y1='90%' x2='90%' y2='10%' stroke='black' />
            <line x1='10%' y1='10%' x2='90%' y2='90%' stroke='black' />
            </>
          ) : (
            <>
            <line x1='10%' y1='75%' x2='90%' y2='75%' stroke='black' />
            <line x1='10%' y1='50%' x2='90%' y2='50%' stroke='black' />
            <line x1='10%' y1='25%' x2='90%' y2='25%' stroke='black' />
            </>
          )}
        </svg>
      </button>
    </div>
    </>
  )
}

export default HouseNav
