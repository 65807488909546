import React, { useState, useEffect, useMemo } from 'react'
import { getTeams } from '../../services/teams'
import { Link } from 'gatsby'

import Team from './team.js'

const Teams = ({ urlTeamID, props }) => {
  const [teamID, setTeamID] = useState(false)
  const [team, setTeam] = useState(false)
  const [teams, setTeams] = useState(false)

  useEffect(() => {
    getTeams((res = false) => {
      if (res) setTeams(res)
      if (!res) window.alert('Tiimien lataamisessa on ongelma')
    })
  }, [])

  useEffect(() => {
    if (teamID && teams) {
      const team = teams.teams.find((team) => team.$id === teamID)
      console.log(team)
      setTeam(team)
    }
  }, [teams])

  useEffect(() => {
    if (urlTeamID) {
      setTeamID(urlTeamID)
    }
  }, [urlTeamID])

  const output = useMemo(() => {
    if (!teams) return <p>Ladataan</p>

    if (teams.sum < 1) {
      return (
        <p>Et kuulu mihinkänä tiimiin</p>
      )
    }

    return (
      <div className='flex flex-col'>
        <div className='-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
          <div className='py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8'>
            <div className='shadow-sm overflow-hidden border-b border-gray-200 sm:rounded-lg'>
              <table className='min-w-full divide-y divide-gray-200'>
                <thead className='bg-gray-50'>
                  <tr>
                    <th scope='col' className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                      Nimi
                    </th>
                    <th scope='col' className='relative px-6 py-3'>
                      <span className='sr-only'>Avaa</span>
                    </th>
                  </tr>
                </thead>
                <tbody className='bg-white divide-y divide-gray-200'>
                  {teams.teams.map(team =>
                    <tr key={team.$id}>
                      <td className='px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900'>
                        {team.name}
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap text-right text-sm font-medium'>
                        <Link to={`/app/profile/teams/${team.$id}`}>Avaa</Link>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    )
  }, [teams])

  if (team) {
    return <Team team={team} />
  }

  return (
    <>
      <h2>Tiimit</h2>
      {output}
    </>
  )
}

export default Teams
