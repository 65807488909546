import React, { useState, useEffect } from 'react'
import * as Icon from 'react-feather'

const List = ({ item, changeOptions, handleRemoveItem, handleSave, ...props }) => {
  const [editing, setEditing] = useState(false)
  const [list, setList] = useState(item.item.data || [])

  const handleChange = (line, row, target) => {
    if (target.value.length > 25) return
    const arr = JSON.parse(JSON.stringify(list))
    arr[line][row] = target.value
    setList(arr)
  }

  const handleAddRow = () => {
    const arr = JSON.parse(JSON.stringify(list))
    arr.push(['', '', '', ''])
    setList(arr)
  }

  const handleDeleteRow = row => {
    const arr = JSON.parse(JSON.stringify(list))
    arr.splice(row, 1)
    setList(arr)
  }

  useEffect(() => {
    if (item.id === 'new') setEditing(true)
  }, [item])

  useEffect(() => {
    if (!changeOptions) return
    const arr = []

    if (editing || typeof editing === 'number') {
      arr.push({
        label: 'Tallenna',
        onClick: () => {
          const edited = { ...item }
          edited.item.data = list
          delete edited.empty
          handleSave(edited)
          setEditing(false)
        }
      }, {
        label: 'Peruuta',
        onClick: () => {
          if (handleRemoveItem &&
            item.id === 'new') {
            if (window.confirm('Poistetaanko elementti?')) handleRemoveItem('new')
            return
          }
          setEditing(false)
          setList(item.item.data || [])
        }
      })
    }

    changeOptions(arr)
  }, [item, editing, list, changeOptions, handleRemoveItem, handleSave])

  const returnList = list => {
    return list.map((line, lineIndex) =>
      <tr key={lineIndex}>
        {editing && <td className='flex justify-around items-center'><button aria-label='edit' onClick={() => handleDeleteRow(lineIndex)}><Icon.X /></button></td>}
        {(line.length < 4 ? [...line, Array(4 - line.length).fill('')] : line).map((cell, index) =>
          editing
            ? (
              <td key={index} className='py-0.5 text-base'>
                <input className='bg-gray-50 rounded-sm' type='text' value={cell} maxLength='25' size='12' onChange={e => handleChange(lineIndex, index, e.target)} />
              </td>)
            : <td key={index} className='py-0.5 text-left'>{cell}</td>
        )}
      </tr>
    )
  }

  return (
    <div className='h-full w-full max-h-full max-w-full text-lg relative overflow-hidden flex flex-col justify-around items-center '>
      {handleSave && !editing
        ? (
          <button onClick={() => setEditing(true)} className='w-full'>
            <table className='w-full'>
              <tbody>
                {returnList(list)}
              </tbody>
            </table>
          </button>
        )
        : (
          <>
            <table className='w-full'>
              <tbody>
                {returnList(list)}
              </tbody>
            </table>
            {editing && list.length < 6 && <button onClick={() => handleAddRow()}>Lisää rivi</button>}
          </>
        )}
    </div>
  )
}

export default List
