import React from 'react'
import { navigate } from 'gatsby'
import { isLoggedIn } from '../services/auth'
import { handleRecover } from '../services/recovery'
import { logs } from './../utils'

class Login extends React.Component {
  state = {
    userId: '',
    secret: '',
    email: '',
    password: ''
  }

  handleUpdate = event => {
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  handleSubmit = event => {
    event.preventDefault()
    handleRecover(this.state)
  }

  componentDidMount = () => {
    if (this.state.userId === '' && window.location.search !== '') {
      const urlParams = new URLSearchParams(window.location.search)
      if (urlParams.has('expire') && urlParams.get('expire') < ~~(Date.now() / 1000)) {
        navigate('/app/login')
        return
      }
      if (urlParams.has('userId') && urlParams.has('secret') && urlParams.has('email')) {
        try {
          this.setState({
            userId: urlParams.get('userId'),
            secret: urlParams.get('secret'),
            email: window.atob(urlParams.get('email'))
          })
          return
        } catch (e) {
          if (logs) console.log(e)
        }
      }
    }
    navigate('/app/login')
  }

  render () {
    if (isLoggedIn()) {
      navigate('/app/profile')
    }

    return (
      <div className='container'>
        <h1 className='mt-10'>Vaihda salasana</h1>
        <form
          method='post'
          onSubmit={event => {
            this.handleSubmit(event)
          }}
        >
          <div className='field'>
            <label htmlFor='password'>
              Uusi salasana
            </label>
            <input type='password' name='password' onChange={this.handleUpdate} />
          </div>

          <input type='submit' value='Vaihda salasana' className='btn -primary' />
        </form>
      </div>
    )
  }
}

export default Login
