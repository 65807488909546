import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'gatsby'
import * as Icon from 'react-feather'
import { appwrite, logs } from '../utils'
import IconBuilding from '../images/icons/building.svg'
import {
  getCampaignResources,
  patchCampaign,
  postCampaignResources,
  putCampaignResources
} from '../services/extension'

// Some sweet animations
import 'animate.css'

const Handle = ({ ...props }) => {
  const [open, setOpen] = useState(false)
  const [doc, setDocument] = useState(false)
  const [interest, setInterest] = useState('c')
  const [resources, setResources] = useState(false)
  const [filter, setFilter] = useState('now')
  const [loading, setLoading] = useState(false)
  const [saijuKey, setSaijuKey] = useState(false)
  const [saijuLoading, setSaijuLoading] = useState(false)
  const [saijuType, setSaijuType] = useState(false)
  const [campaignAds, setCampaignAds] = useState(false)
  const [rename, setRename] = useState(false)

  const time = Math.floor(new Date().getTime() / 1000)

  const campaignFilters = useMemo(() => {
    const time = Math.floor(new Date().getTime() / 1000)
    return {
      future: {
        filter: [`from>=${time}`],
        label: 'Tuleva'
      },
      now: {
        filter: [`from<=${time}`, `to>=${time}`],
        label: 'Nyt'
      },
      history: {
        filter: [`to<=${time}`],
        label: 'Menneet'
      }
    }
  }, [])

  useEffect(() => {
    if (props.interest) {
      setInterest(props.interest)
    }
  }, [props.interest])

  useEffect(() => {
    if (props.campaignID) {
      setOpen(props.campaignID)
    }
  }, [props.campaignID])

  useEffect(() => {
    setLoading(true)

    let collection = false
    switch (interest) {
      case 'c':
        collection = process.env.REACT_APP_APPWRITE_COLLECTION_ORDER
        break
      case 'h':
        collection = process.env.REACT_APP_APPWRITE_COLLECTION_ORDER
        break
      default:
        setInterest('c')
        return
    }

    if (collection) {
      const promise = appwrite.database.listDocuments(collection, campaignFilters[filter].filter)
      promise.then(function (response) {
        if (logs) console.log('Documents list success: ', response)
        setResources(response)
      }, function (error) {
        if (logs) console.log('Documents list error: ', error)
        window.alert('Lataamisessa ongelma')
      }).then(() => setLoading(false))
    }
  }, [interest, filter, campaignFilters])

  useEffect(() => {
    setLoading(true)
    setSaijuKey(false)

    let collection = false
    switch (interest) {
      case 'c':
        collection = process.env.REACT_APP_APPWRITE_COLLECTION_ORDER
        break
      case 'h':
        collection = process.env.REACT_APP_APPWRITE_COLLECTION_ORDER
        break
      default:
        return
    }

    if (open && collection) {
      const promise = appwrite.database.getDocument(collection, open)
      promise.then(function (response) {
        if (logs) console.log('Get document success: ', response)
        setDocument(response)
        if (interest === 'c' && response.campaigns_id) {
          getCampaignResources(response.campaigns_id, (res = false) => {
            if (res) setCampaignAds(res)
            if (!res) window.alert('Mainosten lataamisessa on ongelma')
          })
        }
      }, function (error) {
        if (logs) console.log('Get document error: ', error)
        window.alert('Lataamisessa ongelma')
      }).then(() => setLoading(false))
    }
  }, [open, interest])

  const handleGetSaiju = (campaignsId, type) => {
    setSaijuType(type)
    const data = new window.FormData()
    data.set('type', type)
    postCampaignResources(data, campaignsId, (res = false) => {
      if (res && res.jwt) setSaijuKey(res.jwt)
      if (!res) window.alert('Lataamisessa on ongelma')
    })
  }

  const handlePostSaiju = event => {
    event.preventDefault()
    const data = new window.FormData(event.target)
    if (data.get('file').size > (saijuType === 'image' ? 2500000 : 5000000)) {
      window.alert('Tiedosto on liian iso')
      return
    }
    setSaijuLoading(true)
    window.fetch(`${process.env.REACT_APP_SAIJU_ENDPOINT}/v1`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${saijuKey}`
      },
      body: data
    })
      .then(response => {
        setSaijuLoading(false)
        if (response.status >= 200 && response.status <= 299) {
          setSaijuKey(false)
          setSaijuType(false)
          getCampaignResources(doc.campaigns_id, (res = false) => {
            if (res) setCampaignAds(res)
            if (!res) window.alert('Mainosten lataamisessa on ongelma')
          })
          return
        }
        window.alert('Tiedoston lataaminen epäonnistui.')
      })
  }

  const handlePutCampaignResources = (campaignsId, mediasId, trend) => {
    const data = new window.FormData()
    data.set('trend', trend)
    data.set('medias_id', mediasId)
    putCampaignResources(data, campaignsId, (res = false) => {
      if (res) {
        getCampaignResources(campaignsId, (res = false) => {
          if (res) setCampaignAds(res)
          if (!res) window.alert('Mainosten lataamisessa on ongelma')
        })
      }
      if (!res) window.alert('Päivittämisessä ongelma')
    })
  }

  const handleRename = (event, campaignsId) => {
    event.preventDefault()
    const data = new window.FormData(event.target)
    patchCampaign(data, campaignsId, (res = false) => {
      if (res) {
        if (res.updated) {
          const nDoc = JSON.parse(JSON.stringify(doc))
          nDoc.label = data.get('label')
          setDocument(nDoc)
        }
      }
      if (!res) window.alert('Nimen päivittämisessä ongelma')
    })
    setRename(false)
  }

  const fIs = value => filter === value

  return (
    <>
      <div className='container flex flex-col p-4'>
        <h1 className='p-4 m-0'>Resurssit</h1>
        <div className='grid grid-cols-6'>
          <div className={`col-span-6 flex h-1.5 top-0 left-0 z-20 animate__animated ${loading ? 'animate__fadeIn' : 'animate__fadeOut'}`}>
            <span className='w-full h-1.5 bg-blue-600 rounded-t bg-gradient-to-r from-blue-400 via-indigo-500 to-green-400 animate-gradient-x' />
          </div>
          <div className='col-span-6 md:col-span-2 self-start bg-white md:rounded-l-md sticky top-0 shadow z-10 border-r border-gray-300 p-4 flex flex-col gap-4'>
            <div className='flex items-center rounded overflow-hidden shadow-sm'>
              <Link
                className={interest === 'c' ? ' link flex-auto flex items-center gap-2 p-3 bg-gray-50 cursor-default ' : 'p-3 bg-blue-600 text-white transition hover:bg-blue-700 '}
                disabled={interest === 'c'}
                to='/app/handle/c'
              >
                <Icon.Briefcase className='h-auto w-8 text-current' />
                <span className={interest === 'c' ? 'font-semibold ' : 'sr-only'}>Kampanjat</span>
              </Link>
              <Link
                className={interest === 'h' ? ' link flex-auto flex items-center gap-2 p-3 bg-gray-50 cursor-default' : 'p-3 bg-blue-600 text-white transition hover:bg-blue-700 '}
                disabled={interest === 'h'}
                to='/app/handle/h'
              >
                <IconBuilding className='h-auto w-8 text-current' />
                <span className={interest === 'h' ? 'font-semibold ' : 'sr-only'}>Taloyhtiöt</span>
              </Link>
            </div>

            <div className='flex items-center rounded overflow-hidden shadow-sm text-sm'>
              {Object.keys(campaignFilters).map(filtr => (
                <button
                  key={filtr}
                  className={`flex-auto flex items-center justify-around gap-2 p-2 bg-gray-50 font-semibold hover:bg-gray-100 transition ${fIs(filtr) ? 'bg-blue-600 text-white hover:bg-blue-700' : ''}`}
                  onClick={() => setFilter(filtr)}
                >
                  <span>{campaignFilters[filtr].label}</span>
                </button>
              ))}
            </div>

            <ul className='p-0 flex flex-col gap-4'>
              {
                resources && resources.documents.map(item => {
                  if (!item.selected) return false
                  const current = open === item.$id
                  return (
                    <li key={item.$id} className='list-none'>
                      <Link to={`/app/handle/${interest}/${item.$id}`} className={'link p-3 gap-3 font-semibold text-base flex flex-col gap-2 w-full rounded-md hover:bg-blue-50 hover:text-blue-800 ' + (current && 'bg-blue-50 text-blue-800')}>
                        <div className='flex-auto text-left flex flex-col gap-0.5'>
                          <span className='text-left text-xs font-semibold text-gray-500 uppercase tracking-wider'>Kampanja {item.from && item.to && item.from > time ? 'Alkamassa' : item.to > time ? 'Menossa' : 'Päättynyt'}</span>
                          {item.label ? item.label : item.label}
                        </div>
                        <div className='flex items-center gap-2'>
                          <Icon.Play className='h-auto w-4 text-current' />
                          <span className='flex items-center gap-2'>
                            {new Date(item.from * 1000).toLocaleString()}
                          </span>
                        </div>
                        <div className='flex items-center gap-2'>
                          <Icon.Pause className='h-auto w-4 text-current' />
                          <span className='flex items-center gap-2'>
                            {new Date(item.to * 1000).toLocaleString()}
                          </span>
                        </div>
                        {item.selected && (
                          <div className='flex items-center gap-2'>
                            <Icon.Monitor className='h-auto w-4 text-current' />
                            <span className='flex items-center gap-2'>
                              {item.provisioned && item.selected.length === item.provisioned.length
                                ? item.selected.length
                                : (
                                  <>
                                    {item.selected.length}
                                    <span className='bg-yellow-500 p-0.5 px-2 text-white rounded-full text-sm'>Provisioitu {item.provisioned ? item.provisioned.length : 0}</span>
                                  </>
                                  )}
                            </span>
                          </div>
                        )}
                        <div className='flex items-center gap-2'>
                          {item.length && (
                            <>
                              <Icon.Clock className='h-auto w-4 text-current' />
                              <span title='Mainoksen kesto'>{item.length}<small>s</small></span>
                            </>
                          )}
                          {item.plays && (
                            <>
                              <span title='Toistojen määrä tunnissa'>{item.plays}<small>kpl/h</small></span>
                            </>
                          )}
                          {(item.plays && item.length) && (
                            <span title='Aikaa tunnissa'>{item.plays * item.length}<small>s/h</small></span>
                          )}
                        </div>
                        {false && item.from && item.from < time && (
                          <div className='flex w-full gap-1 flex-no-wrap'>
                            <div className='flex-auto flex flex-col text-left gap-1'>
                              <span className='text-xs font-semibold text-green-500 uppercase tracking-wider'>50%</span>
                              <span className='bg-green-500 h-2 rounded-l-full w-full' />
                              <span className='text-xs font-semibold text-green-500 uppercase tracking-wider'>Toistettu</span>
                            </div>
                            <div className='flex-auto flex flex-col text-right gap-1'>
                              <span className='text-xs font-semibold text-gray-500 uppercase tracking-wider'>50%</span>
                              <span className='bg-gray-500 h-2 rounded-r-full w-full' />
                              <span className='text-xs font-semibold text-gray-500 uppercase tracking-wider'>Toistamatta</span>
                            </div>
                          </div>
                        )}
                      </Link>
                    </li>
                  )
                })
              }
            </ul>
          </div>
          <div className='col-span-6 md:col-span-4 flex-initial shadow md:rounded-r-md sticky top-0 bg-white p-6'>
            {doc && doc.$id && doc.campaigns_id && (
              <>
                <div className='inline-flex'>
                  <div className='mb-6 flex gap-1.5 items-center'>
                    {rename
                      ? (
                        <>
                          <form
                            className='flex gap-1.5 items-center'
                            onSubmit={e => handleRename(e, doc.campaigns_id)}
                          >
                            <input
                              type='text'
                              className='p-2 border border-gray-200 rounded-lg leading-none bg-white focus:ring-indigo-500 focus:border-indigo-500 disabled:opacity-50 disabled:cursor-not-allowed disabled:bg-gray-300'
                              defaultValue={doc.label ? doc.label : doc.$id}
                              name='label'
                              minLength='2'
                              maxLength='35'
                              autoComplete='off'
                            />
                            <input type='hidden' value={doc.$id} name='$id' />
                            <button
                              className='btn -primary'
                              type='submit'
                            >
                              Tallenna
                            </button>
                          </form>
                        </>
                        )
                      : (
                        <>
                          <h1 className='m-0'>{doc.label ? doc.label : doc.$id}</h1>
                          <button
                            className='p-1  '
                            onClick={() => setRename(true)}
                          >
                            <Icon.Edit2 className='h-auto w-5 text-gray-500 hover:text-black transition' />
                            <span className='sr-only'>Nimeä kampanja</span>
                          </button>
                        </>
                        )}
                  </div>
                  <Link to={`/app/map/${doc.$id}`}>
                    <button className='btn'>
                      Muuta tilausta
                    </button>
                  </Link>
                </div>
                <div>
                  {campaignAds
                    ? campaignAds.playing && campaignAds.playing.length > 0
                        ? (
                          <div className='grid grid-cols-4 gap-6 mb-4'>
                            {campaignAds.playing.map(ad => (
                              <div key={ad.id} className='group relative flex items-center justify-center'>
                                <img
                                  src={ad.compressed}
                                  className='w-full max-h-full overflow-hidden filter group-hover:blur-sm transition'
                                  alt={ad.id}
                                />
                                <div className='absolute flex gap gap-2'>
                                  {ad.compressed && <a className='btn opacity-0 group-hover:opacity-100 transition' target='_blank' rel='noopener noreferrer' href={ad.compressed}>Avaa</a>}
                                  {ad.original && <a className='btn opacity-0 group-hover:opacity-100 transition flex items-center justify-center' target='_blank' rel='noopener noreferrer' href={ad.original}><Icon.File className='h-auto w-4 text-current' aria-label='link' /></a>}
                                </div>
                                <button
                                  className='btn -red p-2 m-2 opacity-25 group-hover:opacity-100 transition rounded-full absolute top-0 right-0'
                                  onClick={() => handlePutCampaignResources(doc.campaigns_id, ad.id, 'down')}
                                >
                                  <Icon.X className='h-auto w-4 text-current' />
                                  <span className='sr-only'>Poista mainos</span>
                                </button>
                              </div>
                            ))}
                          </div>
                          )
                        : (
                          <p>Ei yhtään mainosta valittuna tai ladattuna</p>
                          )
                    : <p>Ladataan mainoksia</p>}
                </div>
                <div className='bg-blue-100 rounded p-4'>
                  <p>Lataa uutta materiaalia kampanjaan.</p>
                  {saijuKey
                    ? (
                      <>
                        {saijuType === 'image'
                          ? (
                            <p>Olet lataamassa <b>kuvaa</b>. Kuvan maksimi tiedostokoko on 2.5mt ja pikseleinä parhaan tuloksen saat, kun lataat kuvan koossa 960 x 970 (järjestelmä pakkaa, rajaa ja keskittää kuvan). Sallittuja formaatteja on PNG ja JPG. Pääset lataamisen jälkeen katsomaan alkuperäisen kuvan ja pakatun version.</p>
                            )
                          : (
                            <p>Olet lataamassa <b>videota</b>. Videon maksimi tiedostokoko on 5mt ja pikseleinä parhaan tuloksen saat, kun lataat videon koossa 960 x 970 (järjestelmä pakkaa, rajaa ja keskittää kuvan). Sallittu formaatti on MP4. Pääset lataamisen jälkeen katsomaan alkuperäisen videon ja pakatun version.</p>
                            )}
                        {saijuLoading
                          ? (
                            <p><Icon.Loader className='h-auto w-4 text-current animate-spin' /> Lähetetään ja käsitellään tiedosto.</p>
                            )
                          : (
                            <form onSubmit={event => handlePostSaiju(event)}>
                              <input
                                type='file'
                                name='file'
                                size={saijuType === 'image' ? 2500000 : 5000000}
                                accept={saijuType === 'image' ? 'image/png, image/jpeg' : 'video/mp4'}
                                required
                              />
                              <button className='btn' type='submit'>Lataa</button>
                            </form>
                            )}
                      </>
                      )
                    : (
                      <div
                        className='flex gap-4'
                      >
                        <button onClick={event => handleGetSaiju(doc.campaigns_id, 'image')} className='btn'>Kuva</button>
                        <button onClick={event => handleGetSaiju(doc.campaigns_id, 'video')} className='btn'>Video</button>
                      </div>
                      )}
                </div>
                <h3 className='mt-4'>Aikaisempia latauksia</h3>
                {campaignAds
                  ? campaignAds.resources && campaignAds.resources.length > 0
                      ? (
                        <div className='grid grid-cols-4 gap-6 mb-4'>
                          {campaignAds.resources.map(ad => (
                            <div key={ad.id} className='group relative flex items-center justify-center'>
                              <img
                                src={ad.compressed}
                                className='w-full max-h-full overflow-hidden filter group-hover:blur-sm transition'
                                alt=''
                              />
                              <div className='absolute flex gap gap-2'>
                                {ad.compressed && <a className='btn opacity-0 group-hover:opacity-100 transition' target='_blank' rel='noopener noreferrer' href={ad.compressed}>Avaa</a>}
                                {ad.original && <a className='btn opacity-0 group-hover:opacity-100 transition flex items-center justify-center' target='_blank' rel='noopener noreferrer' href={ad.original}><Icon.File className='h-auto w-4 text-current' aria-label='link' /></a>}
                              </div>
                              <button
                                className='btn -blue p-2 m-2 opacity-25 group-hover:opacity-100 transition rounded-full absolute top-0 right-0'
                                onClick={() => handlePutCampaignResources(doc.campaigns_id, ad.id, 'up')}
                              >
                                <Icon.ChevronUp className='h-auto w-4 text-current' />
                                <span className='sr-only'>Nosta mainos esille</span>
                              </button>
                            </div>
                          ))}
                        </div>
                        )
                      : (
                        <p>Ei aikaisempia latauksia</p>
                        )
                  : null}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default Handle
