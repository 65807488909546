import React from "react";
import Notice from "./notice";

const Notices = ({ notices, ...props }) => {
  if (!notices || notices.length == 0)
    return (
      <>
        <p className="dark:text-grey">Ei ilmoituksia</p>
      </>
    );

  return notices.map((notice) => {
    let key = `${notice.houses_id}-${notice.id}`;
    if (props.timed) key += `-${props.timed}`;
    return <Notice key={key} _key={key} reload={props.reload && props.reload} {...notice} />;
  });
};

export default Notices;
