import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Link, navigate } from "gatsby";
import { handleLogin, isLoggedIn } from "../services/auth";

const Login = ({ props }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    handleLogin({ email, password });
  };

  if (isLoggedIn()) {
    navigate("/app/profile");
  }

  return (
    <div className="container flex items-center justify-center flex-col py-12">
      {/* Page title */}
      <Helmet>
        <title>Kirjaudu | Opasti</title>
      </Helmet>

      <div className="flex items-center">
        <h1 className="">Kirjaudu</h1>
      </div>
      <div className="flex flex-col ease-out duration-300 align-bottom bg-white rounded-lg text-left overflow-hidden p-6 sh-modal-form transform transition-all sm:my-8 sm:align-middle sm:max-w-sm w-full">
        <form
          method="post"
          onSubmit={(event) => {
            handleSubmit(event);
          }}
          className="flex flex-col gap-3"
        >
          <div className="field mx-0">
            <label htmlFor="email">Sähköpostiosoite</label>
            <input
              type="email"
              name="email"
              value={email}
              className="inline-block w-full"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="field mx-0">
            <label htmlFor="password">Salasana</label>
            <input
              type="password"
              name="password"
              value={password}
              className="inline-block w-full"
              onChange={(e) => setPassword(e.target.value)}
            />
            <Link to="/app/recovery" className="mt-2 float-right underline text-sm">
              Unohditko salasanasi?
            </Link>
          </div>
          <input type="submit" className="btn -primary -big w-full" value="Kirjaudu" />
        </form>
        <p className="text-center pt-4">
          Ei vielä Opasti-käyttäjää?{" "}
          <Link to="/app/register" className="underline">
            Rekisteröidy
          </Link>
        </p>
      </div>
    </div>
  );
};

export default Login;
