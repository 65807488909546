import { appwrite, logs } from './../utils'

export const handleTeamCreation = ({ name }, callback) => {
  const promise = appwrite.teams.create(name)
  promise.then(function (response) {
    if (logs) console.log('Team creation success: ', response)
    callback()
  }, function (error) {
    if (logs) console.log('Team creation error: ', error)
  })
}

export const getTeams = callback => {
  const promise = appwrite.teams.list()
  promise.then(function (response) {
    if (logs) console.log('Teams list success: ', response)
    callback(response)
  }, function (error) {
    if (logs) console.log('Teams list error: ', error)
    callback()
  })
}

export const getTeam = ({ teamId, callback }) => {
  const promise = appwrite.teams.get(teamId)
  promise.then(function (response) {
    if (logs) console.log('Team get success: ', response)
    callback(response)
  }, function (error) {
    if (logs) console.log('Team get error: ', error)
    callback()
  })
}

export const getTeamMembers = ({ teamId, callback }) => {
  const promise = appwrite.teams.getMemberships(teamId)
  promise.then(function (response) {
    if (logs) console.log('Team members get success: ', response)
    callback(response)
  }, function (error) {
    if (logs) console.log('Team members get error: ', error)
    callback()
  })
}
