import React from "react";
import DOMPurify from "dompurify";

var QuillDeltaToHtmlConverter = require("quill-delta-to-html").QuillDeltaToHtmlConverter; // Converter for Quill

const NoticePreview = React.memo(({ noticeOps, title }) => {
  // Convert Quill delta to html
  const QuillDeltaToHTML = () => {
    let deltaOps = noticeOps;
    let cfg = {
      inlineStyles: true,
    };
    let converter = new QuillDeltaToHtmlConverter(deltaOps, cfg);
    let html = converter.convert();

    // Handle html sanitizing (for security) and render
    if (DOMPurify.isSupported) {
      html = DOMPurify.sanitize(html);
    }

    return (
      <>
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </>
    );
  };

  return (
      <div className="p-4 w-full border bg-white">
        <p className="text-2xl md:text-3xl font-semibold pb-4">{title}</p>
        {QuillDeltaToHTML()}
      </div>
  );
});

export default NoticePreview;
