import { isBrowser } from './auth'

export const getCart = () =>
  isBrowser() && window.localStorage.getItem('cart')
    ? JSON.parse(window.localStorage.getItem('cart'))
    : false

export const setCartStorage = cart =>
  window.localStorage.setItem('cart', JSON.stringify(cart))

export const removeCart = () => window.localStorage.removeItem('cart')
