import React from "react";
import { Link, navigate } from "gatsby";
import { isLoggedIn, getAccount, logout } from "../services/auth";

import { Key, LogOut } from "react-feather";

import logo from "../images/logo.min.svg";
import logodark from "../images/darkthemelogo.svg";


export default function NavBar() {
  // let greetingMessage = ''
  // if (isLoggedIn()) {
  //   greetingMessage = `Hello ${getAccount().name}`
  // } else {
  //   greetingMessage = 'You are not logged in'
  // }
  return (
    <header className="sticky top-0 left-0 w-full shadow-md bg-white z-30 h-16 md:h-20 dark:bg-dark-navbar">
      <div className="px-6 lg:px-8 xl:px-4 py-4 lg:py-6 flex justify-between items-center h-full">
        <Link to="/">
          <span className="sr-only">Opasti</span>
          <img className="h-9 block dark:hidden" alt="" src={logo} />
          <img class="h-9 object-contain sticky hidden dark:block" alt="" src={logodark} />
        </Link>
        
        {isLoggedIn() && (
          <nav className="flex space-x-6 items-center">
            <Link to="/app/handle">Resurssit</Link>
            <Link to="/app/map">Kartta</Link>
          </nav>
        )}
        <nav className="flex items-center space-x-4 gap-2">
          {isLoggedIn() ? (
            <>
              <Link to="/app/profile" className="hover:no-underline">
                {getAccount().name ? (
                  <div className="flex flex-col">
                    <span className="-mb-1.5 -ml-1 text-black text-sm">
                      Hei,
                    </span>
                    <span className="text-lg capitalize">
                      {getAccount().name.split(" ")[0]}
                    </span>
                  </div>
                ) : (
                  <>Asetukset</>
                )}
              </Link>
              <a
                href="/"
                onClick={(event) => {
                  event.preventDefault();
                  logout(() => navigate("/app/login"));
                }}
              >
                <LogOut className="sm:inline w-5 h-5" />
              </a>
            </>
          ) : (
            <>
              <Link
                to="/app/access"
                className="flex space-x-1 items-center hover:text-gray-500 hover:no-underline"
              >
                <Key className="hidden sm:inline w-5 h-5" />
                <span className="text-right">Taloyhtiö & käyttöoikeus</span>
              </Link>
              <Link to="/app/login" className="btn -primary">
                Mainostaja
              </Link>
            </>
          )}
        </nav>
      </div>
    </header>
  );
}
