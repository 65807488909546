import { isBrowser } from './auth'

export const getAccess = () =>
  isBrowser() && window.localStorage.getItem('access') // TODO: exp handler
    ? window.localStorage.getItem('access')
    : false

export const setAccess = token =>
  window.localStorage.setItem('access', token)

export const hasAccess = () => {
  const access = getAccess()
  return !!access
}

export const removeAccess = () => window.localStorage.removeItem('access')
