import React, { useEffect, useState, useMemo, useRef } from 'react'
import * as Icon from 'react-feather'
import feather from 'feather-icons'
import { getScreen, getCountries, getPostcodes, getCities, postScreen, putScreen } from '../../services/extension'
import { isBrowser } from '../../services/auth'
import { MapContainer, TileLayer, Marker } from 'react-leaflet'
import { DivIcon } from 'leaflet'

const EditScreen = ({ screenId, back, props }) => {
  const isNew = screenId === 'new'

  const [country, setCountry] = useState('none')
  const [countries, setCountries] = useState([])

  const [postcode, setPostcode] = useState('none')
  const [postcodes, setPostcodes] = useState([])

  const [city, setCity] = useState('none')
  const [cities, setCities] = useState([])

  const [name, setName] = useState('')
  const [presentation, setPresentation] = useState('')
  const [note, setNote] = useState('')

  const markerRef = useRef(null)
  const [map, setMap] = useState(null)
  const [position, setPosition] = useState({
    lat: 64.959997,
    lng: 27.590001
  })

  useEffect(() => {
    getCountries((res = false) => {
      if (res) setCountries(res)
      if (!res) window.alert('Maiden lataamisessa on ongelma')
    })
    if (!isNew) {
      getScreen(screenId, (res = false) => {
        if (res) {
          res.countries_id && setCountry(res.countries_id)
          res.postcodes_id && setPostcode(res.postcodes_id)
          res.cities_id && setCity(res.cities_id)
          res.name && setName(res.name.fi)
          res.presentation && setPresentation(res.presentation.fi)
          res.notes && setNote(res.notes)
          if (res.lat && res.lng) {
            const pos = {
              lat: res.lat,
              lng: res.lng
            }
            const marker = markerRef.current
            if (marker) marker.setLatLng(pos)
            setPosition(pos)
          }
        }
        if (!res) window.alert('Opasteen lataamisessa on ongelma')
      })
    }
  }, [screenId, isNew])

  useEffect(() => {
    if (country !== 'none') {
      getPostcodes(country, (res = false) => {
        if (res) setPostcodes(res)
        if (!res) window.alert('Postikoodien lataamisessa on ongelma')
      })
      getCities(country, (res = false) => {
        if (res) setCities(res)
        if (!res) window.alert('Kaupunkien lataamisessa on ongelma')
      })
    }
  }, [country])

  useEffect(() => {
    if (map) {
      if (map.getCenter() !== position) {
        map.panTo(position, 13)
      }
    }
  }, [map, position])

  const handleSubmit = event => {
    event.preventDefault()
    const data = new window.FormData(event.target)

    data.set('name', JSON.stringify({ fi: name }))
    data.set('presentation', JSON.stringify({ fi: presentation }))
    data.set('location', `${position.lat} ${position.lng}`)

    if (isNew) {
      postScreen(data, (res = false) => {
        if (res) back(true, { id: res.id, edit: false })
        if (!res) window.alert('Näyttöä ei voitu lisätä')
      })
    } else {
      putScreen(screenId, data, (res = false) => {
        if (res) back(true, { id: res.id, edit: false })
        if (!res) window.alert('Näyttöä ei voitu tallentaa')
      })
    }
  }

  const martkerEventHandlers = useMemo(
    () => ({
      dragend () {
        const marker = markerRef.current
        if (marker) {
          setPosition(marker.getLatLng())
        }
      }
    }),
    []
  )

  const displayMap = useMemo(
    () => {
      if (isBrowser()) {
        return (
          <MapContainer
            whenCreated={setMap}
            center={position}
            zoom={13}
            scrollWheelZoom
            zoomControl
            className='h-96 w-full mt-3'
            attributionControl={false}
          >
            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
            />
            <Marker
              position={position}
              eventHandlers={martkerEventHandlers}
              ref={markerRef}
              draggable
              icon={new DivIcon({
                html: `<div class='marker -input'>${feather.icons.crosshair.toSvg({ width: 32, height: 32 })}<div class='marker-bg' style='background-image: url(data:image/svg+xml;base64,${window.btoa(feather.icons.crosshair.toSvg())})'></div></div>`,
                className: '',
                iconSize: [32, 32],
                iconAnchor: [16, 16]
              })}
            />
          </MapContainer>
        )
      }
      return null
    },
    [martkerEventHandlers, position]
  )

  const saveDisabled = country === 'none' || postcode === 'none' || city === 'none'

  return (
    <>
      <div className='flex items-center gap-4 -m-4 mb-4 p-4 bg-gray-700 text-white rounded-tr-md'>
        <button className='btn' onClick={() => back()}><Icon.ArrowLeft /><span className='sr-only'>Takaisin</span></button>
        <h4 className='font-bold uppercase text-sm'>Opasteet</h4>
      </div>
      <form
        onSubmit={event => {
          handleSubmit(event)
        }}
      >
        <div className='flex items-center justify-between gap-0 mb-6'>
          <div>
            <h3 className='text-sm font-semibold text-gray-500 uppercase my-0'>Opaste</h3>
            <h2 className='my-0'>{isNew ? 'Lisää uusi' : `Muokkaa (${screenId}) ${name}`}</h2>
          </div>
          <button className='btn -green' disabled={saveDisabled}>{isNew ? 'Lisää' : 'Tallenna'}</button>
        </div>
        <div className='field'>
          <label htmlFor='countries_id'>Valtio</label>
          <select name='countries_id' value={country} onChange={e => setCountry(e.target.value)}>
            <option value='none' disabled>Valitse valtio</option>
            {countries.map((country, index) =>
              <option key={country.id} value={country.id}>{country.label}</option>
            )}
          </select>
        </div>
        <div className='field'>
          <label htmlFor='postcodes_id'>Postikoodi</label>
          <select name='postcodes_id' disabled={country === 'none'} value={postcode} onChange={e => setPostcode(e.target.value)}>
            <option value='none' disabled>Valitse postikoodi</option>
            {postcodes.map((postcode, index) =>
              <option key={postcode.id} value={postcode.id}>{postcode.postcode} ({postcode.label})</option>
            )}
          </select>
        </div>
        <div className='field'>
          <label htmlFor='cities_id'>Kaupunki</label>
          <select name='cities_id' disabled={country === 'none'} value={city} onChange={e => setCity(e.target.value)}>
            <option value='none' disabled>Valitse kylä</option>
            {cities.map((city, index) =>
              <option key={city.id} value={city.id}>{city.label}</option>
            )}
          </select>
        </div>
        <div className='field'>
          <label htmlFor='name'>Nimi (julkinen)</label>
          <input
            type='text'
            className='inline-block w-full'
            name='name'
            value={name}
            onChange={e => setName(e.target.value)}
          />
        </div>
        <div className='field'>
          <label htmlFor='presentation'>Kuvaus (julkinen)</label>
          <textarea
            rows='5'
            className='inline-block w-full'
            name='presentation'
            value={presentation}
            onChange={e => setPresentation(e.target.value)}
          />
        </div>
        <div className='field'>
          <label htmlFor='note'>Muistio (sisäinen)</label>
          <textarea
            rows='5'
            className='inline-block w-full'
            value={note}
            name='note'
            onChange={e => setNote(e.target.value)}
          />
        </div>
        {displayMap}
      </form>
    </>
  )
}

export default EditScreen
