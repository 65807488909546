import React, { useEffect, useState, useRef } from "react";
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack";
import { getAccess } from "../../services/access";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

// Time conversion
const unixToInput = (unix) => {
  const date = new Date(unix * 1000);
  return date.toISOString().substring(0, 10);
};

const EditPdfNotice = ({ ...props }) => {
  const [title, setTitle] = useState(props.title || "");
  const [starting, setStarting] = useState(props.starting_date ? unixToInput(props.starting_date) : "");
  const [ending, setEnding] = useState(props.ending_date ? unixToInput(props.ending_date) : "");
  const [file, setFile] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);

  // For responsive scaling
  const [pageWidth, setPageWidth] = useState(100);
  const containerRef = useRef(null);

  // Get PDF file from data
  useEffect(() => {
    if ("data" in props && props.data !== null) {
      const data = JSON.parse(props.data);
      if ("pdf_uri" in data) {
        setFile(data.pdf_uri);
      }
    }
  }, [props.data]);

  // Extract text content from PDF and move it to text editor
  const HandleEditorSwitch = async () => {
    if (file == null) {
      return;
    }

    if (!window.confirm("Sisällön vieminen tekstieditoriin voi kadottaa osan muotoilusta. Haluatko silti siirtää sisällön?")) {
      return;
    }

    // Read the file using file reader
    var fileReader = new FileReader();

    fileReader.onload = function () {
      var typedarray = new Uint8Array(this.result);

      // Extract content from document
      pdfjs.getDocument(typedarray).promise.then(async (pdf) => {
        let textContent = "";
        let pageText = "";

        for (let pageNum = 0; pageNum < pdf.numPages; pageNum++) {
          pageText = "";

          await pdf.getPage(pageNum + 1).then(async (page) => {
            await page.getTextContent().then(async (text) => {
              text.items.map((item) => {
                pageText += item.str;
                if (item.hasEOL) {
                  pageText += "\n";
                }
              });
            });
          });

          textContent += pageText;
        }

        props.toTxtEditor(title, textContent);
      });
    };

    fileReader.readAsArrayBuffer(file);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const data = new window.FormData(event.target);
    title && data.append("title", title);
    starting && data.append("starting_date", new Date(starting).getTime() / 1000);
    ending && data.append("ending_date", new Date(ending).getTime() / 1000);

    const requestOptions = {
      method: props.isNew ? "POST" : "PUT",
      headers: { Authorization: `Bearer ${getAccess()}` },
      body: data,
    };

    window
      .fetch(
        `${process.env.REACT_APP_EXTENSION_API_ENDPOINT}/v1/houses/${props.houses_id}/noticespdf` + (!props.isNew ? `/${props.id}` : ""),
        requestOptions
      )
      .then((res) => res.json())
      .then(
        (result) => {
          console.log(result);
          window.alert("Tallennettu");
          console.log(props);
          props.close && props.close();
          props.reload && props.reload(props.houses_id);
        },
        (error) => {
          window.alert("Virhe, löytyy lokista");
          console.log(error);
        }
      );
  };

  function onFileChange(event) {
    setFile(event.target.files[0]);
  }

  function onDocumentLoadSuccess({ numPages: nextNumPages }) {
    setNumPages(nextNumPages);
    setCurrentPage(0);
  }

  const OnPageLoadSuccess = () => {
    const { offsetWidth } = containerRef.current;
    setPageWidth(offsetWidth > 600 ? 600 : offsetWidth);
    console.log(pageWidth, offsetWidth);
  };

  const options = {
    cMapUrl: "cmaps/",
    cMapPacked: true,
    standardFontDataUrl: "standard_fonts/",
  };

  const handleDelete = () => {
    const requestOptions = {
      method: "DELETE",
      headers: { Authorization: `Bearer ${getAccess()}` },
    };

    window
      .fetch(`${process.env.REACT_APP_EXTENSION_API_ENDPOINT}/v1/houses/${props.houses_id}/notices/${props.id}`, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          console.log(result);
          window.alert("Poistettu");
          props.close && props.close();
          props.reload && props.reload(props.houses_id);
        },
        (error) => {
          window.alert("Virhe poistossa, löytyy lokista");
          console.log(error);
        }
      );
  };

  return (
    <div
      key={props._key && props._key}
      className="bg-white p-3 w-full rounded shadow-sm my-3 whitespace-pre-line text-left outline-none ring border-blue-300 dark:bg-dark"
    >
      {/* File preview */}
      {file && (
        <div ref={containerRef}>
          <span>
            Sivu: {currentPage + 1} | {numPages}
          </span>
          <div className="flex justify-center gap-4 my-2">
            <button className="btn -secondary" onClick={(e) => setCurrentPage(currentPage - 1)} disabled={currentPage < 1}>
              Edellinen sivu
            </button>
            <button className="btn -secondary" onClick={(e) => setCurrentPage(currentPage + 1)} disabled={currentPage + 1 >= numPages}>
              Seuraava sivu
            </button>
          </div>
          <Document file={file} onLoadSuccess={onDocumentLoadSuccess} options={options}>
            <Page
              key={`page_${currentPage + 1}`}
              pageNumber={currentPage + 1}
              width={pageWidth}
              className="border w-min my-4 m-auto"
              onLoadSuccess={OnPageLoadSuccess}
            />
          </Document>
          <button type="button" className="link block m-auto" onClick={HandleEditorSwitch}>
            Vedä sisältö tekstieditoriin
          </button>
        </div>
      )}
      <form
        onSubmit={(event) => {
          handleSubmit(event);
        }}
      >
        <label className="flex flex-col gap-1 mb-4">
          <span>Otsikko</span>
          <input className="dark:bg-dark-light dark:border-dark-light" type="text" value={title} onChange={(e) => setTitle(e.target.value)}/>
          <span className="text-xs dark:text-white">* Tämän ilmoituksen otsikko ei näy näytöillä.</span>
        </label>
        {props.isNew && (
          <input type="file" className="mb-4" name="pfd_file" size="10000000" accept="application/pdf" required onChange={onFileChange} />
        )}

        <div className="flex justify-between gap-2 mb-3">
          <div className="field w-full md:w-72 m-0">
            <label className="dark:text-white" htmlFor="starting">Julkaistaan</label>
            <input
              type="date"
              value={starting}
              autoComplete="off"
              onChange={(e) => setStarting(e.target.value)}
              name="starting"
              className="w-full dark:bg-dark-light dark:border-dark-light"
            />
          </div>
          <span />
          <div className="field w-full md:w-72 m-0">
            <label className="dark:text-white" htmlFor="ending">Piilotetaan</label>
            <input
              type="date"
              name="ending"
              autoComplete="off"
              value={ending}
              onChange={(e) => setEnding(e.target.value)}
              className="w-full dark:bg-dark-light dark:border-dark-light"
            />
          </div>
        </div>

        {/* Buttons */}
        <div className="flex flex-col md:flex-row justify-between gap-2">
          <div className="flex flex-col md:flex-row gap-2">
            <input type="submit" className="w-full md:w-auto btn -primary" value={props.isNew ? "Julkaise" : "Tallenna"} />
          </div>
          <div className="flex flex-col md:flex-row gap-2">
            <button className="btn -secondary w-full md:w-auto" onClick={() => props.close()}>
              Sulje
            </button>
            {!props.isNew && (
              <button className="btn -red w-full md:w-auto" onClick={() => handleDelete()}>
                Poista ilmoitus
              </button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditPdfNotice;
