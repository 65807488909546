import React, { useState } from "react";

import EditNotice from "./edit-notice";
import reactHtmlReplace from "react-html-replace";
import EditPdfNotice from "./EditPdfNotice";

const unixToInput = (unix) => {
  const date = new Date(unix * 1000);
  return date.toLocaleDateString();
};

const Notice = ({ ...props }) => {
  const [editing, setEditing] = useState(false);
  const [pdfEdit, setPdfEdit] = useState(false);
  const [starting, setStarting] = useState(props.starting_date ? unixToInput(props.starting_date) : "");
  const [ending, setEnding] = useState(props.ending_date ? unixToInput(props.ending_date) : "");
  const [title, setTitle] = useState(props.title || "");
  const [content, setContent] = useState(props.notice || "");

  console.log(title, content);

  const SwitchToTextEditor = (pdfTitle, pdfContent) => {
    console.log("Switched to txt editor with \n" + "title: " + pdfTitle + "\n" + "Content: " + pdfContent);
    setContent(pdfContent);
    setTitle(pdfTitle);
    setEditing(true);
    setPdfEdit(false);
  };

  // Open editor, if editing
  if (editing) {
    return (
      <EditNotice
        key={props._key && props._key}
        isNew={props.isNew && props.isNew}
        close={() => setEditing(false)}
        title={title}
        notice={content}
        {...props}
      />
    );
  }

  if (pdfEdit) {
    return (
      <EditPdfNotice
        key={props._key && props._key}
        isNew={props.isNew && props.isNew}
        close={() => setPdfEdit(false)}
        toTxtEditor={SwitchToTextEditor}
        {...props}
      />
    );
  }

  // New notice buttons
  if (props.isNew) {
    return (
      <div className="w-full flex flex-col md:flex-row gap-4">
        <button
          onClick={() => setEditing(true)}
          className="btn -secondary w-full p-4 text-center rounded whitespace-pre-line"
        >
          Luo uusi ilmoitus
        </button>
        <button
          onClick={() => setPdfEdit(true)}
          className="btn -secondary w-full p-4 text-center rounded whitespace-pre-line"
        >
          Lisää ilmoitus PDF-tiedostona
        </button>
      </div>
    );
  }

  // Notice
  return (
    <button
      key={props._key && props._key}
      onClick={() => (props.type == "pdf" ? setPdfEdit(true) : setEditing(true))}
      className="bg-white w-full text-lg p-4 rounded border shadow-sm my-3 whitespace-pre-line text-left dark:bg-dark-lighter dark:border-dark-light"
    >
      {/* Date and state */}
      <div className="flex gap-2 flex-wrap justify-between items-center mb-1 text-sm">
        <span>
          {starting.replaceAll("-", ".")} - {ending.replaceAll("-", ".")}
        </span>
        {(props.starting_date < new Date().getTime() / 1000 && props.ending_date > new Date().getTime() / 1000) ||
        (props.starting_date < new Date().getTime() / 1000 && props.ending_date == undefined) ||
        (props.starting_date == undefined && props.ending_date > new Date().getTime() / 1000) ? (
          <span className="bg-accent text-white px-2 rounded-md">Aktiivinen</span>
        ) : (
          <span className="text-white bg-grey-darker px-2 rounded-md">Arkistoitu</span>
        )}
      </div>
      {/* Title */}
      {props.title && (
        <div className="text-2xl font-semibold mb-2">
          {props.title &&
            (props._formatted
              ? reactHtmlReplace(props._formatted.title, (tag, props) => {
                  if (tag === "em") {
                    return <em />;
                  }
                })
              : props.title)}
        </div>
      )}
      {/* Content */}
      {props.notice &&
        props.type != "pdf" &&
        (props._formatted
          ? reactHtmlReplace(props._formatted.notice, (tag, props) => {
              if (tag === "em") {
                return <em />;
              }
            })
          : "")}
      {props.type == "pdf" && <p>pdf-tiedosto</p>}
    </button>
  );
};

export default Notice;
