import React, { useState, useCallback, useMemo } from "react";
import { Trash2, CornerLeftUp } from "react-feather";

const InfobarArchive = ({
  items,
  handleNameChange,
  handleSave,
  handleChange,
  handleAddItem,
  handleRemoveItem,
  ...props
  }) => {
  const current = useMemo(() => items.find((item) => item.current), [items]);

  return (
    <>
      <ul className="flex flex-col gap-2 p-0 mt-4">
        {items.map((item) => {
          return (
            <li
              key={item.id}
              className="list-none flex justify-between items-center bg-white p-4 shadow-sm rounded-md border dark:bg-dark-lighter dark:border-dark-lighter "
            >
              <span>{item.title} </span>
              <div className="flex gap-4">
                <button
                  className="flex items-center"
                  onClick={() => {
                    item.status = 1; 
                    console.log(item);
                    handleSave(item);
                  }}
                >
                  <CornerLeftUp className="h-5 pr-1" />
                  <span className="hidden sm:block">Aktivoi</span>
                </button>
                <button
                  className="flex text-danger items-center "
                  onClick={() => {
                    item.status = 3;
                    console.log(item);
                    handleSave(item);
                  }}
                >
                  <Trash2 className="h-5 pr-1" />
                  <span className="hidden sm:block">Poista</span>
                </button>
              </div>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default InfobarArchive;
