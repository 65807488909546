import React, { useState, useEffect, useCallback } from "react";
import { Helmet } from "react-helmet";
import { navigate } from "gatsby";
import { getAccess, setAccess, hasAccess, removeAccess } from "../services/access";
import * as Icon from "react-feather";
import NoticeTut from "../images/createNoticeTutorial.mp4";

// House specific
import HouseNav from "./houses/house-nav";
import Notice from "./houses/notice";
import Notices from "./houses/notices";
import Search from "./houses/search";
import BarEdit from "./houses/CondoViewElements/InfobarEdit";

// Resource elements
import Rights from "./resources/rights";
import Residents from "./houses/residents";
import { getAccount } from "../services/auth";

const Resources = ({ props }) => {
  const DEBUG_MODE = true; // SET FALSE FOR PROD
  const [resources, setResources] = useState(
    DEBUG_MODE
      ? [
          {
            id: "1",
            title: "Debug ilmotus 1",
            notice: { ops: [{ attributes: { bold: true }, insert: "Testi " }, { insert: "(Json object)\n " }] },
            starting_date: "1704153600",
            ending_date: "1704499200",
          },
          {
            id: "2",
            title: "Debug ilmoitus 2",
            notice: "Debug testi ilmoitus (string, ei muotoiluja)",
            starting_date: "1704153600",
            ending_date: "1716655235",
          },
          {
            id: "3",
            title: "Debug ilmotus 3",
            notice:
              '{"ops":[{ "attributes": { "bold": true }, "insert": "Testi kolmonen (string delta muoto) " }, { "insert": "bbb\\n " }] }',
            starting_date: "1704153600",
            ending_date: "1704499200",
          },
        ]
      : []
  );
  const [current, setCurrent] = useState(false);
  const [instances, setInstances] = useState(DEBUG_MODE ? [{ name: "Taloyhtiö", id: "1" }] : null); // For debugging add {name: "Taloyhtiö", id: "1"}
  const [search, setSearch] = useState("");
  const [view, setView] = useState("notices");
  const [housesView, setHousesView] = useState("residents");
  const [noticesVisible, setNoticesVisible] = useState("all");

  // Check does url has some sweet access tokens
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has("token")) {
    setAccess(urlParams.get("token"));
    window.history.replaceState({}, document.title, "/app/resources");
  } else if (!hasAccess() && !DEBUG_MODE) {
    navigate("/app/access");
  }

  // Get all houses, and in the future other resources
  useEffect(() => {
    const requestOptions = {
      headers: { Authorization: `Bearer ${getAccess()}` },
    };
    window
      .fetch(`${process.env.REACT_APP_EXTENSION_API_ENDPOINT}/v1/houses`, requestOptions)
      .then((res) => {
        // Log user out if JWT expired
        if (res.status === 401) {
          removeAccess();
          navigate("/app/access");
        }
        return res.json();
      })
      .then(
        (result) => {
          console.log(result);
          setInstances(result);
        },
        (error) => {
          console.log(error);
        }
      );
  }, []);

  const loadNotices = useCallback(() => {
    if (!current) {
      return;
    }
    const requestOptions = {
      headers: { Authorization: `Bearer ${getAccess()}` },
    };
    window
      .fetch(`${process.env.REACT_APP_EXTENSION_API_ENDPOINT}/v1/houses/${current}/notices`, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          console.log(result);
          setResources(result);
        },
        (error) => {
          console.log(error);
        }
      );
  }, [current]);

  // If current changes, get that from server
  useEffect(() => {
    if (!current) {
      return;
    }
    loadNotices(current);
  }, [current, loadNotices]);

  const FilterNotices = (notices) => {
    switch (noticesVisible) {
      case "active":
        return notices.filter(
          (n) =>
            (n.starting_date < new Date().getTime() / 1000 && n.ending_date > new Date().getTime() / 1000) ||
            (n.starting_date < new Date().getTime() / 1000 && n.ending_date == undefined) ||
            (n.starting_date == undefined && n.ending_date > new Date().getTime() / 1000)
        );
      case "archived":
        // Return all not active
        return notices.filter(
          (n) =>
            !(
              (n.starting_date < new Date().getTime() / 1000 && n.ending_date > new Date().getTime() / 1000) ||
              (n.starting_date < new Date().getTime() / 1000 && n.ending_date == undefined) ||
              (n.starting_date == undefined && n.ending_date > new Date().getTime() / 1000)
            )
        );
      default:
        return notices;
    }
  };

  return (
    <>
      {/* Title for condo pages */}
      <Helmet>
        <title>{(current ? instances.find((instance) => (instance.id == current)).name : "Taloyhtiöiden") + " Resurssit | Opasti"}</title>
      </Helmet>

      <div className="min-h-screen dark:bg-dark">
        <div className="flex flex-col lg:flex-row px-0 lg:min-h-screen border-l border-r dark:bg-dark-lightish dark:border-darker">
          <div className="sidebar w-full shadow mx-0 md:-mx-6 lg:max-w-sidenav lg:mx-0 lg:border-r z-20 dark:border-dark-light">
            <div className="flex flex-col top-0 z-10 lg:h-full bg-opasti-white p-2 m-0 gap-2 dark:bg-darker">
              <p className="text-hello px-4 mt-4 dark:text-white">Hei, Mukava nähdä! 👋 </p>
              <HouseNav setView={setView} view={view} />
            </div>
          </div>

          <div className="w-full z-10 flex flex-col dark:text-white">
            {/* Title section */}
            <div className="flex flex-col gap-2 p-4 shadow-sm z-10 relative border-b-2 dark:border-dark-light">
              {!current ? (
                <h4 className='dark:text-white'>Valitse taloyhtiö</h4>
              ) : (
                <>
                  <div>
                    <button
                      className="link mb-4 flex gap-1 dark:darkaccent"
                      onClick={() => {
                        setCurrent(null);
                      }}
                    >
                      <Icon.ChevronLeft />
                      <span>Takaisin taloyhtiöihin</span>
                    </button>
                    <h1 className="text-3xl dark:text-white">
                      {
                        {
                          notices: <>Ilmoitukset</>,
                          views: <>Näkymät</>,
                          houses: <>Taloyhtiöt</>,
                          news: <>Uutiset</>,
                          instructions: <>Ohjeet</>,
                          // settings: <>Asetukset</>
                        }[view]
                      }
                    </h1>
                  </div>
                  <div className="flex flex-wrap">
                    {
                      {
                        /* Notice search and filters */
                        notices: (
                          <div className="flex flex-col md:flex-row items-stretch md:items-center gap-2 w-full ">
                            <input
                              id="search"
                              type="text"
                              autoComplete="off"
                              value={search}
                              onChange={(e) => setSearch(e.target.value)}
                              className="border-2 w-full md:w-72 lg:w-80 rounded text-md p-1 dark:bg-dark dark:border-dark-light"
                              placeholder="Hae ilmoituksista"
                            />
                            <label>
                              <span className="pr-2 dark:text-white" value={noticesVisible}>
                                Näytä:
                              </span>
                              <select
                                onChange={(e) => {
                                  setNoticesVisible(e.target.value);
                                }}
                                id="visible"
                                className="border-2 solid p-1 rounded dark:bg-dark dark:text-white dark:border-dark-light opacity-50"
                              >
                                <option value="all">kaikki</option>
                                <option value="active">aktiiviset</option>
                                <option value="archived">arkistoidut</option>
                              </select>
                            </label>
                          </div>
                        ),
                        houses: (
                          <div className="flex flex-row w-full">
                            <ul
                              id="housesnav"
                              className="flex flex-row bg-opasti-white p-0 overflow-x-scroll no-scrollbar dark:bg-dark-lightish"
                            >
                              {/*<li className='list-none'><button onClick={() => setHousesView('info')} disabled={housesView === 'info'} className='-underline overflow-visible'>Tiedot</button></li>*/}
                              <li className="list-none">
                                <button
                                  onClick={() => setHousesView("residents")}
                                  disabled={housesView === "residents"}
                                  className="-underline overflow-visible dark:text-white"
                                >
                                  Asukaslista
                                </button>
                              </li>
                              <li className="list-none">
                                <button
                                  onClick={() => setHousesView("rights")}
                                  disabled={housesView === "rights"}
                                  className="-underline overflow-visible dark:text-white"
                                >
                                  Käyttöoikeudet
                                </button>
                              </li>
                            </ul>
                          </div>
                        ),
                      }[view]
                    }
                  </div>
                </>
              )}
            </div>

            {/* Content */}
            <div className="p-4 bg-grey-light z-0 flex-grow dark:bg-dark">
              {
                !current ? (
                instances.length != 0 ? (
                  <ul className="p-0">
                    {instances.map((resource) => (
                      <li key={resource.id + resource.name} className="list-none w-full">
                        <button
                          className="bg-white py-4 px-5 rounded shadow-sm border my-2 w-full text-left dark:border-dark-light dark:bg-dark-lighter"
                          onClick={(e) => {
                            e.preventDefault();
                            setCurrent(resource.id);
                            search && setSearch("");
                          }}
                        >
                          {resource.id === current && !search && "[Valittu]"} {resource.name}
                        </button>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p className='dark:text-white'>Emme löytäneet yhtään taloyhtiötä. </p>
                )
              ) : (
                {
                  notices: (
                    <>
                      {search ? (
                        <Search question={search} filters={{ noticesVisible }} />
                      ) : "current" ? (
                        <>
                          <Notice isNew houses_id={current} reload={(house) => loadNotices(house)} />
                          <Notices reload={(house) => loadNotices(house)} notices={FilterNotices(resources)} />
                        </>
                      ) : (
                        "Valitse taloyhtiö listasta."
                      )}
                      </>
                    ),
                    views: (
                      <BarEdit current={current} />
                    ),
                    houses: (
                      <>
                      {{
                        // info: <HouseInfo />,
                        residents: <Residents current={current} />,
                        rights: <Rights current={current} />
                      }[housesView]}
                      </>
                    ),
                    news: (
                      <>
                        <p className="text-hello dark:text-white">Tänne saat tiedon uusimmista päivityksistä ja tulevista tapahtumista.</p>
                      </>
                    ),
                    instructions: (
                    <>
                    {
                      <div>
                          <div classname = "">
                            <h1 className='text-hello dark:text-white'>Luo uusi teksti ilmoitus</h1>
                            <iframe className="video top-0 h-240 md:h-360 w-426 md:w-640 lg:h-480 lg:w-854 w-screen h-screen" 
                              src="https://www.youtube.com/embed/U88p2-crfOk?si=va7B5Qbp53XYTV5D" 
                              title="YouTube video player" 
                              frameborder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                              allowfullscreen>
                            </iframe>
                          </div>
                          
                      
                          <p className='text-hello'>Huomioithan, että tämä sivu on parhaillaan kehityksen alla, ja siksi se voi olla keskeneräinen.</p>
                          <p className='text-hello'>Jos sinulla on kiireellisiä kysymyksiä tai tarvitset apua, ota yhteyttä asiakaspalveluun.
                          
                          Kiitos kärsivällisyydestäsi!
                          </p>
                            

                      </div>
                    }
                    </>
                  ),
                  views: <BarEdit current={current} />,
                  houses: (
                    <>
                      {
                        {
                          // info: <HouseInfo />,
                          residents: <Residents current={current} />,
                          rights: <Rights current={current} />,
                        }[housesView]
                      }
                    </>
                  ),
                  news: (
                    <>
                      <p className="text-hello">
                        Tänne saat tiedon uusimmista päivityksistä ja tulevista tapahtumista.
                      </p>
                    </>
                  ),
                  instructions: (
                    <>
                      {
                        <div>
                          <div classname="">
                            <h1>Luo uusi teksti ilmoitus</h1>
                            <iframe
                              className="video top-0 h-240 md:h-360 w-426 md:w-640 lg:h-480 lg:w-854 w-screen h-screen"
                              src="https://www.youtube.com/embed/U88p2-crfOk?si=va7B5Qbp53XYTV5D"
                              title="YouTube video player"
                              frameborder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                              allowfullscreen
                            ></iframe>
                          </div>

                          <p className="text-hello opacity-80">
                            Huomioithan, että tämä sivu on parhaillaan kehityksen alla, ja siksi se voi olla
                            keskeneräinen.
                          </p>
                          <p className="text-hello opacity-80">
                            Jos sinulla on kiireellisiä kysymyksiä tai tarvitset apua, ota yhteyttä asiakaspalveluun.
                            Kiitos kärsivällisyydestäsi!
                          </p>
                        </div>
                      }
                    </>
                  ),
                  views: <BarEdit current={current} />,
                  houses: (
                    <>
                      {
                        {
                          // info: <HouseInfo />,
                          residents: <Residents current={current} />,
                          rights: <Rights current={current} />,
                        }[housesView]
                      }
                    </>
                  ),
                  news: (
                    <>
                      {
                        <div className="mb-4">
                        <div>
                          <h className="h-9 mb-1 text-big dark:text-white">Kuulumisia</h>
                          <p className="h-9 mb-2 dark:text-white">27.2.2024</p>
                          <p className='news-text'>
                            <b>Opastin saanut uusia ominaisuuksia</b>
                            <p>
                            Sovelluksen osalta ollaan vastaanotettu mukavasti uusia ideoita ja toiveita suoraan käyttäjiltä.
                            Tämä on ollut suuri apu sovelluksen kehittäjille, ja näitä toiveita on saatu hyvin toteutettua.
                            PDF -tiedostojen käytettävyyttä on paranneltu, ja tekstien syöttämiseen on luotu toimivampi työkalu, jonka avulla nyt tekstiä voi elävöittää entisestään.
                            Lisäksi mm. porrasnäytön näkymää on muokattu enemmän palvelemaan tarpeita. Lisää toiveita ja kehitysideoita saa edelleen antaa, ja näitä pyritään parhaan mukaan sovellukseen mukaan jatkossakin tuomaan.
                            <p className="pb-4">
                              Lähetelkää ideoita ja palautetta: <a href="mailto:asiakaspalvelu@skyled.fi">asiakaspalvelu@skyled.fi</a>
                            </p>
                            </p>
                          </p>
                        </div>
                      
                        <div>
                          <h className="h-9 mb-1 text-big dark:text-white">Kuulumisia</h>
                          <p className="h-9 mb-2 dark:text-white">27.2.2024</p>
                          <b>Yleisesti ohjelman käyttämisestä</b>
                          <p className="news-text">
                          Ohjevideo näyttää lyhyesti miten tiedotteita Opastin kanssa luodaan. Huomaa, että jokaiselle tiedotteelle on hyvä asentaa alkamis- sekä päättymispäivämäärä.
                          Tällä tavalla saat varmistettua sen, että tiedote näkyy joko aktiivisena tai arkistoituna suoraan tiedotteet sivulla.
                          Päättymispäivämäärä voi tarpeen mukaan jatkossa muuttaa vaikka pidemmälle.
                      
                          Porrasnäyttöjä on nyt enemmän - lisää yhteistyötä. Toiminta on laajentunut ja verkosto kasvanut. Käyttäjä- sekä näyttömäärät ovat kokoajan nousussa, ja tämä kertoo vain siitä, että palvelupakettimme on pystynyt lunastamaan ne odotukset ja tarpeet mitä sille on asetettukin. Kasvu ei kuitenkaan ole vielä tässä, vaan SkyLED Finland etsii edelleen uusia kohteita ja paikkoja näytöille.
                          <p>
                          </p>
                            Yhteistyöehdotuksia niin mainosten kuin uusien näyttöpaikkojen osalta voi vinkata: <a href="mailto:myynti@skyled.fi">myynti@skyled.fi</a>
                          </p>
                        </div>
                      </div>
                      }
                    </>
                  ),
                }[view]
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Resources;
