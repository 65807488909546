import jwtDecode from 'jwt-decode'
import { appwrite, logs } from './../utils'
import { isBrowser, getSession, isLoggedIn } from './auth'

export const getJWT = () => {
  if (isBrowser()) {
    const token = window.localStorage.getItem('appwrite_jwt')
    if (token && jwtDecode(token).exp > ~~(Date.now() / 1000)) return token
  }
  return false
}

export const hasJWT = () => {
  const access = getJWT()
  return !!access
}

const setJWT = token =>
  window.localStorage.setItem('appwrite_jwt', token)

const createJWT = async callback => {
  const promise = await appwrite.account.createJWT().then(function (response) {
    if (logs) console.log('Create JWT success: ', response)
    return response
  }, function (error) {
    if (logs) console.log('Create JWT error: ', error)
    return false
  })
  return promise
}

export const removeJWT = () => window.localStorage.removeItem('appwrite_jwt')

const getValidJWT = async () => {
  if (!hasJWT()) {
    const token = await createJWT()
    if (token.jwt) {
      setJWT(token.jwt)
    } else {
      window.alert('Virhe JWT luonnissa')
    }
  }
  return getJWT()
}

export const getScreens = async (callback, list = 'all') => {
  if (!isLoggedIn()) return false

  const token = await getValidJWT()
  if (!token) return false
  const requestOptions = {
    headers: { Authorization: `Appwrite ${token}`, Session: getSession().$id }
  }
  window.fetch(`${process.env.REACT_APP_EXTENSION_API_ENDPOINT}/v1/screens?list=${list}`, requestOptions)
    .then(res => {
      // Log user out if JWT expired
      if (res.status === 401) {
        window.alert('Ei oikeutta')
      }
      return res.json()
    })
    .then(
      (res) => {
        if (logs) console.log('Get screens response: ', res)
        callback(res)
      },
      (error) => {
        if (logs) console.log('Get screens error: ', error)
        callback()
      }
    )
}

export const getMenu = async callback => {
  if (!isLoggedIn()) return false

  const token = await getValidJWT()
  if (!token) return false
  const requestOptions = {
    headers: { Authorization: `Appwrite ${token}`, Session: getSession().$id }
  }
  window.fetch(`${process.env.REACT_APP_EXTENSION_API_ENDPOINT}/v1/ui/menu`, requestOptions)
    .then(res => {
      // Log user out if JWT expired
      if (res.status === 401) {
        window.alert('Ei oikeutta')
      }
      return res.json()
    })
    .then(
      (res) => {
        if (logs) console.log('Get menu response: ', res)
        callback(res)
      },
      (error) => {
        if (logs) console.log('Get menu error: ', error)
        callback()
      }
    )
}

export const getCountries = async callback => {
  window.fetch(`${process.env.REACT_APP_EXTENSION_API_ENDPOINT}/v1/countries`)
    .then(res => {
      return res.json()
    })
    .then(
      (res) => {
        if (logs) console.log('Get countries response: ', res)
        callback(res)
      },
      (error) => {
        if (logs) console.log('Get countries error: ', error)
        callback()
      }
    )
}

export const getPostcodes = async (country, callback) => {
  window.fetch(`${process.env.REACT_APP_EXTENSION_API_ENDPOINT}/v1/countries/${country}/postcodes`)
    .then(res => {
      return res.json()
    })
    .then(
      (res) => {
        if (logs) console.log('Get postcodes response: ', res)
        callback(res)
      },
      (error) => {
        if (logs) console.log('Get postcodes error: ', error)
        callback()
      }
    )
}

export const getCities = async (country, callback) => {
  window.fetch(`${process.env.REACT_APP_EXTENSION_API_ENDPOINT}/v1/countries/${country}/cities`)
    .then(res => {
      return res.json()
    })
    .then(
      (res) => {
        if (logs) console.log('Get cities response: ', res)
        callback(res)
      },
      (error) => {
        if (logs) console.log('Get cities error: ', error)
        callback()
      }
    )
}

export const postScreen = async (data, callback) => {
  if (!isLoggedIn()) return false

  const token = await getValidJWT()
  if (!token) return false
  const requestOptions = {
    method: 'POST',
    headers: { Authorization: `Appwrite ${token}`, Session: getSession().$id },
    body: data
  }
  window.fetch(`${process.env.REACT_APP_EXTENSION_API_ENDPOINT}/v1/screens`, requestOptions)
    .then(res => {
      // Log user out if JWT expired
      if (res.status === 401) {
        window.alert('Ei oikeutta')
      }
      return res.json()
    })
    .then(
      (res) => {
        if (logs) console.log('Post screen response: ', res)
        callback(res)
      },
      (error) => {
        if (logs) console.log('Post screen error: ', error)
        callback()
      }
    )
}

export const getScreen = async (id, callback) => {
  if (!isLoggedIn()) return false

  const token = await getValidJWT()
  if (!token) return false

  const requestOptions = {
    headers: { Authorization: `Appwrite ${token}`, Session: getSession().$id }
  }

  window.fetch(`${process.env.REACT_APP_EXTENSION_API_ENDPOINT}/v1/screens/${id}`, requestOptions)
    .then(res => {
      // Log user out if JWT expired
      if (res.status === 401) {
        window.alert('Ei oikeutta')
      }
      return res.json()
    })
    .then(
      (res) => {
        if (logs) console.log('Get screen response: ', res)
        callback(res)
      },
      (error) => {
        if (logs) console.log('Get screen error: ', error)
        callback()
      }
    )
}

export const putScreen = async (id, data, callback) => {
  if (!isLoggedIn()) return false

  const token = await getValidJWT()
  if (!token) return false
  const requestOptions = {
    method: 'PUT',
    headers: { Authorization: `Appwrite ${token}`, Session: getSession().$id },
    body: data
  }
  window.fetch(`${process.env.REACT_APP_EXTENSION_API_ENDPOINT}/v1/screens/${id}`, requestOptions)
    .then(res => {
      // Log user out if JWT expired
      if (res.status === 401) {
        window.alert('Ei oikeutta')
      }
      return res.json()
    })
    .then(
      (res) => {
        if (logs) console.log('Put screen response: ', res)
        callback(res)
      },
      (error) => {
        if (logs) console.log('Put screen error: ', error)
        callback()
      }
    )
}

export const patchScreen = async (id, data, callback) => {
  if (!isLoggedIn()) return false

  const token = await getValidJWT()
  if (!token) return false

  const requestOptions = {
    method: 'PATCH',
    headers: { Authorization: `Appwrite ${token}`, Session: getSession().$id },
    body: data
  }

  window.fetch(`${process.env.REACT_APP_EXTENSION_API_ENDPOINT}/v1/screens/${id}`, requestOptions)
    .then(res => {
      // Log user out if JWT expired
      if (res.status === 401) {
        window.alert('Ei oikeutta')
      }
      return res.json()
    })
    .then(
      (res) => {
        if (logs) console.log('Patch screen response: ', res)
        callback(res)
      },
      (error) => {
        if (logs) console.log('Patch screen error: ', error)
        callback()
      }
    )
}

export const getHouses = async (callback, list = 'all') => {
  if (!isLoggedIn()) return false

  const token = await getValidJWT()
  if (!token) return false
  const requestOptions = {
    headers: { Authorization: `Appwrite ${token}`, Session: getSession().$id }
  }
  window.fetch(`${process.env.REACT_APP_EXTENSION_API_ENDPOINT}/v1/klinen/houses?list=${list}`, requestOptions)
    .then(res => {
      // Log user out if JWT expired
      if (res.status === 401) {
        window.alert('Ei oikeutta')
      }
      return res.json()
    })
    .then(
      (res) => {
        if (logs) console.log('Get houses response: ', res)
        callback(res)
      },
      (error) => {
        if (logs) console.log('Get houses error: ', error)
        callback()
      }
    )
}

export const postHouses = async (data, callback) => {
  if (!isLoggedIn()) return false

  const token = await getValidJWT()
  if (!token) return false
  const requestOptions = {
    method: 'POST',
    headers: { Authorization: `Appwrite ${token}`, Session: getSession().$id },
    body: data
  }
  window.fetch(`${process.env.REACT_APP_EXTENSION_API_ENDPOINT}/v1/klinen/houses`, requestOptions)
    .then(res => {
      // Log user out if JWT expired
      if (res.status === 401) {
        window.alert('Ei oikeutta')
      }
      return res.json()
    })
    .then(
      (res) => {
        if (logs) console.log('Post houses response: ', res)
        callback(res)
      },
      (error) => {
        if (logs) console.log('Post houses error: ', error)
        callback()
      }
    )
}

export const getHouse = async (id, callback) => {
  if (!isLoggedIn()) return false

  const token = await getValidJWT()
  if (!token) return false

  const requestOptions = {
    headers: { Authorization: `Appwrite ${token}`, Session: getSession().$id }
  }

  window.fetch(`${process.env.REACT_APP_EXTENSION_API_ENDPOINT}/v1/klinen/houses/${id}`, requestOptions)
    .then(res => {
      // Log user out if JWT expired
      if (res.status === 401) {
        window.alert('Ei oikeutta')
      }
      return res.json()
    })
    .then(
      (res) => {
        if (logs) console.log('Get house response: ', res)
        callback(res)
      },
      (error) => {
        if (logs) console.log('Get house error: ', error)
        callback()
      }
    )
}

export const deleteUserLevel = async (id, target, callback) => {
  if (!isLoggedIn()) return false

  const token = await getValidJWT()
  if (!token) return false

  const requestOptions = {
    method: 'DELETE',
    headers: { Authorization: `Appwrite ${token}`, Session: getSession().$id }
  }

  window.fetch(`${process.env.REACT_APP_EXTENSION_API_ENDPOINT}/v1/klinen/houses/${id}/rights/${target}`, requestOptions)
    .then(res => {
      // Log user out if JWT expired
      if (res.status === 401) {
        window.alert('Ei oikeutta')
      }
      return res.json()
    })
    .then(
      (res) => {
        if (logs) console.log('Delete user response: ', res)
        callback(res)
      },
      (error) => {
        if (logs) console.log('Delete user error: ', error)
        callback()
      }
    )
}

export const putUserLevel = async (id, target, level, callback) => {
  if (!isLoggedIn()) return false

  const token = await getValidJWT()
  if (!token) return false

  const requestOptions = {
    method: 'PUT',
    headers: { Authorization: `Appwrite ${token}`, Session: getSession().$id }
  }

  window.fetch(`${process.env.REACT_APP_EXTENSION_API_ENDPOINT}/v1/klinen/houses/${id}/rights/${target}/${level}`, requestOptions)
    .then(res => {
      // Log user out if JWT expired
      if (res.status === 401) {
        window.alert('Ei oikeutta')
      }
      return res.json()
    })
    .then(
      (res) => {
        if (logs) console.log('Put user level response: ', res)
        callback(res)
      },
      (error) => {
        if (logs) console.log('Put user level error: ', error)
        callback()
      }
    )
}

export const postUser = async (id, email, callback) => {
  if (!isLoggedIn()) return false

  const token = await getValidJWT()
  if (!token) return false

  const requestOptions = {
    method: 'POST',
    headers: { Authorization: `Appwrite ${token}`, Session: getSession().$id }
  }

  window.fetch(`${process.env.REACT_APP_EXTENSION_API_ENDPOINT}/v1/klinen/houses/${id}/rights/${email}`, requestOptions)
    .then(res => {
      // Log user out if JWT expired
      if (res.status === 401) {
        window.alert('Ei oikeutta')
      }
      return res.json()
    })
    .then(
      (res) => {
        if (logs) console.log('Post new user response: ', res)
        callback(res)
      },
      (error) => {
        if (logs) console.log('Post new user error: ', error)
        callback()
      }
    )
}

export const postScreenHouse = async (id, data, callback) => {
  if (!isLoggedIn()) return false

  const token = await getValidJWT()
  if (!token) return false

  const requestOptions = {
    method: 'POST',
    headers: { Authorization: `Appwrite ${token}`, Session: getSession().$id },
    body: data
  }

  window.fetch(`${process.env.REACT_APP_EXTENSION_API_ENDPOINT}/v1/klinen/houses/${id}/screens`, requestOptions)
    .then(res => {
      // Log user out if JWT expired
      if (res.status === 401) {
        window.alert('Ei oikeutta')
      }
      return res.json()
    })
    .then(
      (res) => {
        if (logs) console.log('Post screen to house response: ', res)
        callback(res)
      },
      (error) => {
        if (logs) console.log('Post screen to house error: ', error)
        callback()
      }
    )
}

export const deleteScreenHouse = async (id, screen, callback) => {
  if (!isLoggedIn()) return false

  const token = await getValidJWT()
  if (!token) return false

  const requestOptions = {
    method: 'DELETE',
    headers: { Authorization: `Appwrite ${token}`, Session: getSession().$id }
  }

  window.fetch(`${process.env.REACT_APP_EXTENSION_API_ENDPOINT}/v1/klinen/houses/${id}/screens/${screen}`, requestOptions)
    .then(res => {
      // Log user out if JWT expired
      if (res.status === 401) {
        window.alert('Ei oikeutta')
      }
      return res.json()
    })
    .then(
      (res) => {
        if (logs) console.log('Delete screen from house response: ', res)
        callback(res)
      },
      (error) => {
        if (logs) console.log('Delete screen from house error: ', error)
        callback()
      }
    )
}

export const searchScreens = (params) => {
  const controller = new window.AbortController()
  const signal = controller.signal

  let cancel

  const callback = new Promise((resolve, reject) => {
    cancel = window.setTimeout(async () => {
      if (!isLoggedIn()) return false

      const token = await getValidJWT()
      if (!token) return false

      const requestOptions = {
        method: 'GET',
        headers: { Authorization: `Appwrite ${token}`, Session: getSession().$id }
      }

      resolve(window.fetch(`${process.env.REACT_APP_EXTENSION_API_ENDPOINT}/v1/campaigns/search?from=${params.from}&to=${params.to}&needs=${params.needs}&_northEast=${params.mapPos._northEast.lat},${params.mapPos._northEast.lng}&_southWest=${params.mapPos._southWest.lat},${params.mapPos._southWest.lng}`, { ...requestOptions, signal }))
    }, 700)
  })

  return {
    abort: () => { clearTimeout(cancel); controller.abort() },
    ready: callback
  }
}

export const postCampaign = async (data, callback) => {
  if (!isLoggedIn()) return false

  const token = await getValidJWT()
  if (!token) return false

  const requestOptions = {
    method: 'POST',
    headers: { Authorization: `Appwrite ${token}`, Session: getSession().$id },
    body: data
  }

  window.fetch(`${process.env.REACT_APP_EXTENSION_API_ENDPOINT}/v1/campaigns`, requestOptions)
    .then(res => {
      // Log user out if JWT expired
      if (res.status === 401) {
        window.alert('Ei oikeutta')
      }
      return res.json()
    })
    .then(
      (res) => {
        if (logs) console.log('Post campaign response: ', res)
        callback(res)
      },
      (error) => {
        if (logs) console.log('Post campaign error: ', error)
        callback()
      }
    )
}

export const postCampaignResources = async (data, campaignsId, callback) => {
  if (!isLoggedIn()) return false

  const token = await getValidJWT()
  if (!token) return false

  const requestOptions = {
    method: 'POST',
    headers: { Authorization: `Appwrite ${token}`, Session: getSession().$id },
    body: data
  }

  window.fetch(`${process.env.REACT_APP_EXTENSION_API_ENDPOINT}/v1/campaigns/${campaignsId}/resources`, requestOptions)
    .then(res => {
      // Log user out if JWT expired
      if (res.status === 401) {
        window.alert('Ei oikeutta')
      }
      return res.json()
    })
    .then(
      (res) => {
        if (logs) console.log('Post campaign resources response: ', res)
        callback(res)
      },
      (error) => {
        if (logs) console.log('Post campaign resources error: ', error)
        callback()
      }
    )
}

export const getCampaignResources = async (campaignsId, callback) => {
  if (!isLoggedIn()) return false

  const token = await getValidJWT()
  if (!token) return false

  const requestOptions = {
    method: 'GET',
    headers: { Authorization: `Appwrite ${token}`, Session: getSession().$id }
  }

  window.fetch(`${process.env.REACT_APP_EXTENSION_API_ENDPOINT}/v1/campaigns/${campaignsId}/resources`, requestOptions)
    .then(res => {
      // Log user out if JWT expired
      if (res.status === 401) {
        window.alert('Ei oikeutta')
      }
      return res.json()
    })
    .then(
      (res) => {
        if (logs) console.log('Get campaign resources response: ', res)
        callback(res)
      },
      (error) => {
        if (logs) console.log('Get campaign resources error: ', error)
        callback()
      }
    )
}

export const putCampaignResources = async (data, campaignsId, callback) => {
  if (!isLoggedIn()) return false

  const token = await getValidJWT()
  if (!token) return false

  const requestOptions = {
    method: 'PUT',
    headers: { Authorization: `Appwrite ${token}`, Session: getSession().$id },
    body: data
  }

  window.fetch(`${process.env.REACT_APP_EXTENSION_API_ENDPOINT}/v1/campaigns/${campaignsId}/resources`, requestOptions)
    .then(res => {
      // Log user out if JWT expired
      if (res.status === 401) {
        window.alert('Ei oikeutta')
      }
      return res.json()
    })
    .then(
      (res) => {
        if (logs) console.log('Get campaign resources response: ', res)
        callback(res)
      },
      (error) => {
        if (logs) console.log('Get campaign resources error: ', error)
        callback()
      }
    )
}

export const patchCampaign = async (data, campaignsId, callback) => {
  if (!isLoggedIn()) return false

  const token = await getValidJWT()
  if (!token) return false

  const requestOptions = {
    method: 'PATCH',
    headers: { Authorization: `Appwrite ${token}`, Session: getSession().$id },
    body: data
  }

  window.fetch(`${process.env.REACT_APP_EXTENSION_API_ENDPOINT}/v1/campaigns/${campaignsId}`, requestOptions)
    .then(res => {
      // Log user out if JWT expired
      if (res.status === 401) {
        window.alert('Ei oikeutta')
      }
      return res.json()
    })
    .then(
      (res) => {
        if (logs) console.log('Patch campaign response: ', res)
        callback(res)
      },
      (error) => {
        if (logs) console.log('Patch campaign error: ', error)
        callback()
      }
    )
}
