import React, { useState, useEffect } from 'react'
import { getMenu } from '../services/extension'
import * as Icon from 'react-feather'
import { getAccount } from '../services/auth'

import Me from './profile/me'
import Teams from './profile/teams'
import Screens from './profile/screens'
import Houses from './profile/houses'
import Sales from './profile/sales'
import { Link } from 'gatsby'

const Profile = (props) => {
  const [page, setPage] = useState('index')
  const [menu, setMenu] = useState([])

  useEffect(() => {
    if (props.menu) {
      setPage(props.menu)
    }
  }, [props.menu])

  useEffect(() => {
    getMenu((res = false) => {
      if (res) setMenu(res)
      if (!res) window.alert('Menun lataamisessa on ongelma')
    })
  }, [])

  return (
    <>
      <div className='container grid grid-cols-12 p-4'>
        <h1 className='col-span-12 p-4 m-0'>{(getAccount() && getAccount().name) ? <><span className='capitalize'>{getAccount().name.split(' ')[0]}</span> mukava nähdä 👋</> : 'Mukava nähdä 👋'}</h1>
        <div className='col-span-12 md:col-span-4 min-h-full self-start bg-white md:rounded-l-md shadow z-10 border-r border-gray-300'>
          <ul className='p-4'>
            {
              menu.map(item => {
                let Ico = false
                if (item.icon) {
                  Ico = Icon[item.icon]
                }
                const current = page === item.key
                const badge = item.badge && {
                  color: item.badge.color || 'current',
                  bg: item.badge.bg || 'white',
                  label: item.badge.label,
                  isNum: typeof item.badge.label === 'number'
                }
                return (
                  <li key={item.key} className='list-none m-3'>
                    {item.external
                      ? (
                        <a
                          className='p-3 gap-2 font-semibold text-base flex items-center w-full rounded-md hover:bg-blue-50 hover:text-blue-800 text-current'
                          href={item.key}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          {Ico && <Ico className='w-5' />}
                          {item.label}
                          {badge && (
                            <span className={`rounded-full text-xs ml-auto flex items-center justify-center ${badge.isNum ? ' w-6 h-6' : ' p-1 px-2'}`} style={{ color: badge.color, backgroundColor: badge.bg }}>
                              {badge.label}
                            </span>
                          )}
                        </a>
                        )
                      : (
                        <>
                          <Link to={`/app/profile/${item.key === 'index' ? '' : item.key}`} className={'link p-3 gap-2 font-semibold text-base flex items-center w-full rounded-md hover:bg-blue-50 hover:text-blue-800 ' + (current && 'bg-blue-50 text-blue-800')}>
                            {Ico && <Ico className='w-5' />}
                            {item.label}
                            {badge && (
                              <span className={`rounded-full text-xs ml-auto flex items-center justify-center ${badge.isNum ? ' w-6 h-6' : ' p-1 px-2'}`} style={{ color: badge.color, backgroundColor: badge.bg }}>
                                {badge.label}
                              </span>
                            )}
                          </Link>
                        </>
                        )}
                  </li>
                )
              })
            }
          </ul>
        </div>
        <div className='col-span-12 md:col-span-8 min-h-full flex-initial shadow md:rounded-r-md bg-white p-4'>
          {
            {
              index: <p>Palvelua päivitetään.</p>,
              me: <Me />,
              teams: <Teams urlTeamID={props.ID} />,
              screens: <Screens urlScreenID={props.ID} />,
              houses: <Houses urlHouseID={props.ID} />,
              sales: <Sales />
            }[page]
          }
        </div>
      </div>
    </>
  )
}

export default Profile
