import React, { useEffect } from 'react'
import { X, List, Columns, AlignLeft } from 'react-feather'

const SelectType = ({ item, handleRemoveItem, changeOptions, ...props }) => {
  const handleSave = type => {
    const edited = { ...item }
    edited.empty = true
    edited.status = 1
    edited.item = {
      type: type
    }

    if (type === 'columns') {
      edited.item.data = [
        {
          type: 'text',
          content: ''
        }
      ]
    }

    props.handleSave(edited)
  }

  useEffect(() => {
    if (!changeOptions) return
    const arr = []

    if (!item.onlyOne) {
      arr.push({
        label: 'Peruuta',
        onClick: () => {
          handleRemoveItem('new')
        },
        icon: <X/>
      })
    }

    changeOptions(arr)
  }, [item, handleRemoveItem, changeOptions])

  return (
    <div className='flex flex-col sm:flex-row items-center h-full w-full text-lg relative justify-around px-2 py-4 lg:pl-6 '>
      <button className='flex items-center gap-2' onClick={() => handleSave('columns')}><Columns/>Kolumni</button>
      <button className='flex items-center gap-2' onClick={() => handleSave('text')}><AlignLeft/>Teksti</button>
      <button className='flex items-center gap-2' onClick={() => handleSave('list')}><List/>Lista</button>
    </div>
  )
}

export default SelectType
