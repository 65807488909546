import React, { useEffect, useState } from "react";
import { ChevronLeft, ChevronRight } from "react-feather";
import ScreenImg1 from "../images/ScreenImg-1.png";
import ScreenImg2 from "../images/ScreenImg-2.png";
import ScreenImg3 from "../images/ScreenImg-3.png";
import ScreenImg4 from "../images/ScreenImg-4.png";

const ImageCarousel = ({ autoSlide = true, autoSlideInterval = 6000 }) => {
  const [currSlide, setCurrSlide] = useState(0);
  const [imagesWidth, setImagesWidth] = useState(getImageWidth());
  const imageStyles = [ScreenImg1, ScreenImg2, ScreenImg3, ScreenImg4];
  let slideInterval;

  // Set image (div) width based on screen size
  function getImageWidth() {
    const width = window.innerWidth;

    switch (true) {
      case width < 900:
        return 300;
      case width < 1100:
        return 400;
      default:
        return 500;
    }
  }

  // Change image (div) width when screen is resized
  useEffect(() => {
    function handleResize() {
      setImagesWidth(getImageWidth());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Automatically change image
  useEffect(() => {
    if (!autoSlide) return;

    slideInterval = setInterval(next, autoSlideInterval);

    return () => clearInterval(slideInterval);
  }, [autoSlide, autoSlideInterval, currSlide]);

  const resetSlideInterval = () => {
    if (!autoSlide) return;

    clearInterval(slideInterval);
    slideInterval = setInterval(next, autoSlideInterval);
  };

  // Change slide
  const prev = () => {
    setCurrSlide((currSlide) => (currSlide === 0 ? 3 : currSlide - 1));
    resetSlideInterval();
  }

  const next = () => {
    setCurrSlide((currSlide) => (currSlide === 3 ? 0 : currSlide + 1));
    resetSlideInterval();
  }

  return (
    <div className="overflow-hidden py-10 sm:px-14  flex flex-col items-center gap-4 relative">
      <div
        style={{ width: `${imagesWidth}px`, height: `${imagesWidth * 0.8}px` }}
      >
        <div
          className="flex gap-6 items-center transition transform ease-out duration-500 carousel w-full h-full"
          style={{
            transform: `translateX(calc(${-currSlide} * (${
              imagesWidth * 0.75
            }px + 24px))`,
            width: `calc(${100 + 3 * 75}% + ${3 * 24}px)`,
          }}
        >
          {imageStyles.map((style, index) => (
            <img
              src={style}
              alt="Kuva opasti näytöstä"
              key={index}
              className={`rounded-md ${
                currSlide === index ? "carousel-piece-center" : "carousel-piece"
              }`}
            />
          ))}
        </div>
      </div>

      <div className="w-full flex justify-center items-center text-black">
        <button onClick={prev} aria-label="Edellinen kuva">
          <ChevronLeft className="carousel-arrows" aria-hidden="true" />
        </button>
        {imageStyles.map((_, index) => (
          <div
            key={index}
            className={`carousel-dots ${
              currSlide === index ? "carousel-dots-active" : ""
            }`}
          ></div>
        ))}
        <button onClick={next} aria-label="Seuraava kuva">
          <ChevronRight className="carousel-arrows" aria-hidden="true" />
        </button>
      </div>
    </div>
  );
};

export default ImageCarousel;
