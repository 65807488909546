import React from "react";
import { Helmet } from "react-helmet";
import computerVector from "../images/computerVector.svg";
import ImageCarousel from "./imageCarousel";
import Contact from "./contact";
import { ChevronRight } from "react-feather";
import { Link } from "gatsby";
//import VideoSection from "./HousingCooperativesVideo";

const ForHousingCooperatives = () => {
  return (
    <>
      {/* Page title */}
      <Helmet>
        <title>Infonäytöt Taloyhtiöille | Opasti</title>
      </Helmet>

      <div className="w-full">
        {/* Top section */}
        <section className="container xl:min-h-hero bg-top bg-no-repeat bg-cover bg-header-sm sm:bg-header-md md:bg-header-2xl px-0 pt-4 pb-4">
          <nav aria-label="Linkkipolku" className="mx-4 bread-crumbs text-white">
            <Link to="/">Opasti</Link> <ChevronRight /> Taloyhtiöille
          </nav>
          <div className="md:px-6 text-white text-center md:text-left flex flex-col items-center md:flex-row md:items-start">
            <div className="pt-6 md:pt-10 lg:pt-24 px-6 flex-grow md:w-1/2">
              <h1 className="lg:text-4xl xl:text-5xl leading-normal	xl:mb-4">Tehosta tiedonvälitystäsi ja yhdistä yhteisöäsi</h1>
              <p className="xl:text-2xl mb-4">Moderni tapa jakaa tietoa ja vahvistaa taloyhtiön henkeä</p>
              <a href="https://www.skyled.fi/yhteydenotto/" className="btn -reversed">
                Ota yhteyttä
              </a>
            </div>
            <div className="md:pt-10 lg:pt-14 w-full md:w-1/2">
              <ImageCarousel />
            </div>
          </div>
        </section>
        {/* Services */}
        <section className="container pt-3 mb-14">
          <p className="text-xl text-center mb-10">
            Valitsemalla meidät saat käyttöösi intuitiivisen ja tehokkaan alustan, joka tekee ilmoitusten ja tiedotteiden jakamisesta
            vaivatonta. Tarjoamme helppokäyttöisen ratkaisun, joka säästää aikaa ja parantaa asukkaiden tiedon saantia.
          </p>
          <div className="flex flex-col items-center md:flex-row gap-6">
            <img src={computerVector} alt="Vektoripiirros tietokoneen näytöstä" className="w-full max-w-sm md:w-1/3" />

            <div className="flex flex-col gap-5">
              <div className="flex flex-col">
                <p className="font-semibold">Jaa ilmoitukset helposti sieltä missä olet</p>
                <p>
                  Säästä aikaa ja vaivaa - jaa taloyhtiön tärkeät tiedotteet vaivatta netissä. Ei enää hankalia Word-dokumentteja tai
                  taistelua oikukkaiden tulostimien kanssa.
                </p>
              </div>
              <div className="flex flex-col">
                <p className="font-semibold">Muokkaa ulkoasua ja personoi näkymää</p>
                <p>
                  Räätälöi näyttö taloyhtiön ilmeeseen ja tarpeisiin. Valitse värit, fontit ja taustakuvat, jotta näyttö sulautuu
                  saumattomasti ympäristöönsä.
                </p>
              </div>
              <div className="flex flex-col">
                <p className="font-semibold">Näytä juuri sinun taloyhtiöllesi tärkeät tiedot</p>
                <p>Ilmoitusten lisäksi voit näyttää näytöllä muita olennaisia tietoja, kuten kellonajan tai asukaslistan.</p>
              </div>
            </div>
          </div>
        </section>
        {/* Video section (Uncomment when video is linked) */}
        {/* <VideoSection /> */}

        {/* Contact section */}
        <Contact />
      </div>
    </>
  );
};

export default ForHousingCooperatives;
