import React, { useState } from "react";
import { Link } from "gatsby";

const Index = ({ props }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <>
      <div
        className={`w-full flex-grow grid grid-cols-2 grid-rows-2 gap-0 ${
          isHovered ? "hovered" : ""
        }`}
      >
        {/* Left side */}
        <div
          className="bg-accent text-white col-span-2 md:col-span-1 md:row-span-2 half-container-grid-left anim-left-hover"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <div className="my-8 mx-3 md:my-32 md:mx-6 xl:mx-8 col-start-2">
            <div className="mb-4 text-lg">Mainostajille</div>
            <h1 className="lg:text-4xl">Tavoita kohderyhmäsi siellä, missä se merkitsee</h1>
            <p className="text-lg">
              Kohdenna viestisi oikealle yleisölle - Opasti tekee markkinoinnista
              paikallista ja tehokasta.
            </p>
            <Link to="app/marketers" className="btn -reversed -big mt-4 ">
              Mainostajille
            </Link>
          </div>
        </div>
        {/* Right side */}
        <div className="bg-white text-accent-dark col-span-2 md:col-span-1 md:row-span-2 half-container-grid-right anim-right-hover">
          <div className="text-right my-8 mx-3 md:my-32 md:mx-6 xl:mx-8">
            <div className="mb-4 text-lg">Taloyhtiöille</div>
            <h1 className="lg:text-4xl">Tehosta tiedonvälitystäsi ja yhdistä yhteisöäsi</h1>
            <p className="text-lg">Moderni tapa jakaa tietoa ja vahvistaa taloyhtiön henkeä!</p>
            <Link
              to="app/housing-coops"
              className="btn -secondary -big mt-4"
            >
              Taloyhtiöille
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
