import React, { useState, useEffect, useMemo } from 'react'
import { getTeamMembers } from '../../services/teams'

const Team = ({ team, props }) => {
  const [members, setMembers] = useState(false)

  useEffect(() => {
    getTeamMembers({
      callback: (res = false) => {
        if (res) setMembers(res)
        if (!res) window.alert('Tiimin lataamisessa on ongelma')
      },
      teamId: team.$id
    })
  }, [team.$id])

  const output = useMemo(() => {
    if (!members) return <p>Ladataan</p>

    return (
      <div className='flex flex-col'>
        <div className='-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
          <div className='py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8'>
            <div className='shadow-sm overflow-hidden border-b border-gray-200 sm:rounded-lg'>
              <table className='min-w-full divide-y divide-gray-200'>
                <thead className='bg-gray-50'>
                  <tr>
                    <th scope='col' className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                      Nimi
                    </th>
                    <th scope='col' className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                      Rooli
                    </th>
                    <th scope='col' className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                      Tila
                    </th>
                  </tr>
                </thead>
                <tbody className='bg-white divide-y divide-gray-200'>
                  {members.memberships.map(member =>
                    <tr key={member.$id}>
                      <td className='px-6 py-4 whitespace-nowrap'>
                        <div className='flex items-center'>
                          <div className='ml-4'>
                            <div className='text-sm font-medium text-gray-900'>
                              {member.name}
                            </div>
                            <div className='text-sm text-gray-500'>
                              {member.email}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>
                        {member.roles.map(role =>
                          <span key={role}>{role}</span>
                        )}
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap'>
                        {member.confirm
                          ? (
                            <span className='px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800'>
                              Vahvistettu
                            </span>
                          ) : (
                            <span className='px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800'>
                              Vahvistamaton
                            </span>
                          )}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    )
  }, [members])

  return (
    <>
      <h2>Tiimi: {team.name}</h2>
      {output}
    </>
  )
}

export default Team
