import React, { useState, useEffect } from 'react'
import * as Icon from 'react-feather'
import feather from 'feather-icons'
import { MapContainer, TileLayer, Marker } from 'react-leaflet'
import { DivIcon } from 'leaflet'
import Fullscreen from 'react-leaflet-fullscreen-plugin'
import { getScreen, patchScreen } from '../../services/extension'
import { isBrowser } from '../../services/auth'
import { Link } from 'gatsby'

const Screen = ({ screenId, back, props }) => {
  const [screen, setScreen] = useState(false)

  useEffect(() => {
    getScreen(screenId, (res = false) => {
      if (res) setScreen(res)
      if (!res) window.alert('Opasteen lataamisessa on ongelma')
    })
  }, [screenId])

  if (!screen) {
    return <p>Lataa</p>
  }

  const handleIdentifier = event => {
    event.preventDefault()

    const data = new window.FormData(event.target)
    patchScreen(screenId, data, (res = false) => {
      if (res.status === 'success') {
        getScreen(screenId, (res = false) => {
          if (res) setScreen(res)
          if (!res) window.alert('Opasteen lataamisessa on ongelma')
        })
      }
      if (res.status === 'not found') window.alert('Opasteen yhdistävä koodi väärin')
      if (!res) window.alert('Opasteen yhdistämisessä on ongelma')
    })
  }

  if (Object.keys(screen).length < 1) {
    return <p>Opastetta ei ole olemassa</p>
  }

  const fullscreenOptions = {
    position: 'bottomright', // change the position of the button can be topleft, topright, bottomright or bottomleft, default topleft
    title: 'Full Screen', // change the title of the button, default Full Screen
    titleCancel: 'Exit Full Screen', // change the title of the button when fullscreen is on, default Exit Full Screen
    content: null, // change the content of the button, can be HTML, default null
    forceSeparateButton: true, // force separate button to detach from zoom buttons, default false
    forcePseudoFullscreen: false, // force use of pseudo full screen even if full screen API is available, default false
    fullscreenElement: false // Dom element to render in full screen, false by default, fallback to map._container
  }

  return (
    <>
      <div className='flex items-center gap-4 -m-4 mb-4 p-4 bg-gray-700 text-white rounded-tr-md'>
        <Link to='/app/profile/screens'>
          <button className='btn'><Icon.ArrowLeft /><span className='sr-only'>Takaisin</span></button>
        </Link>

        <h4 className='font-bold uppercase text-sm'>Opasteet</h4>
      </div>
      <div className='relative -m-4 mb-4 pb-52'>
        <div className='flex items-center justify-between gap-0 mb-6 z-10 relative p-4 bg-gradient-to-br from-white to-transparent' style={{ zIndex: 10000 }}>
          <div>
            <h3 className='text-sm font-semibold text-gray-500 uppercase my-0'>Opaste</h3>
            <h2 className='my-0'>{screen.name.fi ? screen.name.fi : <span className='font-normal gray-500 italic'>Nimetön</span>}</h2>
          </div>
          <div className='flex items-center justify-between gap-4'>
            <button className='btn -primary' onClick={() => back(false, { id: screen.id, edit: true })}>Muokkaa</button>
            {screen.identifier && <a className='btn -secondary' target='_blank' rel='noopener noreferrer' href={`https://beta.signage.opasti.com/#${screen.identifier}`}>Katsele</a>}
          </div>
        </div>
        <div className='absolute top-0 h-full w-full paivitys'>
          {isBrowser() && screen.lat && screen.lng && (
            <MapContainer
              center={{
                lat: screen.lat,
                lng: screen.lng
              }}
              zoom={13}
              scrollWheelZoom
              zoomControl={false}
              className='h-full w-full'
              attributionControl={false}
            >
              <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
              />
              <Marker
                position={{
                  lat: screen.lat,
                  lng: screen.lng
                }}
                icon={new DivIcon({
                  html: `<div class='marker -input'>${feather.icons.crosshair.toSvg({ width: 32, height: 32 })}<div class='marker-bg' style='background-image: url(data:image/svg+xml;base64,${window.btoa(feather.icons.crosshair.toSvg())})'></div></div>`,
                  className: '',
                  iconSize: [32, 32],
                  iconAnchor: [16, 16]
                })}
              />
              <Fullscreen
                {...fullscreenOptions}
              />
            </MapContainer>
          )}
        </div>
      </div>
      {!screen.identifier
        ? (
          <div className='bg-blue-100 p-4 rounded mt-4'>
            <h4>Näyttöä ei ole vielä yhdistetty</h4>
            <p>Kun kytken käytön päälle, ilmestyy siihen koodi, syötä tämä koodi alle yhdistääksesi näyttö ja opaste.</p>
            <form
              className='flex items-end justify-between gap-4 mt-4'
              onSubmit={event => {
                handleIdentifier(event)
              }}
            >
              <div className='field w-full'>
                <label htmlFor='code'>Asennuskoodi</label>
                <input
                  type='text'
                  className='inline-block w-full'
                  name='code'
                  autoComplete='off'
                />
              </div>
              <button className='btn -blue'>Yhdistä</button>
            </form>
          </div>
        )
        : (
          <div className='bg-blue-100 p-4 rounded mt-4'>
            <h4>Näyttö on yhdistetty, mutta voit korvata sen toisella</h4>
            <p>Syöttämällä uuden laitteen asennuskoodin, korvataan se nykyisellä. Nykyinen laite lakkaa toimimasta ja näyttää käynnistäessä asennuskoodia.</p>
            <form
              className='flex items-end justify-between gap-4 mt-4'
              onSubmit={event => {
                handleIdentifier(event)
              }}
            >
              <div className='field w-full'>
                <label htmlFor='code'>Asennuskoodi</label>
                <input
                  type='text'
                  className='inline-block w-full'
                  name='code'
                  autoComplete='off'
                />
              </div>
              <button className='btn -blue'>Korvaa</button>
            </form>
          </div>
        )}
      {screen.notes && (
        <div className='bg-gray-100 p-4 rounded mt-4'>
          {screen.notes}
        </div>
      )}
    </>
  )
}

export default Screen
