import React, { useState, useRef } from "react";
// import emailjs from '@emailjs/browser';

const Contact = () => {
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [msg, setMsg] = useState("");
  const [contactType, setContactType] = useState("email");

  const form = useRef();

  const handleContactFormSubmit = (e) => {
    e.preventDefault();

    /*
    // Get values from https://www.emailjs.com/
    service_id = "";
    template_id = "";
    public_key = "";

    emailjs.sendForm(service_id, template_id, form.current, public_key).then(
      (result) => {
        console.log(result.text);
        window.alert("Viesti lähetetty. ");
      },
      (error) => {
        console.log(error.text);
        window.alert("Jokin meni pieleen. Voit ottaa yhteyttä Opastin asiakaspalveluun. ");
      }
    ); */
  };

  return (
    <section className="container mt-10 mb-16">
      {/* Contact info and map */}
      <h2 className="text-center mb-10">Ota yhteyttä</h2>
      <div className="flex flex-col lg:flex-row mx-1 sm:mx-6">
        <div className="lg:w-1/3 bg-accent text-white p-4 md:p-8">
          <h3>Yhteystiedot</h3>
          <div className="grid grid-flow-row">
            <div className="flex flex-col mb-2">
              <p>Asiakaspalvelu</p>
              <p className="text-sm">asiakaspalvelu@skyled.fi</p>
            </div>
            <div className="flex flex-col">
              <p>Myynti</p>
              <p className="text-sm">myynti@skyled.fi</p>
              <p className="text-sm">puh. 010-338 4441</p>
            </div>
          </div>
        </div>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1883.9395544487734!2d28.81446739733382!3d61.823659513820935!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x469a44d601ef3b17%3A0x2c26b546a95ba3d0!2sSoitonniementie%20164%2C%2057600%20Savonlinna!5e0!3m2!1sen!2sfi!4v1702630282446!5m2!1sen!2sfi"
          className="flex-grow h-360 lg:h-600"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>

      {/* Contact form 
      <h3 className="text-center mt-20 mb-2">Yhteydenottolomake</h3>

      <form ref={form} onSubmit={handleContactFormSubmit} className="contact-form m-auto flex flex-col gap-2 pb-12 px-1 sm:px-6">
        <div className="field">
            <label htmlFor='email'>Sähköpostiosoite</label>
            <input
              type='email'
              name='email'
              placeholder="sähköposti"
              value={email}
              required
              onChange={(e) => {
                setEmail(e.target.value)}
              }
              className='inline-block w-full'>
            </input>
        </div>
        <div className="field">
          <label htmlFor='phone'>Puhelin nro</label>
          <input
            type='text'
            name='phone'
            placeholder="nro"
            value={phone}
            onChange={(e) => {
              setPhone(e.target.value)}
            }
            className='inline-block w-full'>
          </input>
        </div>
        <div className="field">
          <label htmlFor='msg'>Viesti</label>
          <textarea 
            name="msg"
            placeholder="Mitä haluat kysyä?" 
            value={msg}
            required
            onChange={(e) => {
              setMsg(e.target.value)}
            }
            className='inline-block w-full h-240'
          />
        </div>
        <div className="field">
          <label htmlFor='contactType'>Toivottu yhteydenottotapa</label>
          <select 
            name='contactType'
            value={contactType}
            onChange={(e) => {setContactType(e.target.value)}}
          >
            <option value='email'>Sähköposti</option>
            <option value='puhelin'>Puhelin</option>
          </select>
        </div>

        <input type="submit" className="btn block px-12 primary m-auto" value="Lähetä"/>
      </form> */}
    </section>
  );
};

export default Contact;
