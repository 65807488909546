import React, { useMemo, useState, useEffect } from 'react'

const Columns = ({ item, changeOptions, handleRemoveItem, handleSave, ...props }) => {
  const [editing, setEditing] = useState(false)
  const [columns, setColumns] = useState(item.item.data || [])

  const returnColumns = useMemo(
    () => columns.map((column, index) =>
      <div key={`${index}-${column.type}`} className='p-1 max-h-full max-w-max relative'>
        {
          {
            text: editing === index
              ? (
                <textarea
                  className='w-full h-full resize-none rounded -m-1 p-1 outline-none ring border-blue-300 dark:bg-dark-lighter'
                  placeholder='Syötä teksti tähän'
                  autoComplete='off'
                  rows='4'
                  value={columns[index].content}
                  onChange={e => {
                    if (e.target.value.length > 100) return
                    const arr = JSON.parse(JSON.stringify(columns))
                    arr[index].content = e.target.value
                    setColumns(arr)
                  }}
                />
              )
              : (handleSave
                ? (
                  <button onClick={() => setEditing(index)} className='h-full w-full'>
                    {column.content ? column.content.match(/(.*[^\n])/gm).map((line, index) =>
                      <p key={`${index}-${line}`} className='py-0.5 leading-5 text-lg text-left'>
                        {line}
                      </p>
                    ) : <p className='italic'>Tyhjä</p>}
                  </button>
                )
                : column.content.match(/(.*[^\n])/gm).map((line, index) =>
                  <p key={`${index}-${line}`} className='py-0.5 leading-5 text-lg text-left'>
                    {line}
                  </p>
                )
              ),
            image: <img alt='' className='object-contain max-h-48 max-w-xs' src={column.content} />
          }[column.type]
        }
      </div>
    ),
    [columns, editing, handleSave]
  )

  useEffect(() => {
    if (!changeOptions) return
    const arr = []

    if (editing || typeof editing === 'number') {
      arr.push({
        label: 'Tallenna',
        onClick: () => {
          const edited = { ...item }
          edited.item.data = columns
          delete edited.empty
          handleSave(edited)
          setEditing(false)
        }
      },
      {
        label: 'Peruuta',
        onClick: () => {
          const edited = { ...item }
          edited.item.data = columns
          delete edited.empty
          handleSave(edited)
          setEditing(false)
        }
      })

      if (columns.length < 3) {
        arr.push({
          label: 'Lisää kolumni',
          onClick: () => {
            const arr = JSON.parse(JSON.stringify(columns))
            arr.push({
              type: 'text'
            })
            setColumns(arr)
          }
        })
      }

      if (columns.length > 0) {
        arr.push({
          label: 'Poista viimeisin kolumni',
          onClick: () => {
            const arr = JSON.parse(JSON.stringify(columns))
            arr.pop()
            setColumns(arr)
          }
        })
      }
    }

    changeOptions(arr)
  }, [item, editing, columns, changeOptions, handleRemoveItem, handleSave])

  useEffect(() => {
    if (item.id === 'new') setEditing(0)
  }, [item])

  return (
    <div className={'flex items-center h-full w-full text-lg relative' + (columns.length > 2 ? ' justify-between px-2 pl-6' : ' justify-around')}>
      {returnColumns}
    </div>
  )
}

export default Columns
