import { appwrite, logs } from './../utils'
import { handleLogin } from './auth'
import { navigate } from 'gatsby'

export const handleRecovery = ({ email, goto = '/app/profile' }) => {
  const url = `${window.location.protocol}//${window.location.host}/app/reset-password?email=${window.btoa(email)}`
  const promise = appwrite.account.createRecovery(email, url)
  promise.then(function (response) {
    if (logs) console.log('Password recovery email success: ', response)
    navigate(goto)
  }, function (error) {
    if (logs) console.log('Password recovery email error: ', error)
  })
}

export const handleRecover = ({ password, userId, secret, email, goto = '/app/profile' }) => {
  const promise = appwrite.account.updateRecovery(userId, secret, password, password)
  promise.then(function (response) {
    if (logs) console.log('Password recovery success: ', response)
    // Automatically log in for user
    handleLogin({ email, password, goto })
  }, function (error) {
    if (logs) console.log('Password recovery error: ', error)
  })
}
