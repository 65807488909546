import { Router } from "@reach/router";
import Access from "../components/access";
import Layout from "../components/layout";
import Login from "../components/login";
import PrivateRoute from "../components/privateRoute";
import Profile from "../components/profile";
import React from "react";
import Recovery from "../components/recovery";
import Register from "../components/register";
import ResetPassword from "../components/reset-password";
import Resources from "../components/resources";
import Map from "../components/map";
import MapEdit from "../components/mapEdit";
import Order from "../components/order";
import OrderEdit from "../components/orderEdit";
import Handle from "../components/handle";
import ForHousingCooperatives from "../components/for-housing-cooperatives";
import ForMarketers from "../components/for-marketers";

// Fonts by fontsource
import '@fontsource/roboto';

// heading font
import "@fontsource/nunito/600.css";
import "@fontsource/nunito/700.css";

// body font
import "@fontsource/open-sans/400.css";
import "@fontsource/open-sans/600.css";

// leaflet styles
import "leaflet/dist/leaflet.css";
import Index from "../components";

const App = () => (
  <Layout>
    <Router className="flex flex-col flex-grow justify-between gap-8">
      {/* Public pages */}
      <ForHousingCooperatives path="app/housing-coops" />
      <ForMarketers path="app/marketers" />
      {/* Private routes */}
      <PrivateRoute path="/app/profile" component={Profile} />
      <PrivateRoute path="/app/profile/:menu" component={Profile} />
      <PrivateRoute path="/app/profile/:menu/:ID" component={Profile} />
      <PrivateRoute path="/app/map" component={Map} />
      <PrivateRoute path="/app/map/:campaignID" component={MapEdit} />
      <PrivateRoute path="/app/order" component={Order} />
      <PrivateRoute path="/app/order/:campaignID" component={OrderEdit} />
      <PrivateRoute path="/app/handle" component={Handle} />
      <PrivateRoute path="/app/handle/:interest" component={Handle} />
      <PrivateRoute
        path="/app/handle/:interest/:campaignID"
        component={Handle}
      />
      {/* Login, register and so on */}
      <Login path="/app/login" />
      <Register path="/app/register" />
      <Recovery path="/app/recovery" />
      <ResetPassword path="/app/reset-password" />
      {/* Resources and login request for access */}
      <Access path="/app/access" />
      <Resources path="/app/resources" />

      <Index path="/app/*"/>
    </Router>
  </Layout>
);

export default App;
