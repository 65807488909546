import React, { useState, useCallback, useMemo } from 'react'
import Columns from './Columns'
import Text from './Text'
import List from './List'

import SelectType from './SelectType'
import { Plus, Archive, Edit } from 'react-feather'

const InfobarItemList = ({ items, handleNameChange, handleSave, handleChange, handleAddItem, handleRemoveItem, ...props }) => {
  const [options, setOptions] = useState([])
  const current = useMemo(
    () => items.find(item => item.current), [items])

  const changeOptions = useCallback(
    options => {
      const defaults = []

      if (current.id !== 'new' && current.status === 1) {
        defaults.push({
          label: 'Lisää osio',
          onClick: () => handleAddItem(),
          icon: <Plus/>
        },
        {
          label: 'Arkistoi',
          onClick: () => {
            const current = items.find(item => item.current)
            current.status = 2
            handleSave(current)
          },
          icon: <Archive/>
        })
      }

      if (current.status === 2) {
        defaults.push({
          label: 'Aktivoi',
          onClick: () => {
            const current = items.find(item => item.current)
            current.status = 1
            handleSave(current)
          }
        })
        defaults.push({
          label: 'Poista',
          onClick: () => {
            const current = items.find(item => item.current)
            current.status = 3
            handleSave(current)
          },
        })
      }

      if (current.id !== 'new') {
        defaults.push({
          label: 'Muokkaa osion nimeä',
          onClick: () => {handleNameChange(current)},
          icon: <Edit/>
        })
      }

      setOptions([...defaults, ...options])
    },
    [setOptions, items, handleNameChange, handleSave, handleAddItem, current]
  )

  const handlers = {
    handleSave: handleSave,
    changeOptions: changeOptions,
    handleRemoveItem: handleRemoveItem
  }

  if (!current) return null
  return (
    <>
      <div key={current.id} className='flex flex-col md:flex-row relative shadow-infobar px-4 py-4 h-60 max-h-60 bg-white w-full rounded shadow-sm border overflow-hidden dark:bg-dark-lighter dark:border-dark-lighter'>
        <InfoList items={items} onClick={handleChange && current.status === 1 && handleChange} />

        {current.item && current.item.type ? {
          columns: <Columns item={current} {...handlers} />,
          text: <Text item={current} {...handlers} />,
          list: <List item={current} {...handlers} />
        }[current.item.type]
          : !current.item && <SelectType item={current} {...handlers} />}
      </div>
      {handleSave && (
        <ul className='flex flex-col sm:flex-row p-0 my-2 sm:gap-4'>
          {options.map((option, index) =>
            <li key={index} className='list-none'>
              <button onClick={() => option.onClick()} className='flex py-2 gap-2 items-center'>{option.icon} {option.label}</button>
            </li>
          )}
        </ul>
      )}
    </>
  )
}

// List of all active items
const InfoList = ({ items, current, ...props }) => {
  if (!items) return null

  return (
    <ul className={'flex flex-col gap-1 infobar-list p-0 pb-2 md:pb-0 md:w-1/4 border-b-2 md:border-b-0 md:border-r-2' + (items.length > 3 ? ' justify-between' : ' justify-around')}>
      {items.map((item, index) =>
        <li key={item.id + '-' + item.title + index} className={'list-none text-left relative' + (item.current ? ' current' : '')}>
          {props.onClick ? <button className={'contents' + (item.current ? ' font-bold' : '')} onClick={() => props.onClick(item.id)}>{item.title}</button> : item.title}
        </li>
      )}
    </ul>
  )
}

export default InfobarItemList
