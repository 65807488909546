import React from "react";
import { Link } from "gatsby";

import logo from '../images/logo.min.svg'
import logodark from '../images/darkthemelogo.svg'
//import skyledQR  from '../images/skyledQR.svg'

const Footer = ({ props }) => (
  <footer className='z-50 relative bg-grey-light sh-custom-light dark:bg-darker'>
    <div className='grid md:grid-cols-2 px-6 md:auto-rows-min pt-8 pb-4'>
      <div className='mb-4 md:mb-10 flex flex-col md:items-start'>
          <img src={logo} alt='opasti logo' className='h-8 md:h-10 mb-6 block dark:hidden' />
          <img src={logodark} alt='opasti logo' className='object-contain h-8 md:h-10 mb-6 hidden dark:block' />
          <ul className='menu'>
            <li><Link to='/app/for-housing-cooperatives'>Taloyhtiöille</Link></li>
            <li><Link to='/app/for-marketers'>Mainostajille</Link></li>
          </ul>
      </div>
      <div className="text-center md:text-left flex justify-center flex-col md:flex-row md:justify-end gap-2 md:gap-8 mb-4">
        <div>
          <p className='dark:text-white'>Asiakaspalvelu</p>
          <p className='text-sm dark:text-white'>asiakaspalvelu@skyled.fi</p>
        </div>
        <div>
          <p className='dark:text-white'>Myynti</p>
          <p className='text-sm dark:text-white'>myynti@skyled.fi</p>
          <p className='text-sm dark:text-white'>puh. 010-338 4441</p>
        </div>
      </div>
      <div className='text-center md:text-left dark:text-white'>
        <p>Opastamassa <a href='https://www.skyled.fi' className='link-style' style={{ color: '#6495ED' }}>SkyLED Finland Oy</a></p>
        <p className='text-sm'>&copy; Opasti</p>
      </div>
    </div>
  </footer>
);

export default Footer;
