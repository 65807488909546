import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "gatsby";
import { ChevronRight } from "react-feather";
import ImageCarousel from "./imageCarousel";
import Contact from "./contact";
import CampaignPriceCalculator from "./AdCalculator"; // Adjust the import statement based on the file path

const ForMarketers = () => {
  return (
    <div className="w-full">
      {/* Page title */}
      <Helmet>
        <title>Mainostajille | Opasti</title>
      </Helmet>

      {/* Top section */}
      <section className="container xl:min-h-hero bg-top bg-no-repeat bg-cover bg-header-sm sm:bg-header-md md:bg-header-2xl px-0 pt-4 pb-4">
        <nav aria-label="Linkkipolku" className="mx-4 bread-crumbs text-white">
          <Link to="/">Opasti</Link> <ChevronRight /> Mainostajille
        </nav>
        <div className="md:px-6 text-white text-center md:text-left flex flex-col items-center md:flex-row md:items-start">
          <div className="pt-6 md:pt-10 lg:pt-24 px-6 flex-grow md:w-1/2">
            <h1 className="lg:text-4xl xl:text-5xl leading-normal	xl:mb-4">Tavoita kohderyhmäsi siellä, missä se merkitsee</h1>
            <p className="xl:text-2xl mb-4">
              Kohdenna viestisi oikealle yleisölle - Opasti tekee markkinoinnista paikallista ja tehokasta.
            </p>
            <Link to="/app/register" className="btn -reversed">
              Rekisteröidy mainostajaksi
            </Link>
          </div>
          <div className="md:pt-10 lg:pt-14 w-full md:w-1/2">
            <ImageCarousel />
          </div>
        </div>
      </section>

      {/* Services section */}
      <section className="container pt-3">
        <p className="text-xl text-center mb-10">
          Verkostomme laajenee kokoajan, näyttöjämme löytyy jo Oulusta, Tampereelta ja pääkaupunkiseudulta: Vantaalta, Helsingistä ja
          Espoosta.
        </p>
        <div className="grid lg:grid-cols-3 gap-4 lg:gap-10 xl:gap-20 lg:px-8 xl:px-14">
          <div className="flex flex-col">
            <p className="font-semibold lg:text-center">Yksinkertaista ja vaivatonta</p>
            <p className="lg:text-center">
              Meidän kauttamme mainostilan vuokraaminen on helppoa ja nopeaa. Jätä monimutkaisuus taaksesi ja keskity olennaiseen - viestisi
              välittämiseen.
            </p>
          </div>
          <div className="flex flex-col">
            <p className="font-semibold lg:text-center">Kohdennettua</p>
            <p className="lg:text-center">
              Saat käyttöösi tehokkaat työkalut, joiden avulla varmistat, että mainoksesi tavoittaa juuri oikean yleisön.
            </p>
          </div>
          <div className="flex flex-col">
            <p className="font-semibold lg:text-center">Näkyvää ja tehokasta</p>
            <p className="lg:text-center">
              Tarjoamme näkyvyyttä, joka jää ihmisten mieleen. Infonäytöt asuntoympäristössä varmistavat, että mainoksesi tavoittaa ihmiset
              heidän omassa arjessaan.
            </p>
          </div>
        </div>
      </section>

      {/* Pricing section*/
      /*<CampaignPriceCalculator />*/}

      {/* Contact section */}
      <Contact />
    </div>
  );
};

export default ForMarketers;
