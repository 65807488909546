import React, { useState, useEffect } from 'react';
import SunIcon from './images/sun.svg'; 
import MoonIcon from './images/moon.svg';

const ThemeSwitcher = () => {
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    const isDarkMode = localStorage.getItem('darkMode') === 'true';
    setDarkMode(isDarkMode);
  }, []);

  useEffect(() => {
    document.documentElement.classList.toggle('dark', darkMode);
    localStorage.setItem('darkMode', darkMode);
  }, [darkMode]);

  const toggleDarkMode = () => {
    setDarkMode((prevMode) => !prevMode);
  };

  return (
    <button
      onClick={toggleDarkMode}
      className={`px-4 py-2 rounded-full ${
        darkMode ? 'bg-gradient-to-r from-sky to-sky-dark' : 'bg-gradient-to-r from-yellow-400 to-yellow-500'
      } ${
        darkMode ? 'bg-gradient-to-r from-yellow-400 to-yellow-500' : 'text-white'
      } transition-colors duration-200`}
    >
      {darkMode ? (
        <img src={SunIcon} alt="Dark Mode" className="h-6 w-6" />
      ) : (
        <img src={MoonIcon} alt="Light Mode" className="h-6 w-6" />
      )}
    </button>
  );
};

export default ThemeSwitcher;
