import React, { useState, useEffect, useMemo } from 'react'
import { getHouses, postHouses } from '../../services/extension'
import { Link } from 'gatsby'

import House from './house'

const Houses = ({ urlHouseID, ...props }) => {
  const [houses, setHouses] = useState(false)
  const [house, setHouse] = useState(false)

  useEffect(() => {
    if (urlHouseID) {
      setHouse({ id: urlHouseID, edit: false })
    }
  }, [urlHouseID])

  useEffect(() => {
    getHouses((res = false) => {
      if (res) setHouses(res)
      if (!res) window.alert('Taloyhtiöiden lataamisessa on ongelma')
    })
  }, [])

  const back = (reload = false, house = 'none') => {
    house === 'none' ? setHouse(false) : setHouse(house)
    if (reload) {
      getHouses((res = false) => {
        if (res) setHouses(res)
        if (!res) window.alert('Taloyhtiöiden lataamisessa on ongelma')
      })
    }
  }

  const handleNewHouse = () => {
    const name = window.prompt('Uuden taloyhtiön nimi')
    if (name) {
      const data = new window.FormData()
      data.set('name', name)
      postHouses(data, (res = false) => {
        if (res.status === 'success') {
          res.id && setHouse({ id: res.id, edit: false })
        }
      })
    }
  }

  const output = useMemo(() => {
    if (!houses) {
      return (
        <div className='flex items-center justify-between gap-0 mb-6'>
          <div>
            <h3 className='text-sm font-semibold text-gray-500 uppercase my-0'>Taloyhtiöt</h3>
            <h2 className='my-0'>Ladataan</h2>
          </div>
        </div>
      )
    }

    return (
      <>
        <div className='flex items-center justify-between gap-0 mb-4'>
          <div>
            <h3 className='text-sm font-semibold text-gray-500 uppercase my-0'>Taloyhtiöt</h3>
            <h2 className='my-0'>{houses.length < 1 ? 'Ei tuloksia' : houses.length === 1 ? 'Yksin vain' : `${houses.length} tulosta`}</h2>
          </div>
          <button className='btn -green' onClick={() => handleNewHouse()}>Uusi taloyhtiö</button>
        </div>
        <div className='flex flex-col'>
          <div className='-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
            <div className='py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8'>
              <div className='shadow-sm overflow-hidden border-b border-gray-200 sm:rounded-lg'>
                <table className='min-w-full divide-y divide-gray-200'>
                  <thead className='bg-gray-50'>
                    <tr>
                      <th scope='col' className='px-6 py-3 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider'>
                        Nimi
                      </th>
                      <th scope='col' className='px-6 py-3 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider flex items-center gap-2'>
                        Hallinnoitsijoita
                      </th>
                      <th scope='col' className='relative px-6 py-3'>
                        <span className='sr-only'>Toiminnot</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className='bg-white divide-y divide-gray-200'>
                    {houses.map(house =>
                      <tr key={house.id}>
                        <td className='px-6 py-4 whitespace-nowrap text-sm font-semibold text-gray-900'>
                          {house.name ? house.name : <span className='font-normal gray-500'>Nimetön</span>}
                        </td>
                        <td className='px-6 py-4 whitespace-nowrap'>
                          {house.members}
                        </td>
                        <td className='px-6 py-4 whitespace-nowrap text-right text-sm font-semibold flex gap-3 justify-center content-center'>
                          <Link to={`/app/profile/houses/${house.id}`}>Avaa</Link>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }, [houses])

  if (house && house.id) {
    if (house.edit) {
      // return <EditScreen screenId={screen.id} back={back} />
    }
    return <House houseId={house.id} back={back} />
  }

  return output
}

export default Houses
