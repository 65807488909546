import React, { useEffect, useState } from 'react'
import * as Icon from 'react-feather'

const ResidentList = ({ list, onChange }) => {
  const [residents, setResidents] = useState(list)

  const add = () => {
    const newList = [...residents]
    newList.push('')
    setResidents(newList)
  }

  const remove = (index) => {
    const newList = [...residents]
    newList.splice(index, 1)
    setResidents(newList)
  }

  const update = (index, value) => {
    const newList = [...residents]
    newList[index] = value
    setResidents(newList)
  }

  useEffect(() => {
    setResidents(list)
  }, [list])

  useEffect(() => {
    if (onChange) {
      onChange(residents)
    }
  }, [residents])

  return (
    <div className='relative'>
      <ul className='list-none p-0 m-0'>
        {residents.map((item, index) => {
          return (
            <li key={index} className='list-none flex-row justify-between'>
              <input
                className='bg-gray-50 rounded-sm p-1'
                type='text'
                placeholder='Nimi'
                defaultValue={item}
                // onChange={(e) => { currentPage.content[index] = e.target.value }}
                onChange={(e) => update(index, e.target.value)}
              />
              <button className='align-middle' onClick={() => remove(index)}><Icon.X /></button>
            </li>
          )
        })}
      </ul>
      <button className='' onClick={() => add()}><Icon.Plus /></button>
    </div>
  )
}
export default ResidentList
