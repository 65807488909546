import React, { useState, useEffect, useCallback } from 'react'
import { getCart, removeCart } from '../services/cart'
import { postCampaign } from '../services/extension'
import { getTeams } from '../services/teams'
import { navigate } from 'gatsby'

// Some sweet animations
import 'animate.css'

import * as Icon from 'react-feather'

const Order = ({ ...props }) => {
  const [cart, setCart] = useState({})
  const [teams, setTeams] = useState(false)

  const handleCartEvent = useCallback(() => {
    const str = getCart()
    if (str.dates && str.dates.length === 2) {
      str.dates = [
        new Date(str.dates[0]),
        new Date((new Date(str.dates[1]).fp_incr(1).getTime() / 1000 - 1) * 1000)
      ]
    }
    if (str) setCart(str)
  }, [])

  useEffect(() => {
    handleCartEvent()
    window.addEventListener('storage', handleCartEvent)
    return () => {
      window.removeEventListener('storage', handleCartEvent)
    }
  }, [handleCartEvent])

  useEffect(() => {
    getTeams((res = false) => {
      if (res) setTeams(res)
      if (!res) window.alert('Tiimien lataamisessa ongelma')
    })
  }, [])

  if (!cart.dates || !cart.adLength || !cart.adPlays || !cart.cart || cart.cart.length < 1) {
    return (
      <div className='container flex justify-around'>
        <div className='flex gap-9'>
          <Icon.Smile className='h-auto w-24 text-gray-700 animate__animated animate__jackInTheBox' />
          <div className='flex gap-4 flex-col'>
            <span className='italic font-semibold text-2xl text-gray-900'>Voe mahoton!</span>
            <p className='text-lg text-gray-800'>Tyhjää voi äänestää, muttei tilata. Siirry karttaan valitsemaan itsellesi parhaat opasteet.</p>
            <div>
              <button
                onClick={() => navigate('/app/map')}
                className='flex justify-between gap-4 items-center p-4 px-6 font-semibold rounded shadow-sm transition-all duration-500 text-lg bg-size-200 bg-gradient-to-bl bg-pos-0 from-purple-300 via-purple-400 to-pink-400 text-white hover:shadow hover:bg-pos-0-100'
              >
                <Icon.ArrowLeft className='h-auto w-7 text-current' />
                <span>Karttaan</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const handleOrdering = event => {
    event.preventDefault()
    const data = new window.FormData(event.target)

    data.set('screens', JSON.stringify(cart.cart.map(s => s.id)))

    data.set('starting', cart.dates[0].getTime() / 1000)
    data.set('ending', cart.dates[1].getTime() / 1000)
    data.set('plays', cart.adPlays)
    data.set('length', cart.adLength)

    postCampaign(data, (res = false) => {
      if (res) {
        removeCart()
        navigate('/app/map')
      }
      if (!res) window.alert('Tilaamisessa ilmeni ongelma')
    })
  }

  return (
    <div className='container flex flex-col gap-8'>
      <div className='flex gap-6 flex-wrap justify-between'>
        <div className='flex gap-4 items-center opacity-75 hover:opacity-100 transition'>
          <button
            onClick={() => navigate('/app/map')}
            className='flex justify-between gap-4 items-center p-4 px-6 font-semibold rounded shadow-sm transition-all duration-500 text-lg bg-size-200 bg-gradient-to-bl bg-pos-0 from-purple-300 via-purple-400 to-pink-400 text-white hover:shadow hover:bg-pos-0-100'
          >
            <Icon.ArrowLeft className='h-auto w-7 text-current' />
            <span>Karttaan</span>
          </button>
          <span className='text-lg text-gray-800'>Kaikki opasteet matkassa?</span>
        </div>
        <div className='flex gap-4 items-center opacity-75 hover:opacity-100 transition'>
          <button
            onClick={() => {
              removeCart()
              setCart({})
            }}
            className='flex justify-between gap-4 items-center p-4 px-6 font-semibold rounded shadow-sm transition-all duration-500 text-lg bg-size-200 bg-gradient-to-bl bg-pos-0 from-yellow-300 via-red-400 to-red-500 text-white hover:shadow hover:bg-pos-0-100'
          >
            <Icon.Trash className='h-auto w-7 text-current' />
            <span>Tyhjennä ostoskori</span>
          </button>
        </div>
      </div>
      <form
        onSubmit={event => handleOrdering(event)}
        className='grid grid-cols-2 h-full rounded shadow min-h-full'
      >
        <div className='bg-white sticky top-0'>
          <div className='p-8 sticky top-0 flex flex-col gap-4'>
            <h1>Tilaajantiedot</h1>
            <div className='flex gap-4'>
              <div className='field mt-3'>
                <label htmlFor='firstname'>Etu-</label>
                <input
                  type='text'
                  className='inline-block w-full'
                  name='firstname'
                  required
                />
              </div>
              <div className='field flex-1'>
                <label htmlFor='lastname'>ja sukunimi</label>
                <input
                  type='text'
                  className='inline-block w-full'
                  name='lastname'
                  required
                />
              </div>
            </div>
            <div className='field'>
              <label htmlFor='email'>Sähköpostiosoite</label>
              <input
                type='email'
                className='inline-block w-full'
                name='email'
                required
              />
            </div>

            <div className='field'>
              <label htmlFor='campaignName'>Kampanjan nimi</label>
              <input
                type='text'
                className='inline-block w-full'
                name='campaignName'
                required
              />
            </div>

            <div className='flex flex-col my-3 bg-gray-50 p-4 rounded'>
              <p>Valitse tiimi listalta, tai luo uusi. Tiimissä olevat näkevät tilaukset ja voivat ladata materiaalia.</p>
              <div className='overflow-x-auto sm:-mx-6 lg:-mx-8'>
                <div className='py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8'>
                  <div className='shadow-sm overflow-hidden border-b border-gray-200 sm:rounded-lg'>
                    <table className='min-w-full divide-y divide-gray-200'>
                      <tbody className='bg-white divide-y divide-gray-200'>
                        {teams && teams.teams.map(team =>
                          <tr key={team.$id}>
                            <td className='px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900'>
                              {team.name}
                            </td>
                            <td className='px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 float-right'>
                              <input type='radio' name='team' value={team.$id} required />
                            </td>
                          </tr>
                        )}
                        <tr>
                          <td className='px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900'>
                            <div className='field'>
                              <label htmlFor='teamName'>Uusi tiimi</label>
                              <input
                                type='text'
                                className='inline-block w-full'
                                name='teamName'
                              />
                            </div>
                          </td>
                          <td className='px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 float-right'>
                            <input type='radio' name='team' value='new' required />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className='rounded bg-blue-500 p-3 px-4 text-white flex gap-4 items-center'>
              <Icon.Users className='h-auto w-12 text-current' />
              <span>Tilauksen jälkeen pääset lisäämään mainosmateriaalin ja myös muita ihmisiä hallinnoimaan kampanjaa.</span>
            </div>
            <button
              type='submit'
              className='flex justify-between items-center w-full p-4 px-6 font-semibold rounded shadow-sm transition-all duration-500 text-lg bg-size-200 bg-gradient-to-bl bg-pos-0 from-green-300 via-green-400 to-blue-400 text-white hover:shadow hover:bg-pos-0-100'
            >
              <span>Vahvista</span>
              <Icon.Check className='h-auto w-7 text-current' />
            </button>
          </div>
        </div>
        <div className='bg-gray-50'>
          <div className='p-8 sticky top-0'>
            <h2>Tilauksen sisältö</h2>
            <div className='mt-6'>
              <ul className='pl-0 flex flex-wrap gap-6 justify-between'>
                <li className='flex flex-col gap-0.5 list-none'>
                  <span className='text-xs font-semibold text-gray-800 uppercase tracking-wider'>Opastaminen aloitetaan</span>
                  <span className='text-lg'>{cart.dates[0].toLocaleString()}</span>
                </li>
                <li className='flex flex-col gap-0.5 list-none'>
                  <span className='text-xs font-semibold text-gray-800 uppercase tracking-wider'>Opastaminen päättyy</span>
                  <span className='text-lg'>{cart.dates[1].toLocaleString()}</span>
                </li>
                <li className='flex flex-col gap-0.5 list-none'>
                  <span className='text-xs font-semibold text-gray-800 uppercase tracking-wider'>Mainoksen kesto</span>
                  <span className='text-lg'>{cart.adLength}</span>
                </li>
                <li className='flex flex-col gap-0.5 list-none'>
                  <span className='text-xs font-semibold text-gray-800 uppercase tracking-wider'>Toistoja tunnissa</span>
                  <span className='text-lg'>{cart.adPlays}</span>
                </li>
                <li className='flex flex-col gap-0.5 list-none'>
                  <span className='text-xs font-semibold text-gray-800 uppercase tracking-wider'>Aikaa yhteensä</span>
                  <span className='text-lg'>{(cart.adPlays * ((cart.dates[1].fp_incr(1).getTime() - cart.dates[0].getTime()) / (1000 * 3600))) * cart.adLength} sekuntia</span>
                </li>
              </ul>
              <table className='min-w-full w-full max-w-full text-left table-auto mt-6'>
                <thead>
                  <tr>
                    <th scope='col' className='px-3 py-1.5 text-left text-xs font-semibold text-gray-800 uppercase tracking-wider'>Opaste</th>
                    <th><span className='sr-only'>Toiminnot</span></th>
                  </tr>
                </thead>
                <tbody className='divide-y divide-gray-200'>
                  {cart.cart && cart.cart.map(screen => {
                    return (
                      <tr key={screen.id}>
                        <td className='px-3 py-2 break-all'>{screen.name.fi ? screen.name.fi : <span className='text-gray-500 italic'>Nimetön</span>}</td>
                        <td className='px-3 py-2 float-right whitespace-nowrap'>- hinta -</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default Order
