import React, { useState, useEffect, useRef } from 'react'
import { getAccess } from '../../services/access'

import Notices from './notices'

const abortableFetch = (request, opts) => {
  const controller = new window.AbortController()
  const signal = controller.signal

  let cancel

  const callback = new Promise((resolve, reject) => {
    cancel = window.setTimeout(() => {
      resolve(window.fetch(request, { ...opts, signal }))
    }, 200)
  })

  return {
    abort: () => { clearTimeout(cancel); controller.abort() },
    ready: callback
  }
}

const Search = ({ question, filters, ...props }) => {
  const searchController = useRef(null)
  const [result, setResult] = useState([])

  const FilterNotices = (notices) => {
    // console.log(new Date(starting).getTime() / 1000)
    switch (filters.noticesVisible) {
      case "active":
        return notices.filter((n) => n.starting_date < new Date().getTime() / 1000 &&  n.ending_date > new Date().getTime() / 1000 )
      case "archived":
        // Return all not active
        return notices.filter((n) => n.starting_date > new Date().getTime() / 1000 ||  n.ending_date < new Date().getTime() / 1000 || n.starting_date == undefined )
      default:
        return notices
    }
  }

  const handleSearch = q => {
    const requestOptions = {
      headers: { Authorization: `Bearer ${getAccess()}` }
    }

    searchController.current = abortableFetch(`${process.env.REACT_APP_EXTENSION_API_ENDPOINT}/v1/search/${encodeURI(q)}`, requestOptions)
    searchController.current.ready
      .then(res => res.json())
      .then(
        (result) => {
          console.log(result)
          setResult(result)
        },
        (error) => {
          console.log(error)
        }
      ).catch(e => {
        if (e.name === 'AbortError') {
          return
        }
        console.warn(`Fetch search error: ${e.message}`)
      })
  }

  // If search changes, make it
  useEffect(() => {
    // Incase there is request going
    if (searchController.current) searchController.current.abort()

    if (!question) {
      setResult({})
      return
    }
    handleSearch(question)
  }, [question])

  // onMount and onUnmount
  useEffect(() => {
    return () => searchController.current && searchController.current.abort()
  }, [])

  if (!result.hits || result.hits.length < 1) {
    return <h4>Haku on tyhjä.</h4>
  }

  return result.hits.map((house, index) =>
    <div
      key={`${house.houses_id}-${house.timed}`}
      className={'text-gray-700' + (index > 0 && ' mt-4')}
    >
      <h2
        className='text-gray-900 text-hr -left sticky top-0 py-2 m-0 bg-opasti-white'
      >
        {house.house_name}
      </h2>
      {console.log('talo', house)}
      <Notices timed={house.timed} notices={FilterNotices(house.hits)} />
    </div>
  )
}

export default Search
