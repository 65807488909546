import React from 'react'
import { navigate } from 'gatsby'
import { isLoggedIn } from '../services/auth'
import { handleRecovery } from '../services/recovery'

class Login extends React.Component {
  state = {
    email: '',
    url: 'http://localhost/app/password-reset'
  }

  handleUpdate = event => {
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  handleSubmit = event => {
    event.preventDefault()
    handleRecovery(this.state)
  }

  render () {
    if (isLoggedIn()) {
      navigate('/app/profile')
    }

    return (
      <div className='container'>
        <h1 className='mt-10'>Salasanan palautus</h1>
        <form
          method='post'
          onSubmit={event => {
            this.handleSubmit(event)
          }}
        >
          <div className='field'>
            <label htmlFor='email'>
              Sähköposti
            </label>
            <input type='email' name='email' onChange={this.handleUpdate} />
          </div>
          <input type='submit' value='Lähetä palautusviesti sähköpostiin' className='btn -primary' />
        </form>
      </div>
    )
  }
}

export default Login
