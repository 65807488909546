import React, { useState, useEffect, useCallback, useMemo } from "react";
import { getAccess } from "../../../services/access";
import InfobarItemList from "./InfobarItemList";
import InfobarArchive from "./InfobarArchive";

const BarEdit = ({ current, ...props }) => {
  // for debugging add {id: 1, status: 1, title: "Testi osio", item: { type: "text", content: "testi teksti \n hei"}}
  const [items, setItems] = useState([{id: 1, status: 1, title: "Testi osio", item: { type: "text", content: "testi teksti \n hei"}}]); // status 1 = activeItems, 2 = archivedItemsd

  // Get active from items
  const activeItems = useMemo(() => {
    const arr = items.filter((item) => item.status === 1);
    if (arr.length > 0 && !arr.find((item) => item.current))
      arr[0].current = true;
    return arr;
  }, [items]);

  // Get archived from items
  const archivedItems = useMemo(
    () => items.filter((item) => item.status === 2).sort((a, b) => b.id - a.id),
    [items]
  );

  // Fetch items
  const loadItems = useCallback(
    (house) => {
      if (!current) {
        return;
      }
      const requestOptions = {
        headers: { Authorization: `Bearer ${getAccess()}` },
      };
      window
        .fetch(
          `${process.env.REACT_APP_EXTENSION_API_ENDPOINT}/v1/houses/${current}/additionals`,
          requestOptions
        )
        .then((res) => {
          // Log user out if JWT expired
          if (res.status === 401) {
          }
          return res.json();
        })
        .then(
          (result) => {
            console.log(result);
            setItems(result);
          },
          (error) => {
            console.log(error);
          }
        );
    },
    [current]
  );

  useEffect(() => {
    loadItems();
  }, [loadItems]);

  const handleChange = useCallback(
    (id) => {
      if (id !== "new" && items.find((item) => item.id === "new")) return;
      const newCurrent = items.map((item) => {
        item.current = false;
        if (id === item.id) item.current = true;
        return item;
      });
      setItems(newCurrent);
    },
    [items]
  );

  const handleSave = (changed) => {
    if (changed.empty) {
      const newCurrent = items.map((item) =>
        changed.id === item.id ? changed : item
      );
      setItems(newCurrent);
      return;
    }

    const isNew = changed.id === "new";

    const data = new window.FormData();
    changed.title && data.append("title", changed.title);
    changed.item && data.append("item", JSON.stringify(changed.item));
    !isNew && changed.status && data.append("status", changed.status);

    const requestOptions = {
      method: isNew ? "POST" : "PUT",
      headers: { Authorization: `Bearer ${getAccess()}` },
      body: data,
    };

    window
      .fetch(
        `${process.env.REACT_APP_EXTENSION_API_ENDPOINT}/v1/houses/${current}/additionals` +
          (!isNew ? `/${changed.id}` : ""),
        requestOptions
      )
      .then((res) => res.json())
      .then(
        (result) => {
          console.log(result);
          window.alert("Tallennettu");
          loadItems();
        },
        (error) => {
          window.alert("Virhe, löytyy lokista");
          console.log(error);
        }
      );
  };

  const handleAddItem = (onlyOne = false) => {
    setItems([
      ...items,
      {
        id: "new",
        status: 1,
        onlyOne: onlyOne,
        title: "Uusi osio",
      },
    ]);
  };

  const handleRemoveItem = (id) => {
    const index = items.findIndex((item) => item.id === id);
    const arr = JSON.parse(JSON.stringify(items));
    if (arr.splice(index, 1)) {
      setItems(arr);
    }
  };

  useEffect(() => {
    if (items.find((item) => item.id === "new" && !item.current)) {
      handleChange("new");
    }
  }, [items, handleChange]);

  const handleNameChange = (item) => {
    const title = window.prompt("Syötä osion nimi", item.title || "");
    if (title) {
      item.title = title;
      handleSave(item);
    }
  };

  const handlers = {
    handleChange: handleChange,
    handleNameChange: handleNameChange,
    handleAddItem: handleAddItem,
    handleSave: handleSave,
    handleRemoveItem: handleRemoveItem,
  };

  console.log(archivedItems);

  if (!current) return null;
  if (activeItems.length < 1) handleAddItem(true);
  return (
    <>
      <h2>Alapalkin osiot</h2>
      <section className="mb-6 mt-4">
        <h3 className="mb-4">Näkyvillä</h3>
        <InfobarItemList items={activeItems} {...handlers} />
      </section>
      <section>
        <h3 className="text-hr -left">Arkisto</h3>
        <InfobarArchive items={archivedItems} {...handlers} />
      </section>
    </>
  );
};

export default BarEdit;
