import React, { useState, useMemo, useCallback, useEffect, useRef } from 'react'
import { getScreen, searchScreens } from '../services/extension'
import { getCart, setCartStorage } from '../services/cart'
import { isBrowser } from '../services/auth'
import feather from 'feather-icons'
import { MapContainer, TileLayer, Marker } from 'react-leaflet'
import { DivIcon } from 'leaflet'
import Flatpickr from 'react-flatpickr'
import { Finnish } from 'flatpickr/dist/l10n/fi.js'
import { Link } from 'gatsby'

// Some sweet animations
import 'animate.css'

import * as Icon from 'react-feather'
import { appwrite } from '../utils'

const MapEdit = ({ campaignID, ...props }) => {
  const searchController = useRef(null)

  const [map, setMap] = useState(null)
  const [campaign, setCampaign] = useState(null)
  const [highlight, setHighlight] = useState(false)
  const [result, setResult] = useState(false)
  const [cart, setCart] = useState([])
  const [loading, setLoading] = useState(false)

  const [dates, setDates] = useState([])
  const [adLength, setAdLength] = useState(5)
  const [adPlays, setAdPlays] = useState(60)

  const [cartOpen, setCartOpen] = useState(false)

  const updateCartStorage = useCallback(() => {
    setCartStorage({
      dates: dates,
      adLength: adLength,
      adPlays: adPlays,
      cart: cart
    })
  }, [cart, dates, adLength, adPlays])

  const loadCampaign = (campaign) => {
    if (campaign) {
      if (campaign.plays) {
        setAdPlays(campaign.plays)
      }
      if (campaign.length) {
        setAdLength(campaign.length)
      }
      if (campaign.from && campaign.to) {
        setDates([
          new Date(campaign.from * 1000),
          new Date(campaign.to * 1000)
        ])
      }
      const newCart = []
      if (campaign.selected) {
        campaign.selected.forEach((item) => {
          getScreen(item, (res) => {
            newCart.push(res)
          }).then(() => {
            setCart(newCart)
            updateCartStorage()
          })
        })
      }
    }
  }

  const handleCartEvent = useCallback(() => {
    const str = getCart()
    if (str.cart) setCart(str.cart)
    if (str.dates && str.dates.length === 2) {
      setDates([
        new Date(str.dates[0]),
        new Date(str.dates[1])
      ])
    }
    if (str.adLength) setAdLength(str.adLength)
    if (str.adPlays) setAdPlays(str.adPlays)
  }, [])

  useEffect(() => {
    handleCartEvent()
    window.addEventListener('storage', handleCartEvent)
    return () => {
      window.removeEventListener('storage', handleCartEvent)
    }
  }, [handleCartEvent])

  useEffect(() => {
    if (campaignID) {
      appwrite.database.getDocument(process.env.REACT_APP_APPWRITE_COLLECTION_ORDER, campaignID)
        .then((res) => {
          loadCampaign(res)
          setCampaign(res)
        })
        .catch((res) => {
          console.log(res)
        })
    }
  }, [campaignID])

  const load = useCallback(
    callback => {
      if (dates.length > 1 && map) {
        if (searchController.current) searchController.current.abort()
        setLoading(true)
        const hours = (dates[1].fp_incr(1).getTime() - dates[0].getTime()) / (1000 * 3600)
        searchController.current = searchScreens({
          from: dates[0].getTime() / 1000,
          to: dates[1].fp_incr(1).getTime() / 1000 - 1,
          needs: (adPlays * hours) * adLength,
          mapPos: map.getBounds()
        })
        searchController.current.ready
          .then(res => res.json())
          .then(
            (res) => {
              document.getElementById('map-container') && document.getElementById('map-container').scrollIntoView({
                behavior: 'smooth'
              })
              if (highlight && !res.screens.find(s => s.id === highlight.id)) setHighlight(false)
              setResult(res)
              setLoading(false)
            },
            (error) => {
              setLoading(false)
              if (error.name === 'AbortError') {
                return
              }
              console.error(error)
              window.alert('Haun lataamisessa on ongelma')
            }
          )
      }
    },
    [dates, adLength, adPlays, map, highlight]
  )

  useEffect(() => {
    if (map) {
      map.on('zoomend', () => load())

      map.on('dragend', () => load())
    }
  }, [map, load])

  // Save cart to storage and load
  useEffect(() => {
    if (document.hasFocus()) {
      load()
      updateCartStorage()
    }
  }, [updateCartStorage, load])

  // Close cart, if empty
  useEffect(() => {
    if (cart.length < 1 && cartOpen) setCartOpen(false)
  }, [cart, cartOpen])

  const displayMap = useMemo(
    () => {
      if (isBrowser()) {
        return (
          <div className='flex-auto w-full h-full' id='map'>
            <MapContainer
              center={{
                lat: 64.959997,
                lng: 27.590001
              }}
              zoom={8}
              scrollWheelZoom
              preferCanvas
              zoomControl={false}
              className='h-full w-full'
              attributionControl={false}
              whenCreated={setMap}
            >
              <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
              />
              {result.screens && result.screens.map(screen => {
                if (screen.lat && screen.lng) {
                  const inCart = cart.find(s => s.id === screen.id)
                  const highlighted = highlight && screen.id === highlight.id
                  const eventHandlers = {
                    click: (event) => {
                      document.getElementById('map') && document.getElementById('map').scrollIntoView({
                        behavior: 'smooth'
                      })
                      setHighlight(screen)
                    }
                  }
                  return (
                    <Marker
                      key={screen.id}
                      eventHandlers={eventHandlers}
                      position={{
                        lat: screen.lat,
                        lng: screen.lng
                      }}
                      icon={new DivIcon({
                        html: feather.icons.monitor.toSvg({ class: 'w-full h-auto', color: 'current' }),
                        className: `p-1.5 shadow rounded-full ${highlighted ? ' ring ring-pink-500 z-top' : ''} ${inCart ? ' bg-blue-600 text-white z-top' : 'bg-white text-black'}`,
                        iconSize: [32, 32],
                        iconAnchor: [16, 16]
                      })}
                    />
                  )
                }
                return null
              })}
            </MapContainer>
          </div>
        )
      }
      return null
    },
    [result, highlight, cart]
  )

  const searchBar = useMemo(() => {
    return (
      <div className='absolute top-4 flex flex-col items-center gap-4 max-w-full' style={{ zIndex: 10000 }}>
        <div className='bg-white shadow rounded-full p-4 px-8 flex gap-4'>
          <div className='flex flex-col justify-center'>
            <span>Ajanjaksolle</span>
            <Flatpickr
              value={dates}
              onChange={dates => {
                dates.length > 1 && setDates(dates)
              }}
              options={{
                locale: Finnish,
                dateFormat: 'd.m.Y',
                mode: 'range',
                minDate: 'today',
                maxDate: new Date().fp_incr(548),
                weekNumbers: true,
                utc: true
              }}
            />
            {dates.length < 2 && (
              <div className='absolute -bottom-4 flex flex-col'>
                <div className='ml-4 w-6 overflow-hidden inline-block'>
                  <div className='h-3 w-3 bg-blue-500 rotate-45 transform origin-bottom-left shadow' />
                </div>
                <span className='bg-blue-500 text-white p-1 px-2.5 text-sm rounded-full shadow'>👋 Valitse täältä!</span>
              </div>
            )}
          </div>
          <div className='flex flex-col justify-center'>
            <span>Toistoja tunnissa</span>
            <input
              type='number'
              step='1'
              min='1'
              value={adPlays}
              onChange={e => setAdPlays(e.target.value)}
            />
          </div>
          <div className='flex flex-col justify-center'>
            <span>Mainoksen kesto</span>
            <input
              type='number'
              step='1'
              min='1'
              value={adLength}
              onChange={e => setAdLength(e.target.value)}
            />
          </div>
          <button
            disabled={loading}
            className='bg-blue-600 p-4 rounded-full -mr-4 disabled:cursor-not-allowed disabled:opacity-75'
            onClick={() => load()}
          >
            <Icon.Search className='h-6 w-6 text-white' />
          </button>
        </div>
        {loading
          ? (
            <div className='flex items-center gap-2 bg-white shadow rounded-full p-2 px-4 bg-opacity-75 text-opacity-50 hover:bg-opacity-100 hover:text-opacity-100 transition cursor-wait animate__animated animate__fadeIn'>
              <Icon.Loader className='animate-spin h-4 w-4' />
              Ladataan
            </div>
            )
          : result.moreResults && (
            <div className='flex text-opacity-100 bg-opacity-75 items-center gap-2 bg-blue-700 text-white shadow rounded-full p-2 px-4 hover:bg-opacity-100 transition z-10 relative animate__animated animate__fadeIn'>
              <Icon.ZoomIn className='h-5 w-5' />
              Tarkenna nähdäksesi lisää
            </div>
          )}
      </div>
    )
  }, [result, loading, dates, adLength, adPlays, load])

  const screensList = useMemo(() => (
    <>
      <div className='sticky top-0 bg-white shadow-bottom'>
        {cart.length < 1
          ? (
            <div className='p-7'>Et ole valinnut opasteita</div>
            )
          : (
            <div className='flex justify-between items-center'>
              <div className='p-2 px-7 flex flex-col'>
                <span className='text-sm font-semibold text-gray-600 uppercase'>Valittuna</span>
                <span><span className='text-lg font-semibold'>{cart.length}</span> {cart.length > 1 ? 'opastetta' : 'opaste'}</span>
              </div>
              <button
                className='p-7 transform transition text-gray-800 hover:text-white hover:bg-blue-600'
                onClick={() => {
                  document.getElementById('map') && document.getElementById('map').scrollIntoView({
                    behavior: 'smooth'
                  })
                  setCartOpen(prevState => !prevState)
                }}
              >
                <Icon.ChevronDown className={`h-6 w-6 transform transition ${cartOpen ? 'rotate-180' : ''}`} />
              </button>
            </div>
            )}
        <div className={`p-0 pt-0 relative transition-max-height duration-200 max-w-full overflow-hidden max-h-0 ${cartOpen ? 'max-h-192' : ''}`}>
          <div className='p-4 pt-0'>
            <div className='overflow-x-hidden overflow-y-auto max-h-96 mb-4'>
              <table className='min-w-full w-full max-w-full text-left table-auto'>
                <thead className='sticky top-0 bg-white shadow'>
                  <tr>
                    <th scope='col' className='px-3 py-1.5 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider'>Opaste</th>
                    <th><span className='sr-only'>Toiminnot</span></th>
                  </tr>
                </thead>
                <tbody className='divide-y divide-gray-200'>
                  {cart && cart.map(screen => {
                    const highlighted = highlight && screen.id === highlight.id
                    return (
                      <tr key={screen.id} className={`${highlighted ? 'bg-gray-50' : ''}`}>
                        <td className='px-3 py-2 break-all'>{screen.name.fi ? screen.name.fi : <span className='text-gray-500 italic'>Nimetön</span>}</td>
                        <td className='px-3 py-2 flex gap-2 float-right'>
                          <button
                            onClick={() => {
                              if (map && screen.lat && screen.lng) {
                                document.getElementById('map') && document.getElementById('map').scrollIntoView({
                                  behavior: 'smooth'
                                })
                                map.flyTo([screen.lat, screen.lng], map.getZoom() < 13 ? 13 : map.getZoom())
                              }
                            }}
                          >
                            <Icon.Crosshair className='h-5 w-5 text-current hover:text-blue-600' />
                          </button>
                          <button
                            onClick={() => setHighlight(screen)}
                          >
                            <Icon.ChevronRight className='h-5 w-5 text-current hover:text-blue-600' />
                          </button>
                          <button
                            onClick={() => setCart(prevState => {
                              const index = prevState.findIndex(s => s.id === screen.id)
                              if (index > -1) {
                                const prev = [...prevState]
                                prev.splice(index, 1)
                                return prev
                              }
                              return [...prevState, screen]
                            })}
                          >
                            <Icon.Trash className='h-5 w-5 text-current hover:text-red-600' />
                          </button>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
            {campaign &&
              <Link to={`/app/order/${campaign.$id}`}>
                <button className='flex justify-between items-center w-full p-4 px-6 font-semibold rounded shadow-sm transition-all duration-500 text-lg bg-size-200 bg-gradient-to-bl bg-pos-0 from-green-300 via-green-400 to-blue-400 text-white hover:shadow hover:bg-pos-0-100'>
                  <span>Tilaamaan</span>
                  <Icon.ArrowRight className='h-auto w-7 text-current' />
                </button>
              </Link>}
          </div>
        </div>
      </div>
      {result.screens
        ? result.screens.length > 0
            ? (
              <ul className='flex flex-col gap-4 p-4'>
                {result.screens.map(screen => {
                  const inCart = cart.find(s => s.id === screen.id)
                  const highlighted = highlight && screen.id === highlight.id
                  return (
                    <li
                      key={screen.id}
                      style={{ gridTemplateColumns: 'calc(100% - 3rem) 3rem' }}
                      className={`list-none grid overflow-hidden items-stretch justify-between items-center w-full rounded border-2 ${inCart ? 'border-blue-600' : 'border-transparent'} ${highlighted ? ' ring ring-pink-500' : ''}`}
                    >
                      <button
                        className='flex-grow p-4 overflow-hidden bg-gray-50 shadow-sm rounded'
                        onClick={() => {
                          document.getElementById('map-container') && document.getElementById('map-container').scrollIntoView({
                            behavior: 'smooth'
                          })
                          setHighlight(screen)
                        }}
                      >
                        <div className='flex flex-wrap gap-2 items-baseline'>
                          {screen.name.fi && <h3 className='m-0 break-all'>{screen.name.fi}</h3>}
                          {screen.type && screen.type === 'klinen' && <span style={{ backgroundColor: '#5772ff' }} className='block text-white p-0.5 px-2 rounded-full uppercase text-xs'>Klinen</span>}
                          {(screen.reserved_per || screen.reserved_per === null) && <span className='block text-gray-800 bg-gray-200 p-0.5 px-2 rounded-full uppercase text-xs'>{screen.reserved_per ? `${screen.reserved_per}%` : '0%'}</span>}
                        </div>
                      </button>
                      <button
                        className={`p-4 transition hover:text-white ${inCart ? 'text-white bg-blue-600 hover:bg-blue-700' : 'text-black hover:bg-blue-600 rounded-r'}`}
                        onClick={() => setCart(prevState => {
                          const index = prevState.findIndex(s => s.id === screen.id)
                          if (index > -1) {
                            const prev = [...prevState]
                            prev.splice(index, 1)
                            return prev
                          }
                          return [...prevState, screen]
                        })}
                      >
                        {inCart ? <Icon.CheckSquare className='h-4 w-4 text-current' /> : <Icon.Square className='h-4 w-4 text-current' />}
                      </button>
                    </li>
                  )
                })}
              </ul>
              )
            : (
              <div className='p-12 w-full h-full flex flex-col justify-center items-center gap-4'>
                <Icon.Compass className='h-auto w-16 text-gray-600 animate__animated animate__rotateIn' />
                <span className='text-lg text-center'>
                  <span className='font-semibold'>Ahoy!</span> Näillä vesillä ei vielä Opasti opasta.
                </span>
              </div>
              )
        : (
          <div className='p-12 w-full h-full flex flex-col justify-center items-center gap-4'>
            <div className='relative'>
              <Icon.Calendar className='h-auto w-16 text-gray-400' />
              <Icon.Edit2 className='h-auto w-11 text-gray-700 absolute z-10 top-2 left-8 animate__animated animate__wobble' />
            </div>
            <span className='text-lg text-center'>
              <span className='font-semibold'>Almanakan</span> täyttämisen jälkeen tulee opasteet.
            </span>
          </div>
          )}
    </>
  ), [result, cart, highlight, cartOpen, map, campaign])

  const output = useMemo(() => {
    return (
      <>
        <div className='flex items-stretch w-full relative shadow-inner transition-all duration-200 -my-8' id='map-container'>
          <div
            className={`w-96 bg-white shadow-md transition-all duration-100 sticky top-0 z-topr hover:ml-0 ${highlight ? '-ml-48' : ''}`}
          >
            {screensList}
          </div>
          <div className={`sticky top-0 h-full bg-white min-h-screen shadow z-top ${highlight ? 'w-96' : 'w-0'}`}>
            {highlight && (
              <>
                <div className='sticky top-0'>
                  <div className='absolute top-0 right-0 flex flex-col gap-2 p-2'>
                    <button
                      className='opacity-75 text-gray-600 hover:opacity-100 transition'
                      onClick={() => {
                        setHighlight(false)
                      }}
                    >
                      <Icon.X className='h-8 w-8 text-current rounded-full bg-gray-100 p-1 shadow' />
                    </button>
                    <button
                      className='opacity-75 text-gray-600 hover:opacity-100 transition'
                      onClick={() => {
                        if (map && highlight.lat && highlight.lng) {
                          document.getElementById('map') && document.getElementById('map').scrollIntoView({
                            behavior: 'smooth'
                          })
                          map.flyTo([highlight.lat, highlight.lng], map.getZoom() < 13 ? 13 : map.getZoom())
                        }
                      }}
                    >
                      <Icon.Crosshair className='h-8 w-8 text-current rounded-full bg-gray-100 p-1 shadow' />
                    </button>
                  </div>
                  <div className='bg-gray-600 w-full h-72' />
                  <div className='p-4 pb-8 bg-gradient-to-b from-white via-white'>
                    <div className='flex flex-wrap overflow-hidden gap-2 items-baseline'>
                      {highlight.name.fi && <h3>{highlight.name.fi}</h3>}
                      {highlight.type && highlight.type === 'klinen' && <span style={{ backgroundColor: '#5772ff' }} className='block text-white p-0.5 px-2 rounded-full uppercase text-xs'>Klinen</span>}
                      {(highlight.reserved_per || highlight.reserved_per === null) && <span className='block text-gray-800 bg-gray-200 p-0.5 px-2 rounded-full uppercase text-xs'>{highlight.reserved_per ? `${highlight.reserved_per}%` : '0%'}</span>}
                    </div>
                  </div>
                </div>
                <div className='p-4 pt-0'>
                  {highlight.presentation.fi && <p>{highlight.presentation.fi}</p>}
                </div>
              </>
            )}
          </div>
          <div className='flex-auto relative flex flex-col items-center sticky top-0 h-screen overflow-hidden'>
            {displayMap}
            {searchBar}
          </div>
        </div>
      </>
    )
  }, [highlight, displayMap, searchBar, map, screensList])

  return output
}

export default MapEdit
