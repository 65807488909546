import React, { useState, useEffect } from 'react'
import { getAccount } from '../../services/auth'

const Me = ({ back, ...props }) => {
  const [me, setMe] = useState(false)

  useEffect(() => {
    const getMe = getAccount()
    setMe(getMe)
  }, [])

  if (!me) {
    return <p>Ladataan</p>
  }

  return (
    <>
      <h2>Tilin tiedot</h2>
      <ul>
        {console.log(me)}
        <li>Name: {me.name}</li>
        <li>E-mail: {me.email} {me.emailVerification.toString()}</li>
      </ul>
    </>
  )
}

export default Me
