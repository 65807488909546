import React, { useState, useEffect } from 'react'

const Text = ({ item, changeOptions, handleRemoveItem, handleSave, ...props }) => {
  const [editing, setEditing] = useState(false)
  const [text, setText] = useState(item.item.content || '')

  const handleChange = target => {
    if (target.value.length > 340) return
    setText(target.value)
  }

  useEffect(() => {
    if (!changeOptions) return
    const arr = []

    if (editing) {
      arr.push({
        label: 'Tallenna',
        onClick: () => {
          const edited = { ...item }
          edited.item.content = text
          delete edited.empty
          handleSave(edited)
          setEditing(false)
        }
      },
      {
        label: 'Peruuta',
        onClick: () => {
          if (handleRemoveItem &&
            item.id === 'new') {
            if (window.confirm('Poistetaanko elementti?')) handleRemoveItem('new')
            return
          }
          setEditing(false)
          setText(item.item.content || '')
        }
      })
    }

    changeOptions(arr)
  }, [editing, text, item, changeOptions, handleRemoveItem, handleSave])

  useEffect(() => {
    if (item.id === 'new') setEditing(true)
  }, [item])

  return (
    <div className={'h-full w-full max-h-full max-w-full text-lg relative flex justify-around items-center dark:bg-dark-lighter' + (!editing ? ' overflow-hidden' : '')}>
      {editing
        ? (
          <textarea
            className='w-full h-full resize-none rounded -m-1 p-1 outline-none ring border-blue-300 dark:bg-dark-lighter'
            value={text}
            placeholder='Syötä teksti tähän'
            autoComplete='off'
            onChange={e => handleChange(e.target)}
          />
        )
        : (handleSave
          ? (
            <button onClick={() => setEditing(true)} className='h-full w-full'>
              <div className='whitespace-pre-line text-left flex justify-around items-center ' style={{ height: 'max-content ' }}>
                {item.item.content}
              </div>
            </button>
          )
          : (
            <div className='whitespace-pre-line text-left ' style={{ height: 'max-content' }}>
              {item.item.content}
            </div>
          )
        )}
    </div>
  )
}

export default Text
