import React, { useEffect, useState } from 'react'
import { nanoid } from 'nanoid'
import { getAccess } from '../../services/access'
import ResidentList from './ResidentList'
import * as Icon from 'react-feather'

const Residents = ({ ...props }) => {
  const newPage = () => {
    return {
      id: nanoid(),
      title: 'Kerros',
      duration: 6000,
      content: ['']
    }
  }
  const [screens, setScreens] = useState([])
  const [selectedScreen, setSelectedScreen] = useState('')
  const [residentData, setResidentData] = useState([])
  const [pages, setPages] = useState([])
  const [currentTitle, setCurrentTitle] = useState('')
  const [currentPageId, setCurrentPageID] = useState('')
  const [residentList, setResidentList] = useState([])
  const [status, setStatus] = useState(2)
  const [save, setSave] = useState(false)

  const addPage = () => {
    setPages([...pages, newPage()])
  }

  const selectCurrent = (id) => {
    const temp = [...pages]
    const tempPage = temp.find(item => item.id === id)
    setCurrentTitle(tempPage.title)
    setResidentList(tempPage.content)
    setCurrentPageID(tempPage.id)
  }

  // /v1/houses/{house}/screens
  const loadHouseScreens = () => {
    const requestOptions = {
      headers: { Authorization: `Bearer ${getAccess()}` }
    }
    window.fetch(`${process.env.REACT_APP_EXTENSION_API_ENDPOINT}/v1/houses/${props.current}/screens`, requestOptions)
      .then(res => res.json())
      .then((result) => {
        console.log(result)
        if (result.length <= 0) {
          window.alert('Ei näyttöjä.')
        }
        setScreens(result)
        setSelectedScreen(result[0])
      }, (error) => {
        console.log(error)
      })
  }

  // /v1/houses/{house}/residents
  const loadResidentResource = () => {
    const requestOptions = {
      headers: { Authorization: `Bearer ${getAccess()}` }
    }
    window.fetch(`${process.env.REACT_APP_EXTENSION_API_ENDPOINT}/v1/houses/${props.current}/residents`, requestOptions)
      .then(res => res.json())
      .then(
        (result) => {
          console.log(result)
          setResidentData(result)
        },
        (error) => {
          console.log(error)
        }
      )
  }

  const deletePage = () => {
    let temp = JSON.parse(JSON.stringify(pages))

    console.log('before slicing')
    console.table(temp)

    const index = temp.findIndex(item => item.id === currentPageId)

    if (index > -1) {
      temp.splice(index, 1)
    }

    if (temp.length === 0) {
      temp = [newPage()]
    }

    setCurrentTitle(temp[0].title)
    setResidentList(temp[0].content)
    setCurrentPageID(temp[0].id)

    console.log('after slicing')
    console.table(temp)
    setPages(temp)
    setSave(true)
  }

  const saveResidentResource = () => {
    const residentResource = residentData

    if (residentResource.length <= 0) {
      residentResource.push({
        screens_id: selectedScreen.screens_id,
        status: status,
        residents: pages
      })
    }

    const index = residentResource.findIndex((temp) => temp.screens_id === selectedScreen.screens_id)
    if (index > -1) {
      residentResource[index] = {
        screens_id: selectedScreen.screens_id,
        status: status,
        residents: pages
      }
    } else {
      residentResource.push({
        screens_id: selectedScreen.screens_id,
        status: status,
        residents: pages
      })
    }
    console.log(JSON.stringify(residentResource))

    const data = new window.FormData()
    data.append('title', 'residentlist')
    data.append('type', 'residents')
    data.append('item', JSON.stringify(residentResource))

    const requestOptions = {
      method: 'POST',
      headers: { Authorization: `Bearer ${getAccess()}` },
      body: data
    }
    window.fetch(`${process.env.REACT_APP_EXTENSION_API_ENDPOINT}/v1/houses/${props.current}/residents`, requestOptions)
      .then(res => res.json())
      .then(
        (result) => {
          console.log(result)
          // loadResidentResource()
        },
        (error) => {
          console.log(error)
          window.alert('Tallennus epäonnistui.')
          // loadResidentResource()
        }
      )
  }

  const updateStatus = (status) => {
    setStatus(status)
    setSave(true)
  }

  useEffect(() => {
    if (save) {
      saveResidentResource()
      setSave(false)
    }
  }, [save])

  useEffect(() => {
    const tempPageData = pages
    const key = pages.findIndex((data) => (data.id === currentPageId))
    if (key < 0) {
      return setPages([newPage()])
    }
    tempPageData[key].title = currentTitle
    tempPageData[key].content = residentList
    return setPages(tempPageData)
  }, [residentList, currentTitle, status])

  useEffect(() => {
    let foundOne = false
    residentData.forEach((resource) => {
      if (resource.screens_id === selectedScreen.screens_id) {
        foundOne = true
        setPages(resource.residents)
        setStatus(resource.status)
        setCurrentPageID(resource.residents[0].id)
        setCurrentTitle(resource.residents[0].title)
        setResidentList(resource.residents[0].content)
      }
    })
    if (residentData.length <= 0 || !foundOne) {
      const tempPage = newPage()
      setPages([tempPage])
      setStatus(2)
      setCurrentPageID(tempPage.id)
      setCurrentTitle(tempPage.title)
      setResidentList(tempPage.content)
    }
  }, [selectedScreen, residentData])

  useEffect(() => {
    loadHouseScreens()
  }, [])

  useEffect(() => {
    loadResidentResource()
  }, [])

  if (!currentPageId) {
    return (<h2>Loading..</h2>)
  }

  return (
<>
    <div className='grid grid-cols-3 bg-white rounded shadow-sm p-4 dark:bg-dark-lighter'>
      <div className='col-span-3 md:col-span-1 '>
        <div className='border-b-2 border-indigo-500 '>
          <h2 className='dark:text-white'>Opasteet</h2>
        </div>
        <ul>
          {screens.map((screen, index) => {
            if (screen.screens_id === selectedScreen.screens_id) {
              return (
                <li key={index} className='list-none text-left '>
                  <button key={index} className='contents font-bold' onClick={() => setSelectedScreen(screen)}>{screen.name ? screen.name : 'Nimetön'}</button>
                </li>
              )
            }
            return (
              <li key={index} className='list-none text-left '>
                <button key={index} className='contents' onClick={() => setSelectedScreen(screen)}>{screen.name ? screen.name : 'Nimetön'}</button>
              </li>
            )
          })}
        </ul>
      </div>
      <div className='col-span-3 md:col-span-1 flex-col'>
        <div className='border-b-2 border-indigo-500 '>
          <h2>
            <input
            className='dark:bg-dark-lighter'
            key={currentPageId}
            type='text'
            defaultValue={currentTitle}
            onChange={(e) => setCurrentTitle(e.target.value)}
            placeholder='Kerroksen nimi'
            />
          </h2>
        </div>
        <ul className='mb-4'>
          {pages.map((page, index) => {
            if (page.id === currentPageId) {
              return (
                <li key={index} className='list-none text-left'>
                  <button key={index} className='contents font-bold' onClick={() => selectCurrent(page.id)}>{page.title}</button>
                </li>
              )
            }
            return (
              <li key={index} className='list-none text-left'>
                <button key={index} className='contents' onClick={() => selectCurrent(page.id)}>{page.title}</button>
              </li>
            )
          })}
        </ul>
        <div className='flex flex-row justify-center gap-4'>
          <button className='flex flex-row gap-1' onClick={() => addPage()}><Icon.Plus /><span className='md:hidden'>Lisää kerros</span></button>
          <button className='flex flex-row gap-1' onClick={() => deletePage()}><Icon.X /><span className='md:hidden'>Poista kerros</span></button>
        </div>
      </div>
      <div className='col-span-3 md:col-span-1'>
        <div className='border-b-2 border-indigo-500'>
          <h2>Asukkaat</h2>
        </div>
        <ResidentList key={currentPageId} list={residentList} onChange={setResidentList} />
      </div>
    </div>
      <ul className='flex p-0 m-2 gap-2'>
        <li className='list-none'>
          <button onClick={() => saveResidentResource()} className='btn -primary dark:text-white'>Tallenna</button>
        </li>
        <li className='list-none'>
          {status === 1 && <button onClick={() => updateStatus(2)} className='btn -clear dark:text-white'>Näkyvyys: Piilota asukaslista</button>}
          {status === 2 && <button onClick={() => updateStatus(1)} className='btn -clear dark:text-white'>Näkyvyys: Näytä asukaslista</button>}
        </li>
      </ul>
    </>

    // Old residents list
    /*<div>
      <div className='flex p-4 bg-white w-full rounded shadow-sm'>
        <div className='flex-none pr-2 border-r-2 border-indigo-500'>
          <div className='border-b-2 border-indigo-500'>
            <h2>Opasteet</h2>
          </div>
          <ul>
            {screens.map((screen, index) => {
              if (screen.screens_id === selectedScreen.screens_id) {
                return (
                  <li key={index} className='list-none text-left'>
                    <button key={index} className='contents font-bold' onClick={() => setSelectedScreen(screen)}>{screen.name ? screen.name : 'Nimetön'}</button>
                  </li>
                )
              }
              return (
                <li key={index} className='list-none text-left'>
                  <button key={index} className='contents' onClick={() => setSelectedScreen(screen)}>{screen.name ? screen.name : 'Nimetön'}</button>
                </li>
              )
            })}
          </ul>
        </div>
        <div className='flex-none pr-2 border-r-2 border-indigo-500'>
          <div className='border-b-2 border-indigo-500'>
            <h2>Kerrokset</h2>
          </div>
          <ul>
            {pages.map((page, index) => {
              if (page.id === currentPageId) {
                return (
                  <li key={index} className='list-none text-left'>
                    <button key={index} className='contents font-bold' onClick={() => selectCurrent(page.id)}>{page.title}</button>
                  </li>
                )
              }
              return (
                <li key={index} className='list-none text-left'>
                  <button key={index} className='contents' onClick={() => selectCurrent(page.id)}>{page.title}</button>
                </li>
              )
            })}
          </ul>
        </div>
        <div className='flex-1'>
          {editor()}
        </div>
      </div>
      <ul className='flex p-0 m-2'>
        <li className='list-none'>
          <button onClick={() => addPage()} className='btn -clear'>Lisää kerros</button>
        </li>
        <li className='list-none'>
          <button onClick={() => saveResidentResource()} className='btn -clear'>Tallenna</button>
        </li>
        <li className='list-none'>
          {status === 1 && <button onClick={() => updateStatus(2)} className='btn -clear'>Näkyvyys: Piilota asukaslista</button>}
          {status === 2 && <button onClick={() => updateStatus(1)} className='btn -clear'>Näkyvyys: Näytä asukaslista</button>}
        </li>
      </ul>
    </div>*/
  )
}

export default Residents
