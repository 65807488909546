import React, { useState, useEffect } from 'react'
import * as Icon from 'react-feather'

import { getHouse, postUser, putUserLevel, deleteUserLevel, getScreens, postScreenHouse, deleteScreenHouse } from '../../services/extension'
import { Link } from 'gatsby'

const House = ({ houseId, back, ...props }) => {
  const [house, setHouse] = useState(false)
  const [screens, setScreens] = useState(false)

  useEffect(() => {
    getHouse(houseId, (res = false) => {
      if (res) setHouse(res)
      if (!res) window.alert('Taloyhtiön lataamisessa on ongelma')
    })
  }, [houseId])

  if (!house) {
    return <p>Lataa</p>
  }

  const handleNewUser = event => {
    event.preventDefault()

    const data = new window.FormData(event.target)

    postUser(houseId, data.get('email'), (res = false) => {
      if (res.status === 'success' || res.status === 'already exists') {
        getHouse(houseId, (res = false) => {
          if (res) setHouse(res)
          if (!res) window.alert('Taloyhtiön lataamisessa on ongelma')
        })
        if (res.status === 'already exists') window.alert('Hallinnoitsija kuuluu jo taloyhtiöön')
      }
    })
  }

  const handleUserLevel = (userId, level) => {
    putUserLevel(houseId, userId, level, (res = false) => {
      if (res.status === 'success') {
        getHouse(houseId, (res = false) => {
          if (res) setHouse(res)
          if (!res) window.alert('Taloyhtiön lataamisessa on ongelma')
        })
      }
    })
  }

  const handleUserDelete = (userId) => {
    deleteUserLevel(houseId, userId, (res = false) => {
      if (res.status === 'success') {
        getHouse(houseId, (res = false) => {
          if (res) setHouse(res)
          if (!res) window.alert('Taloyhtiön lataamisessa on ongelma')
        })
      }
    })
  }

  const handleHouseScreen = event => {
    event.preventDefault()
    const data = new window.FormData(event.target)
    postScreenHouse(houseId, data, (res = false) => {
      if (res.status === 'success') {
        setScreens(false)
        getHouse(houseId, (res = false) => {
          if (res) setHouse(res)
          if (!res) window.alert('Taloyhtiön lataamisessa on ongelma')
        })
      }
    })
  }

  const handleScreenDelete = screenId => {
    deleteScreenHouse(houseId, screenId, (res = false) => {
      if (res.status === 'success') {
        setScreens(false)
        getHouse(houseId, (res = false) => {
          if (res) setHouse(res)
          if (!res) window.alert('Taloyhtiön lataamisessa on ongelma')
        })
      }
    })
  }

  const term = level => {
    return {
      50: 'Hallinnoija',
      100: 'Käyttöoikeus',
      1: 'Palveluntarjoaja',
      default: level
    }[level]
  }

  return (
    <>
      <div className='flex items-center gap-4 -m-4 mb-4 p-4 bg-gray-700 text-white rounded-tr-md'>
        <Link to='/app/profile/houses'>
          <button className='btn'><Icon.ArrowLeft /><span className='sr-only'>Takaisin</span></button>
        </Link>
        <h4 className='font-bold uppercase text-sm'>Taloyhtiöt</h4>
      </div>
      <div className='relative -m-4'>
        <div className='flex items-center justify-between gap-0 mb-4 z-10 relative p-4 bg-gradient-to-br from-white to-transparent'>
          <div>
            <h3 className='text-sm font-semibold text-gray-500 uppercase my-0'>Taloyhtiö</h3>
            <h2 className='my-0'>{house.name ? house.name : <span className='font-normal gray-500 italic'>Nimetön</span>}</h2>
          </div>
        </div>
      </div>
      <div className='flex flex-col'>
        <h2>Hallinnoitsijat</h2>
        <div className='-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
          <div className='py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8'>
            <div className='shadow-sm overflow-hidden border-b border-gray-200 sm:rounded-lg'>
              <table className='min-w-full divide-y divide-gray-200'>
                <thead className='bg-gray-50'>
                  <tr>
                    <th scope='col' className='px-6 py-3 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider'>
                      Käyttäjänimi
                    </th>
                    <th scope='col' className='px-6 py-3 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider flex items-center gap-2'>
                      Taso
                    </th>
                    <th scope='col' className='relative px-6 py-3'>
                      <span className='sr-only'>Muuta tasoa</span>
                    </th>
                    <th scope='col' className='relative px-6 py-3'>
                      <span className='sr-only'>Poista oikeus</span>
                    </th>
                  </tr>
                </thead>
                <tbody className='bg-white divide-y divide-gray-200'>
                  {house.users && house.users.map(user =>
                    <tr key={user.id}>
                      <td className='px-6 py-4 whitespace-nowrap text-sm font-semibold text-gray-900'>
                        {user.users_username ? user.users_username : <span className='font-normal gray-500'>Nimetön</span>}
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap'>
                        {term(user.level)}
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap text-right text-sm font-semibold flex gap-3 justify-center content-center'>
                        <select
                          value={user.level}
                          onChange={e => handleUserLevel(user.users_id, e.target.value)}
                          autoComplete='off'
                          className='block py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                        >
                          <option value='1'>{term(1)}</option>
                          <option value='50'>{term(50)}</option>
                          <option value='100'>{term(100)}</option>
                        </select>
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap'>
                        <button className='link text-red-600' onClick={() => handleUserDelete(user.users_id)}>Poista</button>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className='bg-blue-100 p-4 rounded mt-4'>
          <h4>Lisää hallinnoitsija</h4>
          <form
            className='flex items-end justify-between gap-4 mt-4'
            onSubmit={event => {
              handleNewUser(event)
            }}
          >
            <div className='field w-full'>
              <label htmlFor='email'>Sähköpostiosoite</label>
              <input
                type='email'
                className='inline-block w-full'
                name='email'
                autoComplete='off'
              />
            </div>
            <button className='btn -blue'>Lisää</button>
          </form>
        </div>
      </div>

      <div className='flex flex-col mt-4'>
        <h2>Opasteet</h2>
        <div className='-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
          <div className='py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8'>
            <div className='shadow-sm overflow-hidden border-b border-gray-200 sm:rounded-lg'>
              <table className='min-w-full divide-y divide-gray-200'>
                <thead className='bg-gray-50'>
                  <tr>
                    <th scope='col' className='px-6 py-3 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider'>
                      Nimi taloyhtiössä
                    </th>
                    <th scope='col' className='px-6 py-3 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider flex items-center gap-2'>
                      Nimi
                    </th>
                    <th scope='col' className='relative px-6 py-3'>
                      <span className='sr-only'>Poista taloyhtiöstä</span>
                    </th>
                  </tr>
                </thead>
                <tbody className='bg-white divide-y divide-gray-200'>
                  {house.screens && house.screens.map(screen =>
                    <tr key={screen.id}>
                      <td className='px-6 py-4 whitespace-nowrap text-sm font-semibold text-gray-900'>
                        {screen.naming ? screen.naming : <span className='font-normal gray-500'>Nimetön</span>}
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap'>
                        {screen.name.fi ? screen.name.fi : <span className='font-normal gray-500'>Nimetön</span>}
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap'>
                        <button className='link text-red-600' onClick={() => handleScreenDelete(screen.screens_id)}>Poista</button>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className='bg-blue-100 p-4 rounded mt-4'>
          <h4>Lisää opaste</h4>
          <p>Huomaa, että opaste voi olla yhdistettynä vain yhteen taloyhtiöön kerrallaan.</p>
          <form
            onSubmit={event => handleHouseScreen(event)}
            className='flex items-end justify-between gap-4 mt-4'
          >
            {screens
              ? (
                <>
                  <div className='field w-full'>
                    <label htmlFor='screens_id'>Valitse näyttö</label>
                    <div className='flex items-end justify-between gap-4'>
                      <select
                        autoComplete='off'
                        name='screens_id'
                      >
                        {screens && screens.map(screen => (
                          <option
                            key={screen.id}
                            value={screen.id}
                          >
                            {screen.name.fi ? screen.name.fi : 'Nimetön'} ({screen.postcode && screen.postcode} {screen.city && screen.city})
                          </option>
                        )
                        )}
                      </select>
                      <input
                        type='text'
                        className='inline-block w-full'
                        name='naming'
                        placeholder='Rappu tms.'
                        autoComplete='off'
                      />
                    </div>
                  </div>
                  <button type='submit' className='btn -blue'>Yhdistä</button>
                </>
              )
              : (
                <button
                  type='button'
                  className='btn -secondary'
                  onClick={() => {
                    getScreens((res = false) => {
                      if (res) setScreens(res)
                      if (!res) window.alert('Opasteiden lataamisessa on ongelma')
                    }, 'houseless')
                  }}
                >
                  Lataa opasteet
                </button>
              )}
          </form>
        </div>
      </div>
    </>
  )
}

export default House
